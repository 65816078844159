 <div class="row">
    <div class="col-sm-12">
        <app-card [hidHeader]="true" cardClass="">
            <div class="row text-center">
                <div class="col-sm-12"> 
                    <button class="btn btn-primary mb-5" (click)="generatePDF()">Generar PDF</button>
                    <embed src="" id="foo" class="col-12" height="1000">

                    <div id="captura" style="width: 500px; height: 1000px;">
                        <div class="col-12">
                            <div class="col-6">
                                <dx-pie-chart id="pie" title="Area of Countries" palette="bright" [dataSource]="areas"
                            (onPointClick)="pointClickHandler($event)" (onLegendClick)="legendClickHandler($event)">
                            <dxi-series argumentField="country" valueField="area">
                                <dxo-label [visible]="true">
                                    <dxo-connector [visible]="true" [width]="1"></dxo-connector>
                                </dxo-label>
                            </dxi-series>
                            <dxo-size [width]="500"></dxo-size>
                            <dxo-export [enabled]="true"></dxo-export>
                        </dx-pie-chart>
                            </div>
                            <div class="col-6">
                                <dx-pie-chart id="pie" title="Area of Countries" palette="bright" [dataSource]="areas"
                            (onPointClick)="pointClickHandler($event)" (onLegendClick)="legendClickHandler($event)">
                            <dxi-series argumentField="country" valueField="area">
                                <dxo-label [visible]="true">
                                    <dxo-connector [visible]="true" [width]="1"></dxo-connector>
                                </dxo-label>
                            </dxi-series>
                            <dxo-size [width]="500"></dxo-size>
                            <dxo-export [enabled]="true"></dxo-export>
                        </dx-pie-chart>
                            </div>
                        </div>
                        

                        
                    </div>
                </div>
            </div>
        </app-card>
    </div>

</div> 
<div class="row">
    <button class="btn btn-primary mb-5" (click)="exportExcel()">Generar Excel</button>
        <table class="table table-bordered" id="sjs-table">
            <thead>
              <tr>
                <th scope="col">Folio Asignación</th>
                <th scope="col">Fecha Asignación</th>
                <th scope="col">Usuario</th>
                <th scope="col">Tipo Software</th>
                <th scope="col">Software</th>
                <th scope="col">Nombre</th>
                <th scope="col">Dato Auxiliar</th>
                <th scope="col">Vencimiento</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let per of dataTable">
                    <td>
                            {{per.folioAsignacion}}
                    </td>
                    <td>
                            {{per.fechaHoraAsignacion | date:'longDate'}}
                    </td>
                    <td>
                            {{per.usuario}}
                    </td>
                    <td>
                            {{per.tipoSw}}
                    </td>
                    <td>
                            {{per.software}}
                    </td>
                    <td>
                            {{per.nombreSw}}
                    </td>
                    <td>
                            {{per.auxiliar}}
                    </td>
                    <td>
                            {{per.vencimiento | date:'longDate'}}
                    </td>
                </tr>
            </tbody>
          </table>
</div>
    