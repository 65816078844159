<div class="shadow p-3 mb-5 bg-body rounded">
    <div style="text-align: center;">
        <span><h3>Asignacion de Software</h3></span>
    </div>
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-6">  
            <div class="input-group mb-3">
                <input type="text" class="form-control" (change)="cambioAsignacionInput()" #txtBuscaAsignacion placeholder="Buscar Asignación" aria-label="Buscar Asignación" aria-describedby="basic-addon2" (keyup.enter)="buscaAsignacionInput()">
                <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="buscaAsignacionInput()"><i class="feather icon-search"></i></button>
                </div>
            </div>
        </div>
        <div class="col-md-2"
        *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              ]"
        >
            <div class="div-btn-nuevo-hardware">
                <button class="btn btn-primary btnNuevaAsignacion" data-bs-toggle="modal" data-bs-target="#modalNuevaAsignacion" (click)="clicAgregarAsignacion(idAsignacionCompartir)">Nueva Asignación</button>
            </div>
        </div>
        <div class="col-md-2"></div>
    </div>
    <div class="row">
      <div class="col-md-12" style="overflow: auto;" *ngIf="!cargarLoader">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Folio Asignación</th>
              <th scope="col">Fecha Asignación</th>
              <th scope="col">Usuario</th>
              <th scope="col">Tipo Software</th>
              <th scope="col">Software</th>
              <th scope="col">Nombre</th>
              <th scope="col">Dato Auxiliar</th>
              <th scope="col">Vencimiento</th>
              <th scope="col"
              *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              ]"
              >Compartir</th>
            </tr>
          </thead>
          <tbody>
              <tr *ngFor="let per of asignacionesSw">
                  <td>
                          {{per.folioAsignacion}}
                  </td>
                  <td>
                          {{per.fechaHoraAsignacion | date:'longDate'}}
                  </td>
                  <td>
                          {{per.usuario}}
                  </td>
                  <td>
                          {{per.tipoSw}}
                  </td>
                  <td>
                          {{per.software}}
                  </td>
                  <td>
                          {{per.nombreSw}}
                  </td>
                  <td>
                          {{per.auxiliar}}
                  </td>
                  <td>
                          {{per.vencimiento | date:'longDate'}}
                  </td>
                  <td style="text-align: center;"
                  *appHasAnyRole="[
                  rolesAzure.SUPERADMIN, 
                  rolesAzure.SUPERVISOR, 
                  ]"
                  >
                     <button class="btn btn-secondary" (click)="clicAgregarAsignacion(per.idAsignacionSw)" data-bs-toggle="modal" data-bs-target="#modalCompartir" style="margin-left: 5px;">Compartir  <i class="feather icon-share-2 iconos-acciones"></i></button>
                </td>
              </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-start align-items-center p-2">
          <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" (pageChange)="loadPage($event)" [disabled]="asignacionesSw == null || asignacionesSw.length == 0">
            <ng-template ngbPaginationPrevious>Anterior</ng-template>
            <ng-template ngbPaginationNext>Siguiente</ng-template>
          </ngb-pagination>
        
          <div class="d-flex align-items-center ml-3">
            <label for="pageInput" class="mr-2">Buscar página específica:</label>
            <input id="pageInput" type="number" class="form-control w-auto"
                   #pageInput
                   (change)="loadPage(pageInput.value)"
                   (keyup.enter)="loadPage(pageInput.value)"
                   onkeypress="return(event.charCode >= 48 && event.charCode <= 57)"
                   placeholder="" aria-label="Buscar página específica" />
          </div>
      </div>
      </div>
  </div>
</div>

        
<!-- Modal -->
<div class="modal fade" id="modalNuevaAsignacion" tabindex="-1" aria-labelledby="modalNuevoCatSoftware" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalNuevaCaracteristica">Asignación de inventario de software</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
            <form [formGroup]="formAsignacionSw" (submit)="guardaAsignacionSw()">
                <div class="row">
                        <div class="col-6">
                                <label for="divIdUsuario" class="control-label is-required">Usuario</label>
                            <div class="input-group mb-3" id="divIdUsuario"> 
                                <input type="text" class="form-control" id="idUsuario" placeholder="Usuario"  formControlName="usuario" [(ngModel)]="usuarioElegido.nombreEmpleado" disabled>                
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" type="button" data-bs-target="#modalEligeUsuario" data-bs-toggle="modal"><i class="feather icon-search"></i></button>
                                    </div>
                                <input type="hidden"  formControlName="idUsuario" [(ngModel)]="usuarioElegido.numeroEmpleado">                 
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group"> 
                                <label for="idGrupoPermiso" class="control-label is-required">Ubicación</label>
                                <select class="form-control" name="Ubicacion" id="Ubicacion" formControlName="idUbicacion" (change)="validaAsignacion()">
                                    <option value="" disabled selected>Elige la ubicación</option>
                                        <option [value]=gp.idUbicacion *ngFor="let gp of ubicacion">{{gp.nombreUbicacion}}</option>
                                </select>                  
                            </div> 
                        </div>
                </div>
                <div class="row">
                    <div class="col-4"></div>
                    <div class="col-4">
                        <div>
                            <span class="btn btn-primary btnBuscaInvHw" data-bs-target="#modalEligeInventario" data-bs-toggle="modal" data-backdrop="static" data-keyboard="false">Agregar Inventario de Software</span>
                        </div>
                    </div>
                    <div class="col-4"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12" style="overflow: auto;">
                        <div *ngIf="hayInvSw">
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th scope="col">Software</th>
                                    <th scope="col">Nombre Software</th>
                                    <th scope="col">Temporalidad</th>
                                    <th scope="col">Dato Auxiliar</th>
                                    <th scope="col">Hardware</th>
                                    <th scope="col">Eliminar</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let soft of softwareElegido; let i=index">
                                        <td>
                                                {{soft.software}}
                                        </td>
                                        <td>
                                                {{soft.nombreSw}}
                                        </td>
                                        <td>
                                                {{soft.temporalidad}}
                                        </td>
                                        <td>
                                                {{soft.auxiliar}}
                                        </td>
                                        <td *ngIf="soft.idInventarioHw == null" style="text-align: center;">
                                          <span class="btn btn-primary" (click)="abreAgregarHardware(i)" data-bs-toggle="modal" data-bs-target="#modalAgregaHardware">Agregar Hardware</span>
                                        </td>
                                        <td *ngIf="soft.idInventarioHw != null" style="text-align: center;">
                                                {{soft.idInventarioHw}}
                                        </td>
                                        <td>
                                          <span class="btn btn-danger" (click)="eliminarSoft(i)"><i class="feather icon-trash iconos-acciones"></i></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-4"></div>
                    <div class="col-4" style="text-align: center;">
                        <button class="btn-lg btn-primary  btnAsignarHw" [disabled]="btnAsignarSw" data-bs-dismiss="modal" style="margin-left: 5px;">Asignar</button>
                    </div>
                    <div class="col-4"></div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </div>


  <!-- Modal -->
<div class="modal fade" id="modalEligeUsuarioCompartir" tabindex="-1" aria-labelledby="modalEligeUsuarioCompartir" aria-hidden="true" >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalNuevaCaracteristica">Elegir Usuario a Compartir</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#modalCompartir" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">  
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" #txtBuscaUsuarioCompartir placeholder="Buscar Usuario" aria-label="Buscar Usuario" aria-describedby="basic-addon2" (keyup.enter)="buscaUsuarioCompartir()">
                        <div class="input-group-append">
                        <button class="btn btn-outline-secondary" type="button" (click)="buscaUsuarioCompartir()"><i class="feather icon-search"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
        <div class="col-md-12" *ngIf="buscaUsuCompartir" style="overflow: auto;">
            <table class="table table-bordered" *ngIf="usuariosCompartir.length > 0">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Número</th>
                  <th scope="col">Correo</th>
                  <th scope="col">Elegir</th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let per of usuariosCompartir">
                      <td>
                              {{per.nombreEmpleado}}
                      </td>
                      <td>
                              {{per.numeroEmpleado}}
                      </td>
                      <td>
                              {{per.correo}}
                      </td>
                      <td style="text-align: center;">
                          <button class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#modalCompartir" (click)="eligeUsuarioCompartir(per.numeroEmpleado)">Elegir  <i class="feather icon-edit iconos-acciones"></i></button> 
                      </td>
                  </tr>
              </tbody>
            </table>
            <table class="table table-bordered" *ngIf="usuariosCompartir.length == 0">
                <thead>
                  <tr>
                    <th scope="col">Usuario</th>
                    <th scope="col">Elegir</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                      <td align="center" colspan="2">
                             No hay resultados
                      </td>
                  </tr>
                </tbody>
              </table>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal -->
<div class="modal fade" id="modalEligeUsuario" tabindex="-1" aria-labelledby="modalEligeUsuario" aria-hidden="true" >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalNuevaCaracteristica">Elegir Usuario</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#modalNuevaAsignacion" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">  
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" #txtBuscaUsuario placeholder="Buscar Usuario" aria-label="Buscar Usuario" aria-describedby="basic-addon2" (keyup.enter)="buscaUsuario()">
                        <div class="input-group-append">
                        <button class="btn btn-outline-secondary" type="button" (click)="buscaUsuario()"><i class="feather icon-search"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
        <div class="col-md-12" *ngIf="buscaUsu" style="overflow: auto;">
            <table class="table table-bordered" *ngIf="usuarios.length > 0">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Número</th>
                  <th scope="col">Correo</th>
                  <th scope="col">Elegir</th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let per of usuarios">
                      <td>
                              {{per.nombreEmpleado}}
                      </td>
                      <td>
                              {{per.numeroEmpleado}}
                      </td>
                      <td>
                              {{per.correo}}
                      </td>
                      <td style="text-align: center;">
                          <button class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#modalNuevaAsignacion" (click)="eligeUsuario(per.numeroEmpleado)">Elegir  <i class="feather icon-edit iconos-acciones"></i></button> 
                      </td>
                  </tr>
              </tbody>
            </table>
            <table class="table table-bordered" *ngIf="usuarios.length == 0">
                <thead>
                  <tr>
                    <th scope="col">Usuario</th>
                    <th scope="col">Elegir</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                      <td align="center" colspan="2">
                             No hay resultados
                      </td>
                  </tr>
                </tbody>
              </table>
        </div>
      </div>
    </div>
  </div>
  
<!-- Modal -->
<div class="modal fade" id="modalEligeInventario" tabindex="-1" role="dialog" aria-labelledby="modalEligeInventario" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Elegir Inventario</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#modalNuevaAsignacion" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">  
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" #txtBuscaInventario placeholder="Buscar Inventario Software" aria-label="Buscar Inventario" aria-describedby="basic-addon2" (keyup.enter)="buscaInventario()">
                        <div class="input-group-append">
                        <button class="btn btn-outline-secondary" type="button" (click)="buscaInventario()"><i class="feather icon-search"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
        
        <div class="col-md-12" *ngIf="buscaInv" style="overflow: auto;">
            <table class="table table-bordered" *ngIf="invSoftware.length > 0">
                <thead>
                    <tr>
                      <th scope="col">Software</th>
                      <th scope="col">Nombre Software</th>
                      <th scope="col">Temporalidad</th>
                      <th scope="col">Dato Auxiliar</th>
                      <th scope="col">Stock</th>
                      <th scope="col">Elegir</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let det of invSoftware">
                        <td>
                            {{det.software}}
                        </td>
                        <td>
                            {{det.nombreSw}}
                        </td>
                        <td>
                            {{det.temporalidad}}
                        </td>
                        <td>
                            {{det.auxiliar}}
                        </td>
                        <td>
                            {{det.stock}}
                        </td>
                        <td style="text-align: center;">
                            <button class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#modalNuevaAsignacion" (click)="eligeInventario(det.idInventarioSw)">Elegir  <i class="feather icon-edit iconos-acciones"></i></button> 
                        </td>
                  </tr>
              </tbody>
            </table>
            <table class="table table-bordered" *ngIf="invSoftware.length == 0">
                <thead>
                    <tr>
                      <th scope="col">Tipo Software</th>
                      <th scope="col">Nombre Software</th>
                      <th scope="col">Temporalidad</th>
                      <th scope="col">Dato Auxiliar</th>
                      <th scope="col">Stock</th>
                      <th scope="col">Elegir</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td align="center" colspan="5">
                                No hay resultados
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
      </div>
    </div>
  </div>
</div>
  
  <!-- Modal -->
<div class="modal fade" id="modalAgregaHardware" tabindex="-1" role="dialog" aria-labelledby="modalAgregaHardware" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Elegir del Hardware Asignado al Usuario</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#modalCompartir" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
        <div class="col-md-12" *ngIf="buscaInvHw" style="overflow: auto;">
            <table class="table table-bordered" *ngIf="invHardware.length > 0">
                <thead>
                    <tr>
                      <th scope="col">Tipo</th>
                      <th scope="col">Marca</th>
                      <th scope="col">Modelo</th>
                      <th scope="col"># Serie</th>
                      <th scope="col">TAG</th>
                      <th scope="col">Elegir</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let det of invHardware">
                        <td>
                            {{det.tipohw}}
                        </td>
                        <td>
                            {{det.marca}}
                        </td>
                        <td>
                            {{det.modelo}}
                        </td>
                        <td>
                            {{det.numeroSerie}}
                        </td>
                        <td>
                            {{det.numeroTag}}
                        </td>
                        <td style="text-align: center;">
                            <button class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#modalNuevaAsignacion" (click)="eligeHardware(det.idInventarioHw)">Elegir  <i class="feather icon-edit iconos-acciones"></i></button> 
                        </td>
                  </tr>
              </tbody>
            </table>
            <table class="table table-bordered" *ngIf="invHardware.length == 0">
                <thead>
                    <tr>
                      <th scope="col">Hardware</th>
                      <th scope="col">Marca</th>
                      <th scope="col">Modelo</th>
                      <th scope="col"># Serie</th>
                      <th scope="col">TAG</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td align="center" colspan="5">
                                No hay resultados
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
      </div>
    </div>
  </div>
</div>
  
  <!-- Modal -->
<div class="modal fade" id="modalAgregaHardwareCompartir" tabindex="-1" role="dialog" aria-labelledby="modalAgregaHardware" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Elegir del Hardware asignado al usuario al que se compartirá el software</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#modalCompartir" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
        <div class="col-md-12" *ngIf="buscaInvHw" style="overflow: auto;">
            <table class="table table-bordered" *ngIf="invHardware.length > 0">
                <thead>
                    <tr>
                      <th scope="col">Tipo</th>
                      <th scope="col">Marca</th>
                      <th scope="col">Modelo</th>
                      <th scope="col"># Serie</th>
                      <th scope="col">TAG</th>
                      <th scope="col">Elegir</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let det of invHardware">
                        <td>
                            {{det.tipohw}}
                        </td>
                        <td>
                            {{det.marca}}
                        </td>
                        <td>
                            {{det.modelo}}
                        </td>
                        <td>
                            {{det.numeroSerie}}
                        </td>
                        <td>
                            {{det.numeroTag}}
                        </td>
                        <td style="text-align: center;">
                            <button class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#modalCompartir" (click)="eligeHardwareCompartir(det.idInventarioHw)">Elegir  <i class="feather icon-edit iconos-acciones"></i></button> 
                        </td>
                  </tr>
              </tbody>
            </table>
            <table class="table table-bordered" *ngIf="invHardware.length == 0">
                <thead>
                    <tr>
                      <th scope="col">Hardware</th>
                      <th scope="col">Marca</th>
                      <th scope="col">Modelo</th>
                      <th scope="col"># Serie</th>
                      <th scope="col">TAG</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td align="center" colspan="5">
                                No hay resultados
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
      </div>
    </div>
  </div>
</div>
  
  <!-- Modal -->
<div class="modal fade" id="modalBaja" tabindex="-1" role="dialog" aria-labelledby="modalBajaFactura" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Desasignacion de software</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
          <div class="container">
              <div id="divFactura">
                  <form [formGroup]="formBaja">
                      <div class="form-group"> 
                          <label class="control-label is-required" for="motivo">Motivo</label>
                          <textarea type="text" min="1" class="form-control" id="motivoBaja" placeholder="Motivo de la baja" formControlName="motivoBaja" maxlength="200"></textarea>
                      </div>   
                      <div style="text-align: center;">
                          <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" (click)="bajaAsignacion()">Quitar Asignación</button>
                      </div>
                      <div class="modal-footer">
                      </div>
                  </form>
              </div>
          </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="modalCompartir" tabindex="-1" aria-labelledby="modalCompartir" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Compartir Software asignado con otro usuario</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
          <form [formGroup]="formCompartir" (submit)="comparteSw()">
              <div class="row">
                      <div class="col-6">
                              <label for="divIdUsuario" class="control-label is-required">Usuario</label>
                          <div class="input-group mb-3" id="divIdUsuario"> 
                              <input type="text" class="form-control" id="idUsuario" placeholder="Usuario"  formControlName="usuarioCompartir" [(ngModel)]="usuarioElegidoCompartir.nombreEmpleado" disabled>                
                              <div class="input-group-append">
                                  <button class="btn btn-outline-secondary" type="button" data-bs-target="#modalEligeUsuarioCompartir" data-bs-toggle="modal"><i class="feather icon-search"></i></button>
                                  </div>
                              <input type="hidden"  formControlName="idUsuarioCompartir" [(ngModel)]="usuarioElegidoCompartir.numeroEmpleado">                 
                          </div>
                      </div>
                      <div class="col-6">
                          <div class="form-group"> 
                              <label for="idGrupoPermiso" class="control-label is-required">Ubicación</label>
                              <select class="form-control" name="Ubicacion" id="Ubicacion" formControlName="idUbicacionCompartir" (change)="validaCompartir()">
                                  <option value="" disabled selected>Elige la ubicación</option>
                                      <option [value]=gp.idUbicacion *ngFor="let gp of ubicacion">{{gp.nombreUbicacion}}</option>
                              </select>                  
                          </div> 
                      </div>
              </div>
              <br>
              <div class="row">
                <div class="col-4"></div>
                <div class="col-4">
                    <div>
                        <span class="btn btn-primary btnBuscaInvHw" (click)="hardwareCompartir()" data-bs-target="#modalAgregaHardwareCompartir" data-bs-toggle="modal" data-backdrop="static" data-keyboard="false">Agregar Hardware de Usuario</span>
                    </div>
                </div>
                <div class="col-4"></div>
              </div>
              <br>
              <div class="row">
                  <div class="col-4"></div>
                  <div class="col-4" style="text-align: center;">
                      <button class="btn-lg btn-primary  btnAsignarHw" data-bs-dismiss="modal" [disabled]="btnCompartir" style="margin-left: 5px;">Compartir</button>
                  </div>
                  <div class="col-4"></div>
              </div>
          </form>
          <br>


          <div class="row">
            <div class="col-12" style="overflow: auto;">
                <div *ngIf="hayHwCompartir">
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <th scope="col">Tipo</th>
                            <th scope="col">Marca</th>
                            <th scope="col">Modelo</th>
                            <th scope="col">No Serie</th>
                            <th scope="col">No TAG</th>
                            <th scope="col">Eliminar</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let hw of hardwareElegido; let i=index">
                                <td>
                                        {{hw.tipoHW}}
                                </td>
                                <td>
                                        {{hw.marca}}
                                </td>
                                <td>
                                        {{hw.modelo}}
                                </td>
                                <td>
                                        {{hw.numeroSerie}}
                                </td>
                                <td>
                                        {{hw.numeroTag}}
                                </td>
                                <td>
                                  <span class="btn btn-danger" (click)="eliminarHardware()"><i class="feather icon-trash iconos-acciones"></i></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
          </div>
          <hr>
          <div style="text-align: center;">
            <h5>Usuarios con los que se comparte este Software</h5>
          </div>
          <div class="col-md-12" style="overflow: auto;">
            <table class="table table-bordered" *ngIf="usuariosCompartidos.length > 0">
              <thead>
                <tr>
                  <th scope="col">Usuario</th>
                  <th scope="col">Tipo Software</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Hardware</th>
                  <th scope="col">Ubicación</th>
                  <th scope="col">Eliminar</th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let per of usuariosCompartidos">
                      <td>
                              {{per.usuario}}
                      </td>
                      <td>
                              {{per.tripoSw}}
                      </td>
                      <td>
                              {{per.nombreSw}}
                      </td>
                      <td>
                              {{per.modelo}}
                      </td>
                      <td>
                              {{per.nombreUbicacion}}
                      </td>
                      <td style="text-align: center;">
                          <button class="btn btn-warning"  data-bs-dismiss="modal" (click)="eliminarCompartido(per.idSwCompartido,per.idAsignacionSw)">Eliminar  <i class="feather icon-edit iconos-acciones"></i></button> 
                      </td>
                  </tr>
              </tbody>
            </table>
            <table class="table table-bordered" *ngIf="usuariosCompartidos.length == 0">
                <thead>
                  <tr>
                    <th scope="col">Usuario</th>
                    <th scope="col">Elegir</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                      <td align="center" colspan="2">
                             No se ha compartido
                      </td>
                  </tr>
                </tbody>
              </table>
        </div>

      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
