<div class="row">
  <div class="col-2"></div>
  <div class="col-md-5">
    <form [formGroup]="formCars">
      <div class="form-group">
        <label for="caracs" class="control-label">Características</label>
        <select class="form-control" id="caracs" formControlName="cars">
          <option value="" disabled selected>Elige la característica</option>
          <option *ngFor="let cars of caracteristicas" [value]="cars.idCaracteristica">{{cars.caracteristica}}</option>
        </select>
      </div>
    </form>
  </div>
  <div class="col-3" style="text-align: center; margin-bottom: 20px;margin-top: 30px;"
  *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
    <button class="btn btn-lg btn-primary" (click)="mostrarReporte()">Mostrar Reporte</button>
  </div>
  <div class="col-2"></div>
</div>

<div style="overflow: auto;" *ngIf="!cargarLoader">
  <div class="row">
    <div class="col-8"></div>
    <div class="col-2" style="text-align:right ;"
    *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
      <button class="btn btn-primary mb-5" (click)="generatePDF()">Generar PDF</button>
    </div>
    <div class="col-2" style="text-align:right ;"
    *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
      <button class="btn btn-primary mb-5" (click)="exportExcel()">Generar Excel</button>
    </div>
  </div>
  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col">Tipo</th>
        <th scope="col">Modelo</th>
        <th scope="col"># Inventario</th>
        <th scope="col"># Serie</th>
        <th scope="col">TAG</th>
        <th scope="col"
        *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">Caracteristicas</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let per of invHardware">
        <td>
          {{per.tipoHW}}
        </td>
        <td>
          {{per.modelo}}
        </td>
        <td>
          {{per.numeroInvAlm}}
        </td>
        <td>
          {{per.numeroSerie}}
        </td>
        <td>
          {{per.numeroTag}}
        </td>
        <td style="text-align: center;"
        *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
          <button class="btn btn-primary" (click)="verCaracteristicas(per.idInventarioHw)" data-bs-toggle="modal"
            data-bs-target="#modalCaracteristicas"><i class="feather icon-eye"></i></button>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- <pagination-controls *ngIf="invHardware.length > 0" (pageChange)="page = $event" previousLabel="Anterior" S nextLabel="Siguiente"></pagination-controls> -->
  <div class="d-flex justify-content-start align-items-center p-2">
    <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" (pageChange)="loadPage($event)" [disabled]="invHardware == null || invHardware.length == 0">
      <ng-template ngbPaginationPrevious>Anterior</ng-template>
      <ng-template ngbPaginationNext>Siguiente</ng-template>
    </ngb-pagination>
  
    <div class="d-flex align-items-center ml-3">
      <label for="pageInput" class="mr-2">Buscar página específica:</label>
      <input id="pageInput" type="number" class="form-control w-auto"
             #pageInput
             (change)="loadPage(pageInput.value)"
             (keyup.enter)="loadPage(pageInput.value)"
             onkeypress="return(event.charCode >= 48 && event.charCode <= 57)"
             placeholder="" aria-label="Buscar página específica" />
    </div>
  </div>
  
</div>



<!-- Modal -->
<div class="modal fade" id="modalCaracteristicas" tabindex="-1" aria-labelledby="modalCaracteristicas"
  aria-hidden="true" style="z-index: 99999;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Características del Hardware</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Característica</th>
              <th scope="col">Descripción</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let h of carsHardware">
              <td>
                {{h.caracteristica}}
              </td>
              <td>
                {{h.descripcion}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>