<div class="" *ngIf="!isLoading">
    <div>
      <form [formGroup]="formCatSoftware" (submit)="tipoSwChange()">
        <div class="form-group">
            <label for="state_id" class="control-label">Tipo de Software</label>
            <select class="form-control" name="tipoSw" id="tipoSw" formControlName="idTipoSw" #t (change)="tipoSwChange()">
                <option value="" selected disabled>Selecciona una opción</option>
                <option [value]=tiposw.idTipoSw *ngFor="let tiposw of tiposw" >{{tiposw.nombre}}</option>
            </select>                    
        </div>
      </form>
    </div>
    <div class="container" *ngIf="!haveLicense">
      <span>Seleccione un Tipo de Software.</span>
    </div>
    <div class="row" *ngIf="haveLicense">
      <div class="col-8">
        <dx-gantt [taskListWidth]="150" (onSelectionChanged)="OnSelectionChanged($event)" 
          scaleType="months" 
          [showResources]="false" [height]="600" [(selectedRowKey)]="selectedRowKey">
          <dxo-tasks [dataSource]="tasks"></dxo-tasks>
          <dxo-dependencies [dataSource]="dependencies"></dxo-dependencies>
          <dxo-resources [dataSource]="resources"></dxo-resources>
          <dxo-resource-assignments [dataSource]="resourceAssignments"></dxo-resource-assignments>
          <dxi-column dataField="title" caption="Licencias:" [width]="200"></dxi-column>
          <!-- <dxi-column
          dataField="end"
          caption="Vencimiento"
          dataType="date"
          [width]="100"
          ></dxi-column> -->
        </dx-gantt>
      </div>
      <div class="col-4">
        <div class="card" *ngIf="days >= 30">
          <h4 class="card-header">Lote: {{currentTask.lote}}</h4>
          <div class="card-body" >
            <p class="font-weight-light">
              Proveedor: <strong>{{currentTask.proveedor}}</strong> <br>
              Nombre: <strong>{{currentTask.title}}</strong> <br>
              Costo Unitario: <strong>{{currentTask.precioUnitario | currency:'MX':'code'}}</strong> <br>
              Fecha de Registro: <strong>{{currentTask.start | date: 'dd/MM/yyy'}}</strong> <br>
              Fecha de Vencimiento: <strong>{{currentTask.end | date: 'dd/MM/yyy'}}</strong></p>
              <!-- Días para vencer: <strong>{{currentTask.dateDif}}</strong> -->
            <p style="color:red;" *ngIf="currentTask.dateDif<46">Días para vencer: <strong>{{currentTask.dateDif}}</strong></p>
            <p style="color:green;" *ngIf="currentTask.dateDif>45">Días para vencer: <strong>{{currentTask.dateDif}}</strong></p>
            
            <dx-pie-chart id='pie' type="doughnut" [palette]="['lightgreen', 'lightgray']" [(dataSource)]="currentProgress"
              centerTemplate="centerTemplate">
                <dxi-series valueField="val" argumentField="text"></dxi-series>
                <dxo-legend [visible]="false"></dxo-legend>
                <svg *dxTemplate="let pieChart of 'centerTemplate'">
                  <text text-anchor="middle" style="font-size: 18px" x="100" y="120" fill="#494949">
                    <tspan x="100"> Tiempo Consumido</tspan>
                    <tspan x="100" dy="20px" style="font-weight: 600">
                      {{ currentTask.progress | number: '1.0'}} %
                    </tspan>
                  </text>
                </svg>
              </dx-pie-chart>
          </div>
        </div>
        <div class="card" *ngIf="days < 30">
          <h4 class="card-header">Lote: {{currentTask.lote}}</h4>
          <div class="card-body">
            <p class="font-weight-light">
              Proveedor: <strong>{{currentTask.proveedor}}</strong> <br>
              Nombre: <strong>{{currentTask.title}}</strong> <br>
              Costo Unitario: <strong>{{currentTask.precioUnitario | currency:'MX':'code'}}</strong> <br>
              Fecha de Registro: <strong>{{currentTask.start | date: 'dd/MM/yyy'}}</strong> <br>
              Fecha de Vencimiento: <strong>{{currentTask.end | date: 'dd/MM/yyy'}}</strong></p>
              <!-- Días vencido: <strong>{{currentTask.dateDif*-1}}</strong> -->
              <p style="color:red;">Días vencido: <strong>{{currentTask.dateDif*-1}}</strong></p>
              <dx-pie-chart id='pie' type="doughnut" [palette]="['#e32636', 'lightgray']" [(dataSource)]="currentProgress"
              centerTemplate="centerTemplate">
                <dxi-series valueField="val" argumentField="text"></dxi-series>
                <dxo-legend [visible]="false"></dxo-legend>
                <svg *dxTemplate="let pieChart of 'centerTemplate'">
                  <text text-anchor="middle" style="font-size: 18px" x="100" y="120" fill="#494949">
                    <tspan x="100"> Tiempo Consumido</tspan>
                    <tspan x="100" dy="20px" style="font-weight: 600">
                      {{ currentTask.progress | number: '1.0'}} %
                    </tspan>
                  </text>
                </svg>
              </dx-pie-chart>
          </div>
        </div>
    </div>
  </div>
</div>