import { AuthenticationResult } from '@azure/msal-browser';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

const _url = environment.api;

@Injectable({
  providedIn: 'root'
})
export class MicrosoftAuthService {
  private encryptionKey = 'ak-ir.24-';
  constructor(
    private authService: MsalService,
    private router: Router,
    private http: HttpClient
  ) {}

  public handleRedirectPromise() {
    this.authService.instance.handleRedirectPromise().then(res => {
      if (res != null && res.account != null) {
        this.authService.instance.setActiveAccount(res.account)
      }
    })
  }

  public login() {
    this.authService.loginPopup().subscribe((response: AuthenticationResult) => {
        this.authService.instance.setActiveAccount(response.account);

        if (this.isLoggedIn()) {
          localStorage.setItem("tokenMSAL", response.accessToken);
          this.getUser();
        }


      }, error => console.log(error));
  }

  private isLoggedIn(): boolean {
    return this.authService.instance.getActiveAccount() != null
  }

  public getToken() {
    return this.authService.acquireTokenSilent({
      scopes: ["api://898ee150-b50d-4459-ba0f-285138924257/Auth.AD"]
    }).pipe(
      map((res : AuthenticationResult) => res.accessToken)
    )
  }

  private getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }


  private getUser() {
    if (this.isLoggedIn()) {
      this.getToken().subscribe(async token => {
        localStorage.setItem('token', token);
        const tokenInfo = this.getDecodedAccessToken(token);
        if(tokenInfo.roles){
          await this.employeeId(tokenInfo.unique_name);
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('name', tokenInfo.name);
          localStorage.setItem('email', tokenInfo.unique_name);
          localStorage.setItem('roles', JSON.stringify(tokenInfo.roles));
          this.router.navigate(['/']);
        }else{

        }
      });
    }
  }

   public logout() {
    this.authService.logout();
    localStorage.clear();
  }

  employeeId(correo: string){
    const body = {
      correo: correo
    }
    this.http.post(`${_url}Usuarios/GetUsuarioLogueado/`, body ).subscribe( (resp:any)  => {
      localStorage.setItem('employeeId', resp.data.numeroEmpleado)
      const encrypted = CryptoJS.AES.encrypt(resp.data.apiKey, this.encryptionKey).toString();
      localStorage.setItem('akuhandler', encrypted)
    })
  }
}
