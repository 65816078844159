<div class="shadow p-3 mb-5 bg-body rounded">
    <div style="text-align: center;">
        <span><h3>Reasignación de Software</h3></span>
    </div>
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-6">  
            <div class="input-group mb-3">
                <input type="text" class="form-control" (change)="cambioReAsignacionInput()" #txtBuscaAsignacion placeholder="Buscar Asignación" aria-label="Buscar Asignación" aria-describedby="basic-addon2" (keyup.enter)="buscaReAsignacionInput()">
                <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="buscaReAsignacionInput()"><i class="feather icon-search"></i></button>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-2">
            <div class="div-btn-nuevo-hardware">
                <button class="btn btn-primary btnNuevaAsignacion" data-bs-toggle="modal" data-bs-target="#modalNuevaAsignacion" (click)="clicAgregarReAsignacion()">Nueva Asignación</button>
            </div>
        </div> -->
        <div class="col-md-2"></div>
    </div>
    <div class="row">
      <!-- <div class="col-md-2"></div> -->
      <div class="col-md-12" style="overflow: auto;" *ngIf="!cargarLoader">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Folio Asignación</th>
              <th scope="col">Fecha Asignación</th>
              <th scope="col">Usuario</th>
              <th scope="col">Tipo Software</th>
              <th scope="col">Software</th>
              <th scope="col">Nombre</th>
              <th scope="col">Dato Auxiliar</th>
              <th scope="col">Vencimiento</th>
              <th scope="col"
              *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              ]"
              >Reasignar</th>
            </tr>
          </thead>
          <tbody>
              <tr *ngFor="let per of asignacionesSw">
                  <td>
                          {{per.folioAsignacion}}
                  </td>
                  <td>
                          {{per.fechaHoraAsignacion | date:'longDate'}}
                  </td>
                  <td>
                          {{per.usuario}}
                  </td>
                  <td>
                          {{per.tipoSw}}
                  </td>
                  <td>
                          {{per.software}}
                  </td>
                  <td>
                          {{per.nombreSw}}
                  </td>
                  <td>
                          {{per.auxiliar}}
                  </td>
                  <td>
                          {{per.vencimiento | date:'longDate'}}
                  </td>
                  <td 
                  *appHasAnyRole="[
                    rolesAzure.SUPERADMIN, 
                    rolesAzure.SUPERVISOR, 
                    ]"
                  >
                    <span class="btn btn-danger btnReasignar" data-bs-toggle="modal" data-bs-target="#modalReAsignacion" (click)="clicReasignar(per.idInventarioSw,per.idAsignacionSw,per.idUsuario)">Re-Asignar</span>
                  </td>
              </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-start align-items-center p-2">
          <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" (pageChange)="loadPage($event)" [disabled]="asignacionesSw == null || asignacionesSw.length == 0">
            <ng-template ngbPaginationPrevious>Anterior</ng-template>
            <ng-template ngbPaginationNext>Siguiente</ng-template>
          </ngb-pagination>
        
          <div class="d-flex align-items-center ml-3">
            <label for="pageInput" class="mr-2">Buscar página específica:</label>
            <input id="pageInput" type="number" class="form-control w-auto"
                   #pageInput
                   (change)="loadPage(pageInput.value)"
                   (keyup.enter)="loadPage(pageInput.value)"
                   onkeypress="return(event.charCode >= 48 && event.charCode <= 57)"
                   placeholder="" aria-label="Buscar página específica" />
          </div>
        </div>
      </div>
      <!-- <div class="col-md-2"></div> -->
  </div>
</div>

        
<!-- Modal -->
<div class="modal fade" id="modalReAsignacion" tabindex="-1" aria-labelledby="modalReAsignacion" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalNuevaCaracteristica">Re Asignación de inventario de software</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
            <form [formGroup]="formAsignacionSw" (submit)="guardaReAsignacionSw()">
                <div class="row">
                        <div class="col-6">
                                <label for="divIdUsuario" class="control-label">Usuario</label>
                            <div class="input-group mb-3" id="divIdUsuario"> 
                                <input type="text" class="form-control" id="idUsuario" placeholder="Usuario"  formControlName="usuario" [(ngModel)]="usuarioElegido.nombreEmpleado" disabled>                
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" type="button" data-bs-target="#modalEligeUsuario" data-bs-toggle="modal"><i class="feather icon-search"></i></button>
                                    </div>
                                <input type="hidden"  formControlName="idUsuario" [(ngModel)]="usuarioElegido.numeroEmpleado">                 
                            </div>
                        </div>
                        <!-- <div class="col-1">
                            <span  data-bs-target="#modalEligeUsuario" data-bs-toggle="modal"><i class="feather icon-search"></i> </span>
                        </div> -->
                        <div class="col-6">
                            <div class="form-group"> 
                                <label for="idGrupoPermiso" class="control-label">Ubicación</label>
                                <select class="form-control" name="Ubicacion" id="Ubicacion" formControlName="idUbicacion" (change)="validaAsignacion()">
                                    <option value="" disabled selected>Elige la ubicación</option>
                                        <option [value]=gp.idUbicacion *ngFor="let gp of ubicacion">{{gp.nombreUbicacion}}</option>
                                </select>                  
                            </div> 
                        </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12">
                        <!-- <div *ngIf="hayInvSw"> -->
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th scope="col">Software</th>
                                    <th scope="col">Nombre Software</th>
                                    <th scope="col">Temporalidad</th>
                                    <th scope="col">Dato Auxiliar</th>
                                    <!-- <th scope="col">Hardware</th> -->
                                </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let soft of softwareElegido; let i=index">
                                        <td>
                                                {{soft.software}}
                                        </td>
                                        <td>
                                                {{soft.nombreSw}}
                                        </td>
                                        <td>
                                                {{soft.temporalidad}}
                                        </td>
                                        <td>
                                                {{soft.auxiliar}}
                                        </td>
                                        <!-- <td *ngIf="soft.idInventarioHw == null" style="text-align: center;">
                                          <span class="btn btn-primary" (click)="abreAgregarHardware(i)" data-bs-toggle="modal" data-bs-target="#modalAgregaHardware">Agregar Hardware</span>
                                        </td>
                                        <td *ngIf="soft.idInventarioHw != null" style="text-align: center;">
                                                {{soft.idInventarioHw}}
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        <!-- </div> -->
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-4"></div>
                    <div class="col-4" style="text-align: center;">
                        <button class="btn-lg btn-success  btnAsignarHw" [disabled]="btnAsignarSw" data-bs-dismiss="modal" style="margin-left: 5px;">Asignar</button>
                    </div>
                    <div class="col-4"></div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <!-- <button type="submit" [disabled]="formAsignacionHw.invalid" data-bs-dismiss="modal" class="btn btn-primary" (click)="guardaAsignacionHw()">Guardar</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button> -->
        </div>
      </div>
    </div>
  </div>


  <!-- Modal -->
<div class="modal fade" id="modalEligeUsuario" tabindex="-1" aria-labelledby="modalEligeUsuario" aria-hidden="true" >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalNuevaCaracteristica">Elegir Usuario</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">  
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" #txtBuscaUsuario placeholder="Buscar Usuario" aria-label="Buscar Usuario" aria-describedby="basic-addon2" (keyup.enter)="buscaUsuario()">
                        <div class="input-group-append">
                        <button class="btn btn-outline-secondary" type="button" (click)="buscaUsuario()"><i class="feather icon-search"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
        <div class="col-md-12" *ngIf="buscaUsu">
            <table class="table table-bordered" *ngIf="usuarios.length > 0">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Número</th>
                  <th scope="col">Correo</th>
                  <th scope="col">Elegir</th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let per of usuarios">
                      <td>
                              {{per.nombreEmpleado}}
                      </td>
                      <td>
                              {{per.numeroEmpleado}}
                      </td>
                      <td>
                              {{per.correo}}
                      </td>
                      <td style="text-align: center;">
                          <button class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#modalReAsignacion" (click)="eligeUsuario(per.numeroEmpleado)">Elegir  <i class="feather icon-edit iconos-acciones"></i></button> 
                      </td>
                  </tr>
              </tbody>
            </table>
            <table class="table table-bordered" *ngIf="usuarios.length == 0">
                <thead>
                  <tr>
                    <th scope="col">Usuario</th>
                    <th scope="col">Elegir</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                      <td align="center" colspan="2">
                             No hay resultados
                      </td>
                  </tr>
                </tbody>
              </table>
        </div>
      </div>
    </div>
  </div>

  
  <!-- Modal -->
<div class="modal fade" id="modalAgregaHardware" tabindex="-1" role="dialog" aria-labelledby="modalAgregaHardware" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Elegir Hardware</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">  
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" #txtBuscaInventarioHw placeholder="Buscar Inventario" aria-label="Buscar Inventario" aria-describedby="basic-addon2" (keyup.enter)="buscaHardware()">
                        <div class="input-group-append">
                        <button class="btn btn-outline-secondary" type="button" (click)="buscaHardware()"><i class="feather icon-search"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
        
        <div class="col-md-12" *ngIf="buscaInvHw">
            <table class="table table-bordered" *ngIf="invHardware.length > 0">
                <thead>
                    <tr>
                      <!-- <th scope="col">ID</th> -->
                      <th scope="col">Hardware</th>
                      <th scope="col">Marca</th>
                      <th scope="col">Modelo</th>
                      <th scope="col"># Serie</th>
                      <th scope="col">TAG</th>
                      <th scope="col">Elegir</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let det of invHardware">
                        <!-- <td>
                            {{det.idInventarioHW}}
                        </td> -->
                        <td>
                            {{det.tipoHW}}
                        </td>
                        <td>
                            {{det.marca}}
                        </td>
                        <td>
                            {{det.modelo}}
                        </td>
                        <td>
                            {{det.numeroSerie}}
                        </td>
                        <td>
                            {{det.numeroTag}}
                        </td>
                        <td style="text-align: center;">
                            <button class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#modalReAsignacion" (click)="eligeHardware(det.idInventarioHW)">Elegir  <i class="feather icon-edit iconos-acciones"></i></button> 
                        </td>
                  </tr>
              </tbody>
            </table>
            <table class="table table-bordered" *ngIf="invHardware.length == 0">
                <thead>
                    <tr>
                      <th scope="col">Hardware</th>
                      <th scope="col">Marca</th>
                      <th scope="col">Modelo</th>
                      <th scope="col"># Serie</th>
                      <th scope="col">TAG</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td align="center" colspan="5">
                                No hay resultados
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
      </div>
    </div>
  </div>
</div>
  
  