import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment.prod';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Destinatario } from 'src/app/model/destinatario';
import { HttpClient } from '@angular/common/http';
import { InventarioService } from '../../../data/service/inventario.service';
import Swal from 'sweetalert2';
import { Roles } from 'src/app/model';

@Component({
  selector: 'app-catalogo-desino-notificaciones',
  templateUrl: './catalogo-desino-notificaciones.component.html',
  styleUrls: ['./catalogo-desino-notificaciones.component.scss']
})
export class CatalogoDesinoNotificacionesComponent implements OnInit {
  //#region PAGINATION
  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;
  //#endregion

  public formDestino: FormGroup;
  public formEditaDestino: FormGroup;
  private url: string = environment.api;
  public destinos: Destinatario[] = [];
  // public destinoEditar: any[] = [];

  destinoEditar: any = {
    numeroEmpleado: null,
    nombreEmpleado: '',
    correo: null,
    idEmpleado: null
  }

  public rolesAzure = Roles;

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService) {
    this.formDestino = this.formBuilder.group({
      numeroEmpleado: ['', [Validators.required]],
      correo: ['', [Validators.required]],
      nombreEmpleado: ['', [Validators.required]],
    })

    this.formEditaDestino = this.formBuilder.group({
      idEmpleado: ['', [Validators.required]],
      numeroEmpleado: ['', [Validators.required]],
      correo: ['', [Validators.required]],
      nombreEmpleado: ['', [Validators.required]],
    })
  }

  ngOnInit() {
    this.getDestinatarios()
  }

  getDestinatarios() {
    this.destinos = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    this.inventarioService.getDestinatarios(this.page).subscribe((respuesta: any) => {
      if (respuesta['result'] === 0) {
        if (!Swal.isVisible()) {
          Swal.fire({
            title: "ERROR",
            text: "No hay coincidencias con tu criterio de búsqueda",
            type: "warning"
          }).then(() => {
            if (this.page != 1) {
              this.page = 1;
              this.getDestinatarios();
            } else {
              this.page = 1;
              this.getDestinatarios();
            }
          })
        }
      }
      this.destinos = respuesta.data;
      this.totalRecords = respuesta.totalRecords;
      this.cargarLoader = false;
    }, error => {
      Swal.fire({
        title: "ERROR",
        text: `Error ${error}`,
        type: "warning"
      })
      this.cargarLoader = false;
    })
  }

  guardaDestino() {
    this.inventarioService.createDestinatario(this.formDestino.value).subscribe(data => {
      if (data['result'] === 1) {
        this.page = 1
        this.formDestino.reset();
        Swal.fire({
          title: "Guardado",
          text: "Se ha registrado exitosamente",
          type: "success",
          // timer: 3000
        })
        this.getDestinatarios()
      } else {
        Swal.fire({
          title: "ERROR",
          text: data['message'],
          type: "warning",
          // timer: 2000
        })
      }

    })
  }

  noSpecialChar() {

  }

  abreEditaDestino(idEmpleado) {
    this.inventarioService.getDestinatarioxId(idEmpleado).subscribe((resped: any) => {
      this.destinoEditar = resped.data
    })
  }

  editaDestino() {
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se modificará el destinatario, ya no será notificado",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.dismiss) {

      } else {
        this.inventarioService.updateDestinatario(this.formEditaDestino.value).subscribe(data => {
          if (data['result'] === 1) {
            this.page = 1
            this.formEditaDestino.reset();
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
              // timer: 3000
            })
            this.getDestinatarios()
          } else {
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
              // timer: 2000
            })
          }

        })
      }

    })


  }

  eliminarDestino(idDestino) {

    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se eliminará el destinatario",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.dismiss) {

      } else {
        this.inventarioService.deleteDestinatario(idDestino).subscribe(data => {
          if (data['result'] === 1) {
            this.page = 1
            this.formEditaDestino.reset();
            Swal.fire({
              title: "Guardado",
              text: "Se ha eliminado exitosamente",
              type: "success",
              // timer: 3000
            })
            this.getDestinatarios()
          } else {
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
              // timer: 2000
            })
          }

        })
      }
    })

  }

  loadPage(page: number) {
    this.page = page;
    this.getDestinatarios();
  }
}