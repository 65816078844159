<div class="shadow p-3 mb-5 bg-body rounded">
    <div style="text-align: center;">
        <span>
            <h3>Catálogo Marca</h3>
        </span>
    </div>
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-5">
            <div class="input-group mb-3">
                <input type="text" class="form-control" (change)="cambioBuscaMarcaInput()" #txtBuscaMarca
                    placeholder="Buscar Marca" aria-label="Buscar Marca" aria-describedby="basic-addon2"
                    (keyup.enter)="buscaMarcasInput()">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button"><i class="feather icon-search"
                            (click)="buscaMarcasInput()"></i></button>
                </div>
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>
    <div class="row"
    *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              ]">
        <div class="col-md-4"></div>
        <div class="col-md-5">
            <form [formGroup]="formMarca" (submit)="guardaMarca()">
                <div class="input-group mb-3">
                    <input type="text" class="form-control" maxlength="200" (keypress)="noSpecialChar($event)"
                        placeholder="Ingresar Marca *" aria-label="Ingresar Marca" aria-describedby="basic-addon2"
                        formControlName="nombreMarca">
                    <div class="input-group-append">
                        <button class="btn btn-primary" [disabled]="formMarca.invalid">Agregar</button>
                    </div>
                </div>
            </form>

        </div>
        <div class="col-md-4"></div>
    </div>


    <div class="row" *ngIf="!cargarLoader">
        <div class="col-md-2"></div>
        <div class="col-md-9">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Marca</th>
                        <th scope="col"
                        *appHasAnyRole="[
                        rolesAzure.SUPERADMIN, 
                        rolesAzure.SUPERVISOR, 
                        ]">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let marc of marcas">
                        <td>
                            {{marc.nombreMarca}}
                        </td>
                        <td style="text-align: center;"
                        *appHasAnyRole="[
                        rolesAzure.SUPERADMIN, 
                        rolesAzure.SUPERVISOR, 
                        ]">
                            <button class="btn btn-warning" (click)="abreEditaMarca(marc.idMarca)"
                                data-bs-toggle="modal" data-bs-target="#modalEditaMarca">Editar<i
                                    class="feather icon-edit iconos-acciones"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- <pagination-controls (pageChange)="page = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls> -->

            <div class="d-flex justify-content-start align-items-center p-2">
                <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
                    (pageChange)="loadPage($event)" [disabled]="marcas == null || marcas.length == 0">
                    <ng-template ngbPaginationPrevious>Anterior</ng-template>
                    <ng-template ngbPaginationNext>Siguiente</ng-template>
                </ngb-pagination>

                <div class="d-flex align-items-center ml-3">
                    <label for="pageInput" class="mr-2">Buscar página específica:</label>
                    <input id="pageInput" type="number" class="form-control w-auto" #pageInput
                        (change)="loadPage(pageInput.value)" (keyup.enter)="loadPage(pageInput.value)"
                        onkeypress="return(event.charCode >= 48 && event.charCode <= 57)" placeholder=""
                        aria-label="Buscar página específica" />
                </div>
            </div>

        </div>
        <div class="col-md-2"></div>
    </div>

</div>


<div class="modal fade" id="modalEditaMarca" tabindex="-1" aria-labelledby="modalEditaMarca" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalEditaCaracteristica">Editar</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formEditaMarca" (submit)="editaMarca()">
                    <input hidden="true" [(ngModel)]="marcaEditar.idMarca" formControlName="idMarca">
                    <div class="form-group">
                        <label class="control-label is-required" for="nombreCar">Nombre Marca</label>
                        <input type="text" class="form-control" id="nombreCar" (keypress)="noSpecialChar($event)"
                            maxlength="200" placeholder="Nombre Marca" formControlName="nombreMarca"
                            [(ngModel)]="marcaEditar.nombreMarca">
                    </div>
                    <div style="text-align: center;">
                        <button type="submit" [disabled]="formEditaMarca.invalid" data-bs-dismiss="modal"
                            class="btn btn-primary">Guardar</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    </div>
                </form>
            </div>
            <div class="modal-footer">

            </div>
        </div>
    </div>
</div>