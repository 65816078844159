<div class="shadow p-3 mb-5 bg-body rounded">
    <div style="text-align: center;">
        <span><h3>Catálogo Situaciones</h3></span>
    </div>
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-5">
            <div class="input-group mb-3">
                <input type="text" class="form-control"  (change)="cambioBuscaSituacionInput()" placeholder="Buscar Situacion" #txtBuscaSituacion aria-label="Buscar Situacion" aria-describedby="basic-addon2" (keyup.enter)="buscarSituaciones()">
                <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="buscarSituaciones()"><i class="feather icon-search"></i></button>
                </div>
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>
    <div class="row" 
    *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
        <div class="col-md-4"></div>
        <div class="col-md-5">
            <form [formGroup]="formSituacion" (submit)="guardaSituacion()">
                <div class="input-group mb-3">
                    <input type="text" class="form-control" (keypress)="noSpecialChar($event)" placeholder="Ingresar Situacion*" maxlength="100" aria-label="Ingresar Situacion" aria-describedby="basic-addon2" formControlName="nombre">
                    <div class="input-group-append">
                    <button class="btn btn-primary" [disabled]="formSituacion.invalid">Agregar</button>
                    </div>
                </div>
            </form>    
        </div>
            <div class="col-md-4"></div>
    </div>
    

    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-9">
            <table class="table table-bordered">
                <thead>
                <tr>
                    <th scope="col">Nombre Situación</th>
                    <th scope="col">Acciones</th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sit of situacion">
                        <td>
                                {{sit.nombre}}
                        </td>
                        <td style="text-align: center;"
                        *appHasAnyRole="[
                        rolesAzure.SUPERADMIN, 
                        rolesAzure.SUPERVISOR, 
                        rolesAzure.ING_SOPORTE
                        ]">
                            <button class="btn btn-warning" (click)="abreEditarSituaciones(sit.idSituacion)" data-bs-toggle="modal" data-bs-target="#modalEditaSituacion">Editar<i class="feather icon-edit iconos-acciones"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-start align-items-center p-2">
                <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" (pageChange)="loadPage($event)" [disabled]="situacion == null || situacion.length == 0">
                  <ng-template ngbPaginationPrevious>Anterior</ng-template>
                  <ng-template ngbPaginationNext>Siguiente</ng-template>
                </ngb-pagination>
              
                <div class="d-flex align-items-center ml-3">
                  <label for="pageInput" class="mr-2">Buscar página específica:</label>
                  <input id="pageInput" type="number" class="form-control w-auto"
                         #pageInput
                         (change)="loadPage(pageInput.value)"
                         (keyup.enter)="loadPage(pageInput.value)"
                         onkeypress="return(event.charCode >= 48 && event.charCode <= 57)"
                         placeholder="" aria-label="Buscar página específica" />
                </div>
              </div>
        </div>
            <div class="col-md-2"></div>
    </div>
   
        
    <div class="modal fade" id="modalEditaSituacion" tabindex="-1" aria-labelledby="modalEditaSituacion" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalEditaTipoSw">Editar</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formEditarSituacion" (submit)="editaSituacion()">
                    <input hidden="true" [(ngModel)]="situacionEditar.idSituacion"  formControlName="idSituacion">
                    <div class="form-group"> 
                        <label class="control-label is-required" for="nombreCar">Nombre Situacion</label>
                        <input type="text" class="form-control" id="nombreCar" (keypress)="noSpecialChar($event)" maxlength="200" placeholder="Ingresar Situacion*" formControlName="nombre" [(ngModel)]="situacionEditar.nombre">
                    </div>   
                    <div style="text-align: center;"> 
                        <button type="submit" [disabled]="formEditarSituacion.invalid" data-bs-dismiss="modal" class="btn btn-primary">Guardar</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    </div>     
                </form>
            </div>
            <div class="modal-footer">
                
            </div>
          </div>
        </div>
      </div>