import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators       } from '@angular/forms';
import { HttpClient                               } from '@angular/common/http';
import   Swal                                       from 'sweetalert2';

import { grupoPermiso } from 'src/app/model/grupoPermiso';
import { environment  } from 'src/environments/environment';
import { permiso      } from 'src/app/model/permiso';

@Component({
  selector:     'app-catalogo-permisos',
  templateUrl:  './catalogo-permisos.component.html',
  styleUrls:   ['./catalogo-permisos.component.scss']
})
export class CatalogoPermisosComponent implements OnInit {

  private url: string = environment.api;
  public formPermisos  : FormGroup;
  public formGrupo     : FormGroup;
  public grupoPermisos : grupoPermiso [] = [];
  public permisosLista : permiso      [] = [];
  public permisos      : permiso      [] = [];
  public page          : 1;
  
  @ViewChild('txtBuscaPermiso',      {static: false}) txtBuscaPermiso!      :ElementRef<HTMLInputElement>;
  @ViewChild('txtBuscaGrupoPermiso', {static: false}) txtBuscaGrupoPermiso! :ElementRef<HTMLInputElement>;

  constructor(private http: HttpClient, private formBuilder: FormBuilder){
    this.formPermisos = this.formBuilder.group({
      nombrePermiso: ['',[Validators.required]],
    })
    this.formGrupo = this.formBuilder.group({
      alias: ['',[Validators.required]],
      descripcion: ['',[Validators.required]],
      idPermiso: ['',[Validators.required]],
    })
  }

  ngOnInit() {
    this.buscaPermisos()
  }

  buscaPermisos(){
    this.http.get(`${this.url}Usuarios/Permisos/limit/100`).subscribe((respPermisos:any) =>{
      this.permisos = respPermisos.data;
    })
  }

  cambioPermisoInput(){
    let valor = this.txtBuscaPermiso.nativeElement.value;
    if (valor === '') {
      this.page = 1
      this.buscaPermisos();
    }
  }

  buscaPermisoInput(){
    let valor = this.txtBuscaPermiso.nativeElement.value;
    if (valor === '') {
      this.page = 1
      this.buscaPermisos();
    } else {
      let datoPostPermiso={
          "nombreTabla": "permiso",
          "valorBusqueda": valor,
        }
      this.http.post(`${this.url}TablaCampo/`,datoPostPermiso).subscribe((respPermiso:any) =>{
        if (respPermiso['result'] === 0) {
          Swal.fire({
            title: "ERROR",
            text: "No hay coincidencias con tu criterio de búsqueda",
            type: "warning",
          })
        } else {
          this.page = 1
          this.permisos = respPermiso.data;
        }
      }) 
    }
  }

  guardaPermiso(){
    this.http.post(`${this.url}Usuarios/Permisos`, this.formPermisos.value ).subscribe(data => {
      if (data['result'] === 1) {
        this.page = 1
        this.formPermisos.reset();
        this.buscaPermisos();
        Swal.fire({
          title : "Guardado",
          text  : "Se ha registrado exitosamente",
          type  : "success",
        });
      }else{
        Swal.fire({
          title : "ERROR",
          text  : data['message'],
          type  : "warning",
        });
      }
    });
  }

  verPermisos(perms){
    this.http.get(`${this.url}Usuarios/Permisos/list?id=`+perms).subscribe((respPerm:any) =>{
      this.permisosLista = respPerm.data;
    })
  }

  noSpecialChar(char){
  const onlyspecial = /[°¬_"&@!=¡¿¨´~`',;:.*+\-?^$/{}<>()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
      return false;
    }
    return true
  }
  
}