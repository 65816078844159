<div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-9">
        <div class="input-group mb-3">
            <input type="text" class="form-control" (change)="cambioBuscaFacSoftInput()" #txtBuscaFacSoft
                placeholder="Buscar Factura" aria-label="Buscar Factura" aria-describedby="basic-addon2"
                (keyup.enter)="buscaFacSoftInput()">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="buscaFacSoftInput()" type="button"><i
                        class="feather icon-search"></i></button>
            </div>
        </div>
    </div>
    <div class="col-md-2"
    *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              ]">
        <div class="div-btn-nuevo-hardware">
            <button class="btn btn-primary btnNuevaFactura" data-bs-toggle="modal" data-bs-target="#modalNuevaFactura"
                (click)="clicAgregarFactura()">Agregar</button>
        </div>
    </div>
</div>

<div *ngIf="!cargarLoader">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th scope="col"># Factura</th>
                <th scope="col">Proveedor</th>
                <th scope="col">Subtotal</th>
                <th scope="col">Total</th>
                <th scope="col">Detalle</th>
                <th scope="col"
                *appHasAnyRole="[
                rolesAzure.SUPERADMIN, 
                rolesAzure.SUPERVISOR, 
                ]">Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let fac of facturasSoftware">
                <td>
                    {{fac.numeroFactura}}
                </td>
                <td>
                    {{fac.proveedor}}
                </td>
                <td style="text-align: right;">
                    {{fac.subtotal | currency:"MXN":"symbol"}}
                </td>
                <td style="text-align: right;">
                    {{fac.total | currency:"MXN":"symbol"}}
                </td>
                <td style="text-align: center;">
                    <button class="btn btn-primary" (click)="muestraDetalleFacturas(fac.idFactura)"
                        data-bs-toggle="modal" data-bs-target="#modalDetalle">Detalle <i
                            class="feather icon-eye"></i></button>
                </td>
                <td style="text-align: center;"
                *appHasAnyRole="[
                rolesAzure.SUPERADMIN, 
                rolesAzure.SUPERVISOR, 
                ]">
                    <button class="btn btn-secondary" (click)="abreModalDocumentos(fac.idFactura)"
                        data-bs-toggle="modal" data-bs-target="#modalDocumentos">Documentos <i
                            class="feather icon-upload iconos-acciones"></i></button>
                    <button class="btn btn-warning" (click)="abreEditaFactura(fac.idFactura)" data-bs-toggle="modal"
                        data-bs-target="#modalEditaFactura" style="margin-left: 5px;">Editar <i
                            class="feather icon-edit iconos-acciones"></i></button>
                    <button class="btn btn-danger" (click)="abreBajaFactura(fac.idFactura)" data-bs-toggle="modal"
                        data-bs-target="#modalBajaFactura" style="margin-left: 5px;">Baja <i
                            class="feather icon-trash-2 iconos-acciones"></i></button>
                </td>
            </tr>
        </tbody>
    </table>
    <!-- <pagination-controls (pageChange)="page = $event" previousLabel="Anterior" S nextLabel="Siguiente"></pagination-controls> -->

    <div class="d-flex justify-content-start align-items-center p-2">
        <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
            (pageChange)="loadPage($event)" [disabled]="invHardware == null || invHardware.length == 0">
            <ng-template ngbPaginationPrevious>Anterior</ng-template>
            <ng-template ngbPaginationNext>Siguiente</ng-template>
        </ngb-pagination>

        <div class="d-flex align-items-center ml-3">
            <label for="pageInput" class="mr-2">Buscar página específica:</label>
            <input id="pageInput" type="number" class="form-control w-auto" #pageInput
                (change)="loadPage(pageInput.value)" (keyup.enter)="loadPage(pageInput.value)"
                onkeypress="return(event.charCode >= 48 && event.charCode <= 57)" placeholder=""
                aria-label="Buscar página específica" />
        </div>
    </div>

</div>

<!-- Modal -->
<div class="modal fade" id="modalDetalle" tabindex="-1" aria-labelledby="modalDetalle" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalNuevaCaracteristica">Detalle de la factura</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body" style="overflow: auto;">
                <table class="table table-bordered" *ngIf="detalleArraySw.length > 0">
                    <thead>
                        <tr>
                            <th scope="col">Nombre SW</th>
                            <th scope="col">Tipo SW</th>
                            <th scope="col">Software</th>
                            <th scope="col">Lote</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Vencimiento</th>
                            <th scope="col">Temporalidad</th>
                            <th scope="col">Auxiliar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let det of detalleArraySw">
                            <td>
                                {{det.nombreSw}}
                            </td>
                            <td>
                                {{det.tipoSw}}
                            </td>
                            <td>
                                {{det.nombre}}
                            </td>
                            <td>
                                {{det.lote}}
                            </td>
                            <td>
                                {{det.cantidad}}
                            </td>
                            <td>
                                {{det.vencimiento | date:'longDate'}}
                            </td>
                            <td>
                                {{det.temporalidad}} Meses
                            </td>
                            <td>
                                {{det.auxiliar}}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table class="table table-bordered" *ngIf="detalleArraySw.length == 0">
                    <thead>
                        <tr>
                            <th scope="col">Nombre SW</th>
                            <th scope="col">Tipo SW</th>
                            <th scope="col">Lote</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Vencimiento</th>
                            <th scope="col">Temporalidad</th>
                            <th scope="col">Auxiliar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td align="center" colspan="8">
                                No hay datos de detalle de software
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table class="table table-bordered" *ngIf="detalleArrayHw.length > 0">
                    <thead>
                        <tr>
                            <th scope="col">Tipo HW</th>
                            <th scope="col">Modelo</th>
                            <th scope="col">Precio U</th>
                            <th scope="col">Lote</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Datos</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let det of detalleArrayHw">
                            <td>
                                {{det.tipoHw}}
                            </td>
                            <td>
                                {{det.modelo}}
                            </td>
                            <td>
                                {{det.precioUnitario | currency:"MXN":"symbol"}}
                            </td>
                            <td>
                                {{det.lote}}
                            </td>
                            <td>
                                {{det.cantidad}}
                            </td>
                            <td style="text-align: center;">
                                <button class="btn btn-primary" (click)="muestraDatosDetalle(det.lote,det.idFactura)"
                                    data-bs-toggle="modal" data-bs-target="#modalDatosDetalle">Datos <i
                                        class="feather icon-eye"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table class="table table-bordered" *ngIf="detalleArrayHw.length == 0">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Tipo HW</th>
                            <th scope="col">Precio U</th>
                            <th scope="col">Lote</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Datos</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td align="center" colspan="6"> No hay datos de Detalle de Hw</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalNuevaFactura" tabindex="-1" role="dialog" aria-labelledby="modalNuevaFactura"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalNuevaCaracteristica">Agregar nueva factura</h5>
                <button type="button" class="btn-close cerrarModalNuevaFactura" data-bs-dismiss="modal"
                    aria-label="Close">X</button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div id="divFactura">
                        <form [formGroup]="formFactura" (submit)="guardaFactura()" autocomplete="off">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label is-required" for="numeroFactura">Folio Fiscal
                                            (Último bloque)</label>
                                        <input type="text" class="form-control" (keypress)="noSpecialChar($event)"
                                            autocomplete="off" id="numeroFactura" maxlength="12"
                                            (focusout)="validaFolioFiscal($event.target.value)"
                                            (change)="validarForms();validaFechaNoFactura()" placeholder="12 caracteres"
                                            #numeroFactura formControlName="numeroFactura">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label is-required" for="proveedor">Proveedor</label>
                                        <input type="text" class="form-control" id="proveedor" (change)="validarForms()"
                                            autocomplete="off" placeholder="proveedor" maxlength="100"
                                            formControlName="proveedor">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="control-label is-required" for="fecha">Fecha</label>
                                        <input type="date" class="form-control" id="fecha" (keydown)="disableDate()"
                                            (change)="validarForms();validaFechaNoFactura()" #fecha placeholder="fecha"
                                            formControlName="fecha">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label is-required" for="subtotal">Subtotal</label>
                                        <input type="text" min="1" class="form-control" id="subtotal" autocomplete="off"
                                            #subtotal (focus)="focusSubtotal()" (change)="validarForms()"
                                            (keypress)="noSpecialCharPrecios($event)"
                                            (focusout)="formatearSubtotal($event.target.value)" placeholder="$0.00"
                                            [(value)]="subTotalFormat" formControlName="subtotal">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label is-required" for="total">Total</label>
                                        <input type="text" class="form-control" id="total" autocomplete="off" #total
                                            (focus)="focusTotal()" (change)="validarForms();menosTotal()"
                                            (keypress)="noSpecialCharPrecios($event)"
                                            (focusout)="formatearTotal($event.target.value)" placeholder="$0.00"
                                            [(value)]="totalFormat" formControlName="total">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="control-label" for="total">Observaciones</label>
                                        <textarea type="text" min="1" class="form-control" autocomplete="off"
                                            (keypress)="noSpecialChar($event)" id="observacion"
                                            (change)="validarForms()" placeholder="observacion"
                                            formControlName="observacion"></textarea>
                                    </div>
                                </div>
                            </div>

                            <hr>
                            <div class="row" style="text-align: center;">
                                <div class="col-12">
                                    <h3>Detalle de factura</h3>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-4">
                                    <div>
                                        <span class="btn btn-primary" *ngIf="btnAgregaDetSw == false"
                                            (click)="clicSpanAgregarDetalle()" data-bs-dismiss="modal">Agregar Detalle
                                            Software</span>
                                        <span class="btn btn-primary" data-bs-target="#agregarDetalle"
                                            data-bs-toggle="modal" *ngIf="btnAgregaDetSw == true"
                                            (click)="clicAgregarDetalleSw()" data-bs-dismiss="modal">Agregar Detalle
                                            Software</span>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div>
                                        <span class="btn btn-primary" *ngIf="btnAgregaDetHw == false"
                                            (click)="clicSpanAgregarDetalle()" data-bs-dismiss="modal">Agregar Detalle
                                            Hardware</span>
                                        <span class="btn btn-primary" data-bs-target="#agregarDetalleHw"
                                            data-bs-toggle="modal" *ngIf="btnAgregaDetHw == true"
                                            (click)="clicAgregarDetalle()" data-bs-dismiss="modal"
                                            data-backdrop="static" data-keyboard="false">Agregar Detalle Hardware</span>
                                    </div>
                                </div>
                                <div class="col-2"></div>
                            </div>
                            <div *ngIf="hayInvSw">
                                <div style="text-align: center;">
                                    <h3>Detalle de Software</h3>
                                </div>

                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nombre SW</th>
                                            <th scope="col">Lote</th>
                                            <th scope="col">Cantidad</th>
                                            <th scope="col">Vencimiento</th>
                                            <th scope="col">Temporalidad</th>
                                            <th scope="col">Auxiliar</th>
                                            <th scope="col">Borrar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let det of detalleArrayGuardar; let i=index">
                                            <td>
                                                {{det.nombreSw}}
                                            </td>
                                            <td>
                                                {{det.lote}}
                                            </td>
                                            <td>
                                                {{det.cantidad}}
                                            </td>
                                            <td>
                                                {{det.vencimiento}}
                                            </td>
                                            <td>
                                                {{det.temporalidad}} Meses
                                            </td>
                                            <td>
                                                {{det.auxiliar}}
                                            </td>
                                            <td>
                                                <span class="btn btn-danger" (click)="eliminarDetalle(i,det.lote)"><i
                                                        class="feather icon-trash iconos-acciones"></i></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="hayInvHw">
                                <div style="text-align: center;">
                                    <h3>Detalle de Hardware</h3>
                                </div>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Hardware</th>
                                            <th scope="col">Lote</th>
                                            <th scope="col">Cantidad</th>
                                            <th scope="col">Borrar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let det of detalleArrayGuardarHw; let i=index">
                                            <td>
                                                {{det.idHardware}}
                                            </td>
                                            <td>
                                                {{det.lote}}
                                            </td>
                                            <td>
                                                {{det.cantidad}}
                                            </td>
                                            <td>
                                                <span class="btn btn-danger" (click)="eliminarDetalleHw(i,det.lote)"><i
                                                        class="feather icon-trash iconos-acciones"></i></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr>
                            <div class="row" style="text-align: center;">
                                <div class="col-2"></div>
                                <div class="col-4">
                                    <button type="submit" class="btn btn-primary" [disabled]="btnGuardaFactura"
                                        data-bs-dismiss="modal">Guardar Factura</button>
                                </div>
                                <div class="col-2">
                                    <span class="btn btn-secondary" (click)="limpiarFactura()"
                                        data-bs-dismiss="modal">Limpiar</span>
                                </div>
                                <div class="col-4"></div>
                            </div>
                            <hr>

                            <div class="modal-footer">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="agregarDetalleHw" aria-hidden="true" aria-labelledby="agregarDetalleHw" tabindex="-1"
    data-backdrop="static" data-keyboard="false" role="dialog">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalToggleLabel2">Agregar Detalle de Factura</h5>
            </div>
            <div class="modal-body">
                <form [formGroup]="formDetalleHw" (submit)="agregarDetalleHw()" autocomplete="off">
                    <div class="row">

                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label is-required" for="preciou">Precio Unitario</label>
                                <input type="number" min="1.00" step="0.01" autocomplete="off" class="form-control"
                                    id="preciou" placeholder="Precio Unitario" formControlName="precioUnitario"
                                    (change)="validarAgregarDetalle()">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label" for="lote">Lote</label>
                                <input type="text" class="form-control" [disabled]="inputLote"
                                    (ngModelChange)="cambioCantHw($event)" id="lote" disabled placeholder="Lote" #loteHw
                                    formControlName="lote">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label is-required" autocomplete="off"
                                    for="cantidad">Cantidad</label>
                                <input type="number" class="form-control" (ngModelChange)="cambioCantHw($event)"
                                    (keypress)="noSpecialChar($event);" min="1" id="cantidadHw" #cantidadHw
                                    placeholder="Cantidad" formControlName="cantidad">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="state_id" class="control-label is-required">Tipo</label>
                                <select class="form-control" id="tipoHw" formControlName="idTipoHw"
                                    (change)="cambioTipo($event.target.value)">
                                    <option value="" disabled selected>Elige el Tipo</option>
                                    <option [value]=cat.idTipoHw *ngFor="let cat of tipohw">{{cat.nombre}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="state_id" class="control-label is-required">Modelo</label>
                                <select class="form-control" id="tipoHw" formControlName="idHardware" #idHardware
                                    (ngModelChange)="validarAgregarDetalle()">
                                    <option value="" selected>Elige el hardware</option>
                                    <option [value]=cat.idHardware *ngFor="let cat of catHardware">{{cat.modelo}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group" style="z-index: 1073;"> <!-- State Button -->
                                <label for="idCaracteristica" class="control-label is-required">Características de
                                    Hardware</label>
                                <mat-form-field appearance="fill" style="width: 100%;">
                                    <mat-label>Características</mat-label>
                                    <mat-select multiple formControlName="cars" required
                                        class="cdk-overlay-connected-position-bounding-box" style="z-index: 1073;"
                                        (ngModelChange)="validarAgregarDetalle()">
                                        <mat-option *ngFor="let cars of caracteristicas"
                                            [value]="cars.idCaracteristica">{{cars.caracteristica}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="btnAgregaDatosDetalle" style="text-align: right;">
                        <span class="btn btn-primary btn_agregarDatosDetalle" data-bs-target="#modalagregarDatosDetalle"
                            (click)="clicAgregarDatosDetHw()" data-bs-toggle="modal">Agregar Datos del detalle</span>
                    </div>
                    <table class="table table-bordered" *ngIf="tblAgregaDatosDetalle">
                        <thead>
                            <tr>
                                <th scope="col"># Serie</th>
                                <th scope="col"># Inventario</th>
                                <th scope="col">Borrar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let det of datosdetalle; let i=index">
                                <td>
                                    {{det.numeroSerie}}
                                </td>
                                <td>
                                    {{det.numeroInvAlm}}
                                </td>
                                <td>
                                    <span class="btn btn-danger" (click)="eliminarDatosDetalleHw(i)"><i
                                            class="feather icon-trash iconos-acciones"></i></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <button class="btn btn-primary" [disabled]="validaAgregarDetalle"
                            data-bs-target="#modalNuevaFactura" data-bs-toggle="modal" data-bs-dismiss="modal"
                            (click)="agregarDetalleHw()">Agregar Detalle</button>


                        <button type="button" class="btn btn-secondary" data-bs-target="#modalNuevaFactura"
                            data-bs-toggle="modal" data-bs-dismiss="modal"
                            (click)="clicCancelarDetHw()">Cancelar</button>
                    </div>
                </form>
            </div>
            <div class="modal-footer">

            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalagregarDatosDetalle" aria-hidden="true" aria-labelledby="agregarDatosDetalle"
    tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalToggleLabel2">Agregar Datos de Detalle</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    #btnCerrarModalDatosDetalle></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formDatosDetalle" autocomplete="off">
                    <div class="form-group">
                        <label class="control-label" for="preciou">Número de Serie</label>
                        <input type="text" (keypress)="noSpecialChar($event)"
                            (focusout)="noSerieRepetido($event.target.value)" autocomplete="off" maxlength="30"
                            class="form-control" id="noSerie" placeholder="Número de Serie"
                            formControlName="numeroSerie">
                    </div>
                    <div class="form-group">
                        <label class="control-label" for="preciou">Número de Inventario de Almacén</label>
                        <input type="text" (keypress)="noSpecialCharNoInv($event)"
                            (focusout)="noInvRepetido($event.target.value)" autocomplete="off" maxlength="30"
                            class="form-control" id="noInvAlm" placeholder="Número de Inventario de Almacén"
                            formControlName="numeroInvAlm">
                    </div>
                    <button class="btn btn-primary" [disabled]="bandAgregaDatosDetalle"
                        data-bs-target="#agregarDetalleHw" data-bs-toggle="modal" data-bs-dismiss="modal"
                        (click)="agregarDatosDetalle()">Agregar Datos</button>
                    <button type="button" class="btn btn-secondary" data-bs-target="#agregarDetalleHw"
                        data-bs-toggle="modal" data-bs-dismiss="modal">Cancelar</button>
                </form>
            </div>
            <div class="modal-footer">

            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="agregarDetalle" aria-hidden="true" aria-labelledby="agregarDetalle" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalToggleLabel2">Agregar Detalle de Factura</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formDetalle" (submit)="agregarDetalle()" autocomplete="off">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="state_id" class="control-label is-required">Software</label>
                                <select class="form-control" id="tipoHw" formControlName="idSoftware">
                                    <option value="" disabled selected>Elige el software</option>
                                    <option [value]=cat.idSoftware *ngFor="let cat of catSoftware">{{cat.nombre}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="control-label is-required" for="preciou">Precio Unitario</label>
                                <input type="number" min="1" class="form-control" autocomplete="off" id="preciou"
                                    placeholder="Precio Unitario" formControlName="precioUnitario">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="control-label" for="lote">Lote</label>
                                <input type="text" class="form-control" id="lote" placeholder="Lote"
                                    formControlName="lote" disabled>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="control-label is-required" for="vencimiento">Vencimiento</label>
                                <input type="date" class="form-control" id="vencimiento"
                                    (keypress)="noSpecialCharPrecios($event)" (keydown)="disableDate()"
                                    placeholder="Vencimiento" formControlName="vencimiento"
                                    min="{{date | date:'yyyy-MM-dd'}}">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label is-required" for="nombre">Nombre</label>
                                <input type="text" class="form-control" id="nombre" placeholder="Nombre"
                                    autocomplete="off" formControlName="nombreSw">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="control-label is-required" for="temporalidad">Temporalidad (meses)</label>
                                <input type="number" min="0" class="form-control" id="temporalidad" autocomplete="off"
                                    (keypress)="noSpecialChar($event)" placeholder="Temporalidad (meses)"
                                    formControlName="temporalidad">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="control-label" for="aux">Dato Auxiliar</label>
                                <input type="text" class="form-control" id="aux" placeholder="Dato Aux"
                                    autocomplete="off" formControlName="auxiliar">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label class="control-label is-required" for="cantidad">Cantidad</label>
                                <input type="number" class="form-control" id="cantidad" autocomplete="off"
                                    (keypress)="noSpecialChar($event);" min="1" placeholder="Cantidad"
                                    formControlName="cantidad">
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary" [disabled]="formDetalle.invalid" data-bs-target="#modalNuevaFactura"
                        data-bs-toggle="modal" data-bs-dismiss="modal" (click)="agregarDetalle()">Agregar
                        Detalle</button>
                    <button type="button" class="btn btn-secondary" data-bs-target="#modalNuevaFactura"
                        data-bs-toggle="modal" data-bs-dismiss="modal" (click)="clicCancelarDetSw()">Cancelar</button>
                </form>
            </div>
            <div class="modal-footer">

            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="bajaFacturaSoftware" aria-hidden="true" aria-labelledby="bajaFacturaSoftware" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalToggleLabel2">Dar de Baja Factura de Software</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formDetalle" (submit)="agregarDetalle()" autocomplete="off">
                    <div class="row">

                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="control-label" for="preciou">Precio Unitario</label>
                                <input type="number" min="1" class="form-control" id="preciou"
                                    placeholder="Precio Unitario" formControlName="precioUnitario">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label class="control-label" for="lote">Lote</label>
                                <input type="text" class="form-control" id="lote" placeholder="Lote"
                                    formControlName="lote">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="control-label" for="vencimiento">Vencimiento</label>
                                <input type="date" class="form-control" id="vencimiento" placeholder="Vencimiento"
                                    formControlName="vencimiento" min="{{date | date:'yyyy-MM-dd'}}">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label" for="nombre">Nombre</label>
                                <input type="text" class="form-control" id="nombre" placeholder="Nombre"
                                    formControlName="nombreSw">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="control-label" for="temporalidad">Temporalidad (meses)</label>
                                <input type="number" min="0" class="form-control" id="temporalidad"
                                    placeholder="Temporalidad (meses)" formControlName="temporalidad">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="control-label" for="aux">Dato Auxiliar</label>
                                <input type="text" class="form-control" id="aux" placeholder="Dato Aux"
                                    formControlName="auxiliar">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label class="control-label" for="cantidad">Cantidad</label>
                                <input type="number" class="form-control" id="cantidad" placeholder="Cantidad"
                                    formControlName="cantidad">
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary" [disabled]="formDetalle.invalid" data-bs-target="#modalNuevaFactura"
                        data-bs-toggle="modal" data-bs-dismiss="modal" (click)="agregarDetalle()">Agregar
                        Detalle</button>
                </form>
            </div>
            <div class="modal-footer">

            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalEditaFactura" tabindex="-1" role="dialog" aria-labelledby="modalEditaFactura"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalNuevaCaracteristica">Modificar factura -
                    {{facturaSwEditar.numeroFactura}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div id="divFactura">
                        <form [formGroup]="formEditaFactura" (submit)="editaFactura()" autocomplete="off">
                            <input hidden="true" [(ngModel)]="facturaSwEditar.idFactura" formControlName="idFactura">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label is-required" for="numeroFactura">Folio Fiscal (12
                                            caracteres)</label>
                                        <input type="text" class="form-control" autocomplete="off"
                                            (keypress)="noSpecialChar($event)" disabled id="numeroFactura"
                                            maxlength="12" placeholder="Número de Factura"
                                            formControlName="numeroFactura" [(ngModel)]="facturaSwEditar.numeroFactura">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label is-required" for="proveedor">Proveedor</label>
                                        <input type="text" class="form-control" autocomplete="off" id="proveedor"
                                            placeholder="proveedor" formControlName="proveedor"
                                            [(ngModel)]="facturaSwEditar.proveedor">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label is-required" for="fecha">Fecha</label>
                                        <input type="date" class="form-control" id="fecha" placeholder="fecha"
                                            formControlName="fecha" [(ngModel)]="facturaSwEditar.fecha">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label is-required" for="subtotal">Subtotal</label>
                                        <input type="number" min="1" class="form-control" autocomplete="off"
                                            id="subtotal" placeholder="Subtotal" formControlName="subtotal"
                                            [(ngModel)]="facturaSwEditar.subtotal">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label is-required" for="total">Total</label>
                                        <input type="number" min="1" class="form-control" autocomplete="off" id="total"
                                            placeholder="Total" formControlName="total"
                                            [(ngModel)]="facturaSwEditar.total">
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label class="control-label" for="total">Observaciones</label>
                                        <textarea type="number" min="1" autocomplete="off" class="form-control"
                                            id="observacion" placeholder="observacion" formControlName="observacion"
                                            [(ngModel)]="facturaSwEditar.observacion"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div style="text-align: center;">
                                <button type="submit" class="btn btn-primary" [disabled]="formEditaFactura.invalid"
                                    data-bs-dismiss="modal">Guardar Factura</button>
                            </div>
                            <div class="modal-footer">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalBajaFactura" tabindex="-1" role="dialog" aria-labelledby="modalBajaFactura"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalNuevaCaracteristica">Baja de factura</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div id="divFactura">
                        <form [formGroup]="formBajaFactura" (submit)="bajaFactura(this.idFacturaBaja)"
                            autocomplete="off">
                            <input hidden="true" [(ngModel)]="facturaSwEditar.idFactura" formControlName="idFactura">
                            <div class="form-group">
                                <label class="control-label is-required" for="motivo">Motivo</label>
                                <textarea type="text" min="1" class="form-control" id="motivoBaja"
                                    placeholder="Motivo de la baja" formControlName="motivoBaja"
                                    maxlength="200"></textarea>
                            </div>
                            <div style="text-align: center;">
                                <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Guardar
                                    Factura</button>
                            </div>
                            <div class="modal-footer">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalDatosDetalle" tabindex="-1" role="dialog" aria-labelledby="modalDatosDetalle"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalNuevaCaracteristica">Datos del Detalle de la factura</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col"># Serie</th>
                            <th scope="col"># Inventario Almacen</th>
                            <th scope="col"># TAG</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let det of datosDetalleMuestra">
                            <td>
                                {{det.numeroSerie}}
                            </td>
                            <td>
                                {{det.numeroInvAlm}}
                            </td>
                            <td>
                                {{det.numeroTag}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-target="#modalDetalle" data-bs-toggle="modal"
                    data-bs-dismiss="modal">Regresar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalDocumentos" tabindex="-1" role="dialog" aria-labelledby="modalDocumentos"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalNuevaCaracteristica">Documentos</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <form [formGroup]="formDocumento" autocomplete="off">
                        <div id="subirDoc" style="text-align: center;">
                            <h5>Subir Nuevo Documento</h5>
                        </div>
                        <div id="subirDoc" class="row well">
                            <div class="form-group col-5">
                                <label class="control-label is-required" for="motivo">Documento</label>
                                <input type="file" (change)="nuevoDoc($event)" formControlName="file">
                            </div>
                            <div class="form-group col-5">
                                <label for="state_id" class="control-label is-required">Tipo Documento</label>
                                <select class="form-control" id="tipoDoc" formControlName="tipoDoc">
                                    <option value="" disabled selected>Elige el tipo de documento</option>
                                    <option [value]=doc.idTipoDocumento *ngFor="let doc of tiposDocumentos">
                                        {{doc.nombreDocumento}}</option>
                                </select>
                            </div>
                            <div class="col-2" style="text-align: center;">
                                <button type="submit" class="btn btn-primary" style="margin-top: 28px;"
                                    (click)="subirArchivo()" data-bs-dismiss="modal"
                                    [disabled]="formDocumento.invalid">Subir</button>
                            </div>
                        </div>
                        <hr>
                        <div id="subirDoc" style="text-align: center;">
                            <h5>Documentos Existentes</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-12" style="overflow: auto;">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Tipo Documento</th>
                                            <th scope="col">Archivo</th>
                                            <th scope="col">Descargar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let doc of documentosFactura">
                                            <td>
                                                {{doc.nombreDocumento}}
                                            </td>
                                            <td>
                                                {{doc.rutaFactura}}
                                            </td>
                                            <td style="text-align: center;">
                                                <a href="https://d2kjhhjsuvwfd6.cloudfront.net/{{doc.rutaFactura}}"
                                                    class="btn btn-primary" target="_blank">Descargar<i
                                                        class="feather icon-download iconos-acciones"></i></a>
                                                <!-- <button class="btn btn-primary">Descargar<a href={{doc.rutaAsignacion}} download="blank"><i class="feather icon-download iconos-acciones"></i></a></button> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer">
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>