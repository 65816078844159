import { AfterViewInit, Component, ElementRef, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators                  } from '@angular/forms';
import { HttpClient                                          } from '@angular/common/http';
import   Swal                                                  from 'sweetalert2';

import { environment } from 'src/environments/environment';
import { invSoftware } from 'src/app/model/invSoftware';
import { Usuario     } from 'src/app/model/usuario';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { Roles } from 'src/app/model';

@Component({
  selector:     'app-asignacion-software',
  templateUrl:  './asignacion-software.component.html',
  styleUrls:   ['./asignacion-software.component.scss'],
  providers:   [{ provide: LOCALE_ID, useValue: "es" }],
})
export class AsignacionSoftwareComponent implements AfterViewInit {
    //#region PAGINATION
    public page : number = 1;
    public pageSize: number = 10;
    public totalRecords: number = 0;
    public cargarLoader:boolean = true;
    //#endregion

  private url: string = environment.api;
  private idResponsable = localStorage.getItem('employeeId');
  public formAsignacionSw : FormGroup;
  public formCompartir : FormGroup;
  public formBaja : FormGroup;

  public invSoftware: invSoftware[] = [];
  public invHardware: any[] = [];
  public usuarios: Usuario[] = [];
  public usuariosCompartir: Usuario[] = [];
  public usuariosCompartidos: any[] = [];
  public asignacionesSw: any[] = [];
  public softwareElegido: any  = []
  public hardwareElegido: any  = []
  public hardwareElegidoCompartir: any  = []
  public ubicacion: any[] = [];

  public hayInvSw = false
  public hayHwCompartir = false
  public buscaInv = false
  public buscaInvHw = false
  public buscaUsu = false
  public buscaUsuCompartir = false
  public btnAsignarSw = true
  public btnCompartir = true

  public idAsignacionBaja = 0
  public idAsignacionCompartir = 0
  public indexactual:number = null;

  public rolesAzure = Roles;

  usuarioElegido: any = {
    idUsuario:              null,
    numeroEmpleado:           null,
    nombreEmpleado:         '',
    correo:                 '',
    alias:                  '',
    idUnidadAdministrativa: '',
    idPuesto:               '',
    idMunicipio:            '',
    estatus:                '',
  }

   usuarioElegidoCompartir: any = {
    idUsuario:              null,
    numeroEmpleado:           null,
    nombreEmpleado:         '',
    correo:                 '',
    alias:                  '',
    idUnidadAdministrativa: '',
    idPuesto:               '',
    idMunicipio:            '',
    estatus:                '',
  }
  
  @ViewChild('txtBuscaUsuario', {static: false}) txtBuscaUsuario!:ElementRef<HTMLInputElement>;
  @ViewChild('txtBuscaUsuarioCompartir', {static: false}) txtBuscaUsuarioCompartir!:ElementRef<HTMLInputElement>;
  @ViewChild('txtBuscaInventario', {static: false}) txtBuscaInventario!:ElementRef<HTMLInputElement>;
  @ViewChild('txtBuscaInventarioHw', {static: false}) txtBuscaInventarioHw!:ElementRef<HTMLInputElement>;
  @ViewChild('txtBuscaAsignacion', {static: false}) txtBuscaAsignacion!:ElementRef<HTMLInputElement>;

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService){
    this.formAsignacionSw = this.formBuilder.group({
      usuario: ['',[Validators.required]],
      idUsuario: ['',[Validators.required]],
      idUbicacion: ['',[Validators.required]],
    })
    this.formCompartir = this.formBuilder.group({
      usuarioCompartir: ['',[Validators.required]],
      idUsuarioCompartir: ['',[Validators.required]],
      idUbicacionCompartir: ['',[Validators.required]],
    })
    this.formBaja = this.formBuilder.group({
      idInventarioSw: ['',[Validators.required]],
      motivoBaja: ['',[Validators.required]],
    })
  }

  ngAfterViewInit(): void { this.muestraAsignaciones(); }

  buscarUbicaciones(){
    this.inventarioService.getUbicaciones().subscribe((respUbicaciones:any) =>{
       this.ubicacion = respUbicaciones.data;
    })
  }

  muestraAsignaciones() {
    this.asignacionesSw = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    if (this.txtBuscaAsignacion.nativeElement.value === '') {
      let body = {
        "page": this.page
      }
      this.inventarioService.getAsignacionesSw(body).subscribe((respAsignaciones: any) => {
        if(respAsignaciones.data.length > 0){
          this.asignacionesSw = respAsignaciones.data;
          this.totalRecords = respAsignaciones.totalRecords;
          this.cargarLoader = false;
        }
        else{
          this.funcionErrorPaginacion()
        }
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
        this.cargarLoader = false;
      })
    } else {
      let datoPost = {
        "nombreTabla": "asigsw",
        "valorBusqueda": this.txtBuscaAsignacion.nativeElement.value,
        "Page": this.page
      }
      this.inventarioService.asignacionSwTablaCampo(datoPost).subscribe((respuesta: any) => {
        if (respuesta['result'] === 0) {
          this.funcionErrorPaginacion()
        } else {
          this.asignacionesSw = respuesta.data;
          this.totalRecords = respuesta.totalRecords;
          this.cargarLoader = false;
        }
      })
    }
  }
  
  eligeInventario(idInventarioSw){
      this.inventarioService.getInvSwXId(idInventarioSw).subscribe((respInventarioE:any) =>{
        
        let inve  = {
          "idAsignacion":0,
          "idInventarioSw":respInventarioE.data[0]['idInventarioSw'],
          "nombreSw":respInventarioE.data[0]['nombreSw'],
          "temporalidad":respInventarioE.data[0]['temporalidad'],
          "auxiliar":respInventarioE.data[0]['auxiliar'],
          "software":respInventarioE.data[0]['software'],
        }
        this.softwareElegido.push(inve)
        this.hayInvSw = true
        this.validaAsignacion()
        this.invSoftware = []
      })
  }
  
  eligeHardwareCompartir(idInventarioHw){
    this.hayHwCompartir = true
    this.inventarioService.getInvHwCadena(idInventarioHw).subscribe((respInventarioE:any) =>{
      this.hardwareElegido = respInventarioE.data
    this.validaCompartir()
    })
    
  }

  eligeHardware(idInventarioHw){
    // this.hayHwCompartir = true
    // this.inventarioService.getInvHwCadena(idInventarioHw).subscribe((respInventarioE:any) =>{
      this.softwareElegido[this.indexactual]['idInventarioHw'] = idInventarioHw
    // this.validaCompartir()
    // })
    
  }

  clicAgregarAsignacion(idAsignacionSw){
    this.buscarUbicaciones()
    this.idAsignacionCompartir = idAsignacionSw
    this.inventarioService.getSwCompartido(idAsignacionSw).subscribe((respuesta:any) =>{
      this.usuariosCompartidos = respuesta.data
      // console.log(respuesta)
    })
  }

  eliminarCompartido(idSwCompartido,idAsignacionSw){

    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se dejará de compartir con éste usuario",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Dejar de Compartir'
    }).then((result) => {
      if (result.dismiss) {
       
      }else{
        this.inventarioService.quitarSwCompartido(idSwCompartido).subscribe((respuesta:any) =>{
          if (respuesta['result'] === 1) {
            this.muestraAsignaciones()
            Swal.fire({
              title: "Guardado",
              text: "Se ha dejado de compartir",
              type: "success",
            })
            this.clicAgregarAsignacion(idAsignacionSw)
          }else{
            Swal.fire({
              title: "ERROR",
              text: respuesta['message'],
              type: "warning",
            })
          }    
        })
      }
    })
    
  }

  buscaUsuarioCompartir(){
      let valor = this.txtBuscaUsuarioCompartir.nativeElement.value;
      if (valor === '') {
        this.usuariosCompartir.length = 0
        this.buscaUsuCompartir = true
      } else {
        let datoPostUsuario={
            "nombreTabla": "usr",
            "valorBusqueda": valor,
          }
          this.http.get(`${this.url}Usuarios/nombre/`+valor).subscribe((respUsuario:any) =>{
            // console.log(respUsuario)
            if (respUsuario['result'] === 0) {
              this.usuariosCompartir.length = 0
              this.buscaUsuCompartir = true
             } else {
              this.buscaUsuCompartir = true
              this.usuariosCompartir = respUsuario.data;
             }
        }) 
      }
      // console.log(this.usuarios)
  }

  buscaUsuario(){
      let valor = this.txtBuscaUsuario.nativeElement.value;
      if (valor === '') {
        this.usuarios.length = 0
        this.buscaUsu = true
      } else {
        let datoPostUsuario={
            "nombreTabla": "usr",
            "valorBusqueda": valor,
          }
          this.http.get(`${this.url}Usuarios/nombre/`+valor).subscribe((respUsuario:any) =>{
            if (respUsuario['result'] === 0) {
              this.usuarios.length = 0
              this.buscaUsu = true
             } else {
              this.buscaUsu = true
              this.usuarios = respUsuario.data;
             }
        }) 
      }
  }

  abreBajaAsignacion(idAsignacion){
    this.idAsignacionBaja = idAsignacion
  }

  bajaAsignacion(){
    let asignacionBaja = {
      "motivo": this.formBaja.value['motivoBaja'],
      "idResponsable": this.idResponsable,
      "detalleAsignaciones": [
        {
          "idAsignacion": this.idAsignacionBaja 
        }
      ]
    }
      this.inventarioService.bajaAsignacionSw(asignacionBaja).subscribe(data => {
        if (data['result'] === 1) {
          this.muestraAsignaciones()
          Swal.fire({
            title: "Guardado",
            text: "Se ha registrado exitosamente",
            type: "success",
            timer: 3000
          })
        }else{
          Swal.fire({
            title: "ERROR",
            text: data['message'],
            type: "warning",
            timer: 2000
          })
        }
    }, error => {
  })   
  } 

  eligeUsuario(idUsuario){
    // console.log(idUsuario)
    this.http.get(`${this.url}Usuarios/`+idUsuario).subscribe((respUsuario:any) =>{
      this.usuarioElegido = respUsuario.data;
      for (let index = 0; index < this.softwareElegido.length; index++) {
        this.softwareElegido[index].idInventarioHw = null
      }
      
      this.validaAsignacion()
   })
  //  console.log(this.usuarioElegido)
  }

  eligeUsuarioCompartir(idUsuario){
    this.http.get(`${this.url}Usuarios/`+idUsuario).subscribe((respUsuario:any) =>{
      this.usuarioElegidoCompartir = respUsuario.data;
      for (let index = 0; index < this.softwareElegido.length; index++) {
        this.softwareElegido[index].idInventarioHw = null
      }
      
      this.validaCompartir()
   })
  }

  comparteSw(){
    // console.log(this.usuarioElegidoCompartir)
    let datosCompartir={
      "idAsignacionSw": this.idAsignacionCompartir,
      "idUsuario":this.usuarioElegidoCompartir['numeroEmpleado'] ,
      // "idUsuario":this.usuarioElegidoCompartir['idUsuario'] ,
      "idInventarioHw": this.hardwareElegido[0]['idInventarioHW'] ,
      "idUbicacion": this.formCompartir.value['idUbicacionCompartir'],
    }
    // console.log(datosCompartir)
    this.inventarioService.compartirSw(datosCompartir).subscribe(data => {
      if (data['result'] === 1) {
        this.idAsignacionCompartir = 0
        this.usuarioElegidoCompartir = []
        this.hardwareElegido = []
        this.formCompartir.reset()
        this.hayHwCompartir = false
        Swal.fire({
          title: "Guardado",
          text: "Se ha registrado exitosamente",
          type: "success",
        })
      }else{
        Swal.fire({
          title: "ERROR",
          text: data['message'],
          type: "warning",
        }).then(()=>{
            let element: HTMLElement = document.getElementsByClassName('btnNuevaAsignacion')[0] as HTMLElement;
            element.click()
          })
      }
    })
  }

  buscaInventario(){
    let valor = this.txtBuscaInventario.nativeElement.value;
    if (valor === '') {
      this.invSoftware.length = 0
      this.buscaInv = true
    } else {
      let datoPostInventario={
          "nombreTabla": "invswstock",
          "valorBusqueda": valor,
        }
      this.inventarioService.getInvSwTablaCampo(datoPostInventario).subscribe((respInventario:any) =>{
        if (respInventario['result'] === 0) {
          this.invSoftware.length = 0
          this.buscaInv = true
         } else {
          this.buscaInv = true
          this.invSoftware = respInventario.data;
         }
      }) 
    }
  }

  buscaHardware(){
    let valor = this.txtBuscaInventarioHw.nativeElement.value;
    if (valor === '') {
      this.buscaInvHw = true
    } else {
      let datoPostInventario={
          "nombreTabla": "invhw",
          "valorBusqueda": valor,
        }
      this.inventarioService.getInvHwTablaCampo(datoPostInventario).subscribe((respInventario:any) =>{
        if (respInventario['result'] === 0) {
          this.invHardware.length = 0
          this.buscaInvHw = true
         } else {
          this.buscaInvHw = true
          this.invHardware = respInventario.data;
         }
      }) 
    }
  }

  guardaAsignacionSw(){
    let datoPost = {
      "idUsuario":this.formAsignacionSw.value['idUsuario'],
      "idUbicacion":this.formAsignacionSw.value['idUbicacion'],
      "idResponsable":this.idResponsable,
      "detalleAsignaciones":this.softwareElegido,
    }
      this.inventarioService.createAsignacionSw(datoPost).subscribe(data => {
        if (data['result'] === 1) {
          this.softwareElegido = []
          this.usuarioElegido = []
          this.invSoftware = []
          this.usuarios = []
          this.page = 1
          this.formAsignacionSw.reset();
          this.muestraAsignaciones();
          Swal.fire({
            title: "Guardado",
            text: "Se ha registrado exitosamente",
            type: "success",
          })
        }else{
          Swal.fire({
            title: "ERROR",
            text: data['message'],
            type: "warning",
          }).then(()=>{
              let element: HTMLElement = document.getElementsByClassName('btnNuevaAsignacion')[0] as HTMLElement;
              element.click()
            })
        }
    })   
  }
  
  abreAgregarHardware(index){
    this.indexactual = index
    let datoPost={
      "fini": "",
      "ffin": "",
      "tipoSw": 0,
      "tipoHw": 0,
      "idUsuario":this.usuarioElegido['numeroEmpleado'] ,
      "idUbicacion": 0,
      "idInventarioHw": 0,
      "idInventarioSw": 0,
      "limit": 0
    }
    this.inventarioService.getAsignacionesHw(datoPost).subscribe((respuesta:any) =>{
      if (respuesta['result'] === 0) {
        this.invHardware.length = 0
        this.buscaInvHw = true
       } else {
        this.buscaInvHw = true
        this.invHardware = respuesta.data;
       }
    })
  }

  hardwareCompartir(){
    let datoPost={
      "fini": "",
      "ffin": "",
      "tipoSw": 0,
      "tipoHw": 0,
      "idUsuario":this.usuarioElegidoCompartir['numeroEmpleado'] ,
      "idUbicacion": 0,
      "idInventarioHw": 0,
      "idInventarioSw": 0,
      "limit": 0
    }
    this.inventarioService.getAsignacionesHw(datoPost).subscribe((respuesta:any) =>{
      if (respuesta['result'] === 0) {
        this.invHardware.length = 0
        this.buscaInvHw = true
       } else {
        this.buscaInvHw = true
        this.invHardware = respuesta.data;
       }
    })
  }

  eliminarSoft(i){
    this.softwareElegido.splice(i,1)
    this.validaAsignacion()
  }
  
  eliminarHardware(){
    this.hardwareElegido = []
  }
  
  buscaAsignacionInput(){
    if (this.txtBuscaAsignacion.nativeElement.value !== '') { this.page = 1; this.muestraAsignaciones(); }
  }  
  
  cambioAsignacionInput(){
    if (this.txtBuscaAsignacion.nativeElement.value === '') { this.page = 1; this.muestraAsignaciones(); }
  }

  validaAsignacion(){
    if (this.softwareElegido.length > 0 && this.usuarioElegido != '' && this.formAsignacionSw.valid) {
      this.btnAsignarSw = false
    }else{
      this.btnAsignarSw = true
    }
  }

  validaCompartir(){
    if (this.usuarioElegidoCompartir != '' && this.formCompartir.valid && this.hardwareElegido.length > 0) {
      this.btnCompartir = false
    }else{
      this.btnCompartir = true
    }
  }

  noSpecialChar(char){
  const onlyspecial = /[°¬_"&@!=¡¿¨´~`',;:.*+\-?^$/{}<>()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
        return false;
    }
    return true
  }

  loadPage(page: number) {
    this.page = page;
    this.muestraAsignaciones();
  }
  
  funcionErrorPaginacion(){
    if (!Swal.isVisible()) {
      Swal.fire({
        title: "ERROR",
        text: "No hay coincidencias con tu criterio de búsqueda",
        type: "warning"
      }).then(() => {
        if (this.page != 1) {
          this.page = 1;
          this.muestraAsignaciones();
        } else {
          this.txtBuscaAsignacion.nativeElement.value = "";
          this.page = 1;
          this.muestraAsignaciones();
        }
      })
    }
  }
}
