import { Component, ElementRef, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import { environment } from 'src/environments/environment';
import { invHardware } from 'src/app/model/invHArdware';
import { PdfService } from 'src/app/data/service/pdf-excel/pdf.service';
import { Usuario } from 'src/app/model/usuario';
import { UploadService } from 'src/app/data/service/upload.service';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { Asignacion } from '../../../model/asignacion';
import { downloadPDF } from '../../shared/functions/downloadPDF';
import { Roles } from 'src/app/model';

@Component({
  selector: 'app-historial-asignacion-hw',
  templateUrl: './historial-asignacion-hw.component.html',
  styleUrls: ['./historial-asignacion-hw.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: "es" }],
})
export class HistorialAsignacionHwComponent implements OnInit {
  private url: string = environment.api;

  public formDocumento: FormGroup;
  public formFechas: FormGroup;

  public invHardware: invHardware[] = [];
  public usuarios: Usuario[] = [];
  public hardwareElegido: any = [];
  public asignacion: Asignacion[] = [];
  public documentosAsignacion: any[] = []
  public tiposDocumentos: any = [];
  public usuarioElegido: any = []

  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;

  public buscaInv = false
  public buscaUsu = false
  public date: Date = new Date();
  public idAsignacionDoc: 0;
  toFile;

  public rolesAzure = Roles;
  
  @ViewChild('txtBuscaInventario', { static: false }) txtBuscaInventario!: ElementRef<HTMLInputElement>;
  @ViewChild('txtBuscaUsuario', { static: false }) txtBuscaUsuario!: ElementRef<HTMLInputElement>;

  constructor(private _pdfService: PdfService, private http: HttpClient, private formBuilder: FormBuilder, private uploadService: UploadService, private inventarioService: InventarioService) {
    this.formFechas = this.formBuilder.group({
      fini: ['', [Validators.required]],
      ffin: ['', [Validators.required]],
      usuario: ['', [Validators.required]],
      idUsuario: ['', [Validators.required]],
      idInventarioHw: ['', [Validators.required]],
      hardware: ['', [Validators.required]],
    });
    this.formDocumento = this.formBuilder.group({
      tipoDoc: ['', [Validators.required]],
      file: ['', [Validators.required]],
    })
  }
  ngOnInit() {

  }

  buscaInventario() {
    let valor = this.txtBuscaInventario.nativeElement.value;
    if (valor === '') {
      this.invHardware.length = 0
      this.buscaInv = true
    } else {
      let datoPostInventario = {
        "nombreTabla": "invhw",
        "valorBusqueda": valor,
      }
      this.inventarioService.getInvHwTablaCampo(datoPostInventario).subscribe((respInventario: any) => {
        if (respInventario['result'] === 0) {
          this.invHardware.length = 0
          this.buscaInv = true
        } else {
          this.buscaInv = true
          this.invHardware = respInventario.data;
        }
      })
    }

  }

  mostrarReporte() {
    this.asignacion = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    if ((this.formFechas.value['fini'] != '' && this.formFechas.value['ffin'] == '') || (this.formFechas.value['fini'] == '' && this.formFechas.value['ffin'] != '')) {
      Swal.fire({
        title: "Advertencia",
        text: 'No puede llevar valor solo una fecha',
        type: "warning",
        // timer: 2000
      })
    } else {
      let ubi
      let usu
      if (this.formFechas.value['ubicacion'] == '') ubi = 0
      else ubi = this.formFechas.value['ubicacion']
      if (this.formFechas.value['idUsuario'] == '' || this.formFechas.value['idUsuario'] == null) usu = 0
      else usu = this.formFechas.value['idUsuario']

      let reporte = {
        "fini": this.formFechas.value['fini'],
        "ffin": this.formFechas.value['ffin'],
        "idUsuario": usu,
        "idUbicacion": ubi,
        "idInventarioHw": this.formFechas.value['idInventarioHw'],
        "limit": 0,
        "Page": this.page
      }

      this.inventarioService.getHistorialAsignHw(reporte).subscribe((respuesta: any) => {
        if (respuesta.data != null && respuesta.data.length > 0) {
          this.asignacion = respuesta.data;
          this.totalRecords = respuesta.totalRecords;
          this.cargarLoader = false;
        }
        else this.funcionErrorPaginacion();
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
  }

  buscaUsuario() {
    let valor = this.txtBuscaUsuario.nativeElement.value;
    if (valor === '') {
      this.usuarios.length = 0
      this.buscaUsu = true
    } else {
      let datoPostUsuario = {
        "nombreTabla": "usr",
        "valorBusqueda": valor,
      }
      this.http.get(`${this.url}Usuarios/nombre/` + valor).subscribe((respUsuario: any) => {
        if (respUsuario['result'] === 0) {
          this.usuarios.length = 0
          this.buscaUsu = true
        } else {
          this.buscaUsu = true
          this.usuarios = respUsuario.data;
        }
      })
    }
  }

  eligeInventario(idInventarioHw) {
    this.hardwareElegido.length = 0
    this.inventarioService.getInvHwCadena(idInventarioHw).subscribe((respInventarioE: any) => {
      this.hardwareElegido = respInventarioE.data[0]
    })

  }

  eligeUsuario(idUsuario) {
    this.http.get(`${this.url}Usuarios/` + idUsuario).subscribe((respUsuario: any) => {
      this.usuarioElegido = respUsuario.data;

    })
  }

  quitarUsuario() {
    this.usuarioElegido = []
  }

  quitarInventario() {
    this.hardwareElegido = []
  }

  exportExcel() {
    let ubi
    let usu
    if (this.formFechas.value['ubicacion'] == '') ubi = 0
    else ubi = this.formFechas.value['ubicacion']
    if (this.formFechas.value['idUsuario'] == '' || this.formFechas.value['idUsuario'] == null) usu = 0
    else usu = this.formFechas.value['idUsuario']

    let reporte = {
      "fini": this.formFechas.value['fini'],
      "ffin": this.formFechas.value['ffin'],
      "idUsuario": usu,
      "idUbicacion": ubi,
      "idInventarioHw": this.formFechas.value['idInventarioHw'],
      "limit": 0,
      "Page": -1
    }

    this.inventarioService.getHistorialAsignHw(reporte).subscribe((respuesta: any) => {
      if (respuesta['result'] == 1) {
        /* DESDE AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.asignacion);
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(respuesta.data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, "HistorialAsignacionHardware-" + this.date.toLocaleDateString() + ".xlsx");
        /* HASTA AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
      } else {
        Swal.fire({
          title: "Advertencia",
          text: respuesta['message'],
          type: "warning",
          // timer: 2000
        })
      }
    }, error => {
      Swal.fire({
        title: "ERROR",
        text: `Error ${error}`,
        type: "warning"
      })
    })
  }

  async generatePDF() {
    let ubi
    let usu
    if (this.formFechas.value['ubicacion'] == '') ubi = 0
    else ubi = this.formFechas.value['ubicacion']
    if (this.formFechas.value['idUsuario'] == '' || this.formFechas.value['idUsuario'] == null) usu = 0
    else usu = this.formFechas.value['idUsuario']

    let reporte = {
      "fini": this.formFechas.value['fini'],
      "ffin": this.formFechas.value['ffin'],
      "idUsuario": usu,
      "idUbicacion": ubi,
      "idInventarioHw": this.formFechas.value['idInventarioHw'],
      "limit": 0,
      "Page": -1
    }

    this.inventarioService.getHistorialAsignHw(reporte).subscribe((respuesta: any) => {
      if (respuesta['result'] == 1) {
        const tituloEncabezado = 'Reporte de Historial De Asignacion de Hardware';
        const FileName = 'HistorialAsignacionHardware' + this.date.toLocaleDateString();
        const embedHTML = document.getElementById('foo');
        const widths = 'auto';
        this._pdfService.generatePDF(respuesta.data, embedHTML, FileName, widths, tituloEncabezado);
      } else {
        Swal.fire({
          title: "Advertencia",
          text: respuesta['message'],
          type: "warning",
          // timer: 2000
        })
      }
    }, error => {
      Swal.fire({
        title: "ERROR",
        text: `Error ${error}`,
        type: "warning"
      })
    })
  }

  async subirArchivo() {
    const file = this.toFile.item(0);
    this.uploadService.fileUpload(file);
    let idAsignacion = this.idAsignacionDoc
    let datoPost = {
      "tipoDocumento": "A",
      "idFHA": idAsignacion,
      "listaDocumentos": [{
        "idTipoDocumento": this.formDocumento.value['tipoDoc'],
        "ruta": 'https://inventario-rfid.s3.amazonaws.com/' + file.name
      }]
    }
    this.inventarioService.saveDocumento(datoPost).subscribe((respuesta: any) => {
      if (respuesta['result'] === 1) {
        this.formDocumento.reset()
        Swal.fire({
          title: "Guardado",
          text: "Se ha registrado exitosamente",
          type: "success",
        })
      } else {
        Swal.fire({
          title: "ERROR",
          text: respuesta['message'],
          type: "warning",
        })
      }
    })
  }

  nuevoDoc(event) {
    this.toFile = event.target.files;
  }

  abreModalDocumentos(idAsignacion) {
    this.muestraTipoDocs()
    this.idAsignacionDoc = idAsignacion
    // console.log(idAsignacion)
    // this.http.get(`${this.url}Documentos/asignacion/list?folioAsignacion=328`).subscribe((respuesta:any) =>{
    this.inventarioService.getListDocumentosAsignacion(idAsignacion).subscribe((respuesta: any) => {
      this.documentosAsignacion = respuesta.data;
    })
  }

  muestraTipoDocs() {
    this.inventarioService.getTipoDocumentos('f').subscribe((respuesta: any) => {
      this.tiposDocumentos = respuesta.data;
    })
  }

  loadPage(page: number) {
    this.page = page;
    this.mostrarReporte();
  }

  funcionErrorPaginacion() {
    if (!Swal.isVisible()) {
      Swal.fire({
        title: "ERROR",
        text: "No hay coincidencias con tu criterio de búsqueda",
        type: "warning"
      }).then(() => {
        if (this.page != 1) {
          this.page = 1;
          this.mostrarReporte();
        } else {
          this.page = 1;
          this.formFechas.get("fini").setValue("");
          this.formFechas.get("ffin").setValue("");
          this.formFechas.get("usuario").setValue("");
          this.formFechas.get("idUsuario").setValue("");
          this.formFechas.get("idInventarioHw").setValue(undefined);
          this.formFechas.get("hardware").setValue("");
          this.mostrarReporte();
        }
      })
    }
  }
}
