import { FormBuilder, FormGroup, Validators                                 } from '@angular/forms';
import { formatCurrency, formatDate, getCurrencySymbol, registerLocaleData  } from '@angular/common';
import { AfterViewInit, Component, ElementRef, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { HttpClient                                                         } from '@angular/common/http';
import   localeEsMX                                                           from '@angular/common/locales/es-MX';
import   Swal                                                                 from 'sweetalert2';
import { datosDetalle, datosInvHw, facturaSoftware                          } from 'src/app/model/facturaSoftware';
import { DetalleFactura                                                     } from '../../../model/facturaSoftware';
import { UploadService                                                      } from 'src/app/data/service/upload.service';
import { Caracteristicas                                                    } from 'src/app/model/caracteristicas';
import { InventarioService                                                  } from 'src/app/data/service/inventario.service';
import { Roles                                                              } from 'src/app/model';
import   * as CryptoJS                                                        from 'crypto-js';

registerLocaleData(localeEsMX, 'es-MX');

@Component({
  selector: 'app-facturas-software',
  templateUrl: './facturas-software.component.html',
  styleUrls: ['./facturas-software.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: "es-MX" }],
})
export class FacturasSoftwareComponent implements OnInit, AfterViewInit {

  public rolesAzure = Roles;

  private encryptionKey = 'ak-ir.24-';

  @ViewChild('txtBuscaFacSoft', {static: false}) txtBuscaFacSoft!:ElementRef<HTMLInputElement>;
  @ViewChild('cantidadHw', {static: false}) txtCantHw!:ElementRef<HTMLInputElement>;
  @ViewChild('loteHw', {static: false}) loteHw!:ElementRef<HTMLInputElement>;
  @ViewChild('idHardware', {static: false}) idHardware!:ElementRef<HTMLInputElement>;
  @ViewChild('fecha', {static: false}) txtFecha!:ElementRef<HTMLInputElement>;
  @ViewChild('numeroFactura', {static: false}) txtNumeroFactura!:ElementRef<HTMLInputElement>;
  @ViewChild('total', {static: false}) totalEnc!:ElementRef<HTMLInputElement>;
  @ViewChild('subtotal', {static: false}) subtotalEnc!:ElementRef<HTMLInputElement>;
  @ViewChild('btn_agregarDatosDetalle', {static: false}) btn_agregarDatosDetalle!:ElementRef<HTMLInputElement>;
  @ViewChild('btnCerrarModalDatosDetalle', {static: false}) btnCerrarModalDatosDetalle!:ElementRef<HTMLInputElement>;

  private idResponsable = localStorage.getItem('employeeId');

  public formFactura: FormGroup;
  public formEditaFactura: FormGroup;
  public formBajaFactura: FormGroup;
  public formDetalle: FormGroup;
  public formDetalleHw: FormGroup;
  public formDatosDetalle: FormGroup;
  public formDocumento: FormGroup;
  
  public detalleArrayGuardar: DetalleFactura[] = [];
  public detalleArrayGuardarHw: DetalleFactura[] = [];
  public datosArrayGuardar: datosInvHw[] = [];
  public datosDetalle:  datosDetalle[] = [];
  public datosDetalleMuestra: datosDetalle [] = [];
  public facturasSoftware: facturaSoftware[] = [];
  public facturasSoftwareGuardar: facturaSoftware[] = [];
  public caracteristicas: Caracteristicas[] = [];
  public detalleArraySw: any = [];
  public tipohw: any = [];
  public detalleArrayHw: any = [];
  public diti: any = [];
  public diticars: any = [];
  public datosdetalle: any  = [];
  public tiposDocumentos: any  = [];
  public documentosFactura: any[] = []
  public detalle: any = [];
  public detalles: any = [];
  public catSoftware: any[] = [];
  public catHardware : any []
  public situacion : any []
  public datosInvHw : any []
  public carsHw: any = []

  toFile;
  
  public facturaGuardada = false;
  public btnGuardaFactura = true;
  public btnAgregaDetHw = false;
  public btnAgregaDetSw = false;
  public btnAgregaDatosDetalle = false;
  public validaAgregarDetalle = true;
  public tblAgregaDatosDetalle = false;
  public hayInvSw = false
  public modalAgregarDetalleAbierto = false
  public hayInvHw = false
  public inputLote = false
  public bandNoSerieRepetido = false
  public bandNoInvRepetido = false
  public bandAgregaDatosDetalle = true
  public bandFolioFiscal = false

  public idFacturaBaja = 0;
  public idFacturaDoc = 0;
  public posicionDetHw = 0;
  public consecutivoLote = 0;
  public consecutivoLoteSw = 0;
  public folioFiscal = 0;
  
  public page : number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader:boolean = true;

  public totalFinal = 0
  public subTotalFinal = 0
  public docGuardado = 0

  date = new Date().toISOString().slice(0, 10);
  public cantidad
  public subTotalFormat = '' 
  public ruta = '' 
  public totalFormat = '' 

  facturaSwEditar: any = {
    idFactura: null,
    numeroFactura: '',
    proveedor: '',
    subtotal: null,
    total: null,
    fecha: null,
    observacion: null,
  }

  facturaSwBaja: any = {
    idFactura: null,
    motivoBaja: "",
  }

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private eRef: ElementRef,private uploadService: UploadService, private inventarioService: InventarioService) {
    this.formFactura = this.formBuilder.group({
      numeroFactura: ['',[Validators.required]],
      proveedor: ['',[Validators.required]],
      subtotal: ['',[Validators.required]],
      total: ['',[Validators.required]],
      fecha: ['',[Validators.required]],
      observacion: ['',[]],
    });

    this.formDetalle = this.formBuilder.group({
      cantidad: ['',[Validators.required,Validators.min(0)]],
      precioUnitario: ['',[Validators.required]],
      lote: ['',[]],
      vencimiento: ['',[Validators.required]],
      temporalidad: ['',[Validators.required]],
      idSoftware: ['',[Validators.required]],
      nombreSw: ['',[Validators.required]],
      auxiliar: ['',[]],
    })

    this.formEditaFactura = this.formBuilder.group({
      idFactura: ['',[Validators.required]],
      numeroFactura: ['',[Validators.required]],
      proveedor: ['',[Validators.required]],
      subtotal: ['',[Validators.required]],
      total: ['',[Validators.required]],
      fecha: ['',[Validators.required]],
      observacion: ['',[]],
    })

    this.formBajaFactura = this.formBuilder.group({
      idFactura: ['',[Validators.required]],
      motivoBaja: ['',[Validators.required]],
    })

    this.formDocumento = this.formBuilder.group({
      tipoDoc: ['',[Validators.required]],
      file: ['',[Validators.required]],
    })

    this.formDetalleHw = this.formBuilder.group({
      cantidad: ['',[Validators.required,Validators.min(0)]],
      precioUnitario: ['',[Validators.required]],
      lote: ['',[Validators.required]],
      idHardware: ['',[Validators.required]],
      idTipoHw: ['',[Validators.required]],
      cars: ['',[Validators.required]],
    })

    this.formDatosDetalle = this.formBuilder.group({
      numeroSerie: ['',[Validators.required]],
      numeroInvAlm: ['',[Validators.required]],
    })

   
  }

  focusSubtotal(){
    this.subTotalFormat = this.subTotalFinal.toString()
  }

  focusTotal(){
    this.totalFormat = this.totalFinal.toString()
  }

  formatearSubtotal(value: string) {
    this.subTotalFinal = parseFloat(value)
    let val = parseFloat(value);
    if (Number.isNaN(val)) {
      val = 0;
    }
    this.subTotalFormat = formatCurrency(val, 'en-US', getCurrencySymbol('USD', 'wide'));
  }

  formatearTotal(value: string) {
    this.totalFinal = parseFloat(value)
    let val = parseFloat(value);
    if (Number.isNaN(val)) {
      val = 0;
    }
    this.totalFormat = formatCurrency(val, 'en-US', getCurrencySymbol('USD', 'wide'));
  }

   muestraFacturas(){
    this.facturasSoftware = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    if (this.txtBuscaFacSoft.nativeElement.value === ''){
      this.inventarioService.getFacturas(this.page).subscribe((respFacturas:any) =>{
        if(respFacturas.data != null && respFacturas.data.length > 0){
          this.facturasSoftware = respFacturas.data;
          this.totalRecords = respFacturas.totalRecords;
          this.cargarLoader = false;
        }
        else this.funcionErrorPaginacion();
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
    else{
      let datoPostFacSoft = {
        "nombreTabla": "fsw",
        "valorBusqueda": this.txtBuscaFacSoft.nativeElement.value,
        "Page": this.page
      }
      this.inventarioService.getFacturaTablaCampo(datoPostFacSoft).subscribe((respInvSoft: any) => {
        if (respInvSoft['result'] === 0) this.funcionErrorPaginacion();
        else {
          this.facturasSoftware = respInvSoft.data;
          this.totalRecords = respInvSoft.totalRecords;
          this.cargarLoader = false;
        }
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
  }

  muestraCtlSoftware(){
    this.inventarioService.getSoftware().subscribe((respSoftware:any) =>{
       this.catSoftware = respSoftware.data;
    })
  }

  muestraTipoDocs(){
    this.inventarioService.getTipoDocumentos('f').subscribe((respuesta:any) =>{
       this.tiposDocumentos = respuesta.data;
    })
  }

   muestraDetalleFacturas(factura){
     factura = factura.toString();
    this.inventarioService.getDetalleSwFacturas(factura).subscribe((detFacturasSw:any) =>{
       this.detalleArraySw = detFacturasSw.data;
    })
    this.inventarioService.getDetalleHwFacturas(factura).subscribe((detFacturasHw:any) =>{
       this.detalleArrayHw = detFacturasHw.data;
    })
  }

  muestraDatosDetalle(lote,idFactura){
    let lotefac={
      "idFactura": idFactura,
      "lote": lote
    }
    
    this.inventarioService.getDatosDetalleHwFacturas(lotefac).subscribe((respDatosDetalleMuestra:any) =>{
      this.datosDetalleMuestra = respDatosDetalleMuestra.data;
   })
  }

  ngOnInit() {
    this.muestraCtlSoftware()
    this.muestraSituaciones()
    this.muestraTipoHw()
  }

  ngAfterViewInit(): void { this.muestraFacturas() }

  cambioTipo(idTipo){
    this.validaAgregarDetalle = true
    this.formDetalleHw.controls['idHardware'].setValue('')
    this.muestraCtlHardware(idTipo)
    this.validarAgregarDetalle()
    this.buscarCaracteristicas(idTipo)
  }

  buscarCaracteristicas(idTipo){
    this.inventarioService.getCaracteristicasXTipoHw(idTipo).subscribe((respCars:any) =>{
       this.caracteristicas = respCars.data;
    })
  }

  muestraCtlHardware(idTipo){
    this.inventarioService.getHardwareXTipoHw(idTipo).subscribe((respHardware:any) =>{
       this.catHardware = respHardware.data;
    })
  }

  muestraTipoHw(){
    this.inventarioService.getTipoHardware().subscribe((respTipo:any) =>{
       this.tipohw = respTipo.data;
    })
  }

  muestraSituaciones(){
    this.inventarioService.getSituaciones().subscribe((situaciones:any) =>{
      this.situacion = situaciones.data; 
   })
  }  

  clicAgregarDatosDetHw(){
    this.formDetalleHw.controls['lote'].disable();
  }

  clicSpanAgregarDetalle(){
    Swal.fire({
      title: "Advertencia",
      text: "Debe Agregar Fecha y Folio Fiscal Válido (último bloque de 12 caracteres)",
      type: "warning",
    }).then(()=>{
      let element: HTMLElement = document.getElementsByClassName('btnNuevaFactura')[0] as HTMLElement;
      element.click()
    })
  }

  validaFechaNoFactura(){
    let fecha = this.txtFecha.nativeElement.value;
    // let noFactura = this.txtNumeroFactura.nativeElement.value;
    if (fecha != '' && this.bandFolioFiscal == true) {
      this.btnAgregaDetSw = true
      this.btnAgregaDetHw = true
    }else{
      this.btnAgregaDetSw = false
      this.btnAgregaDetHw = false
    }
  }

  clicAgregarFactura(){
  }

  clicCancelarDetHw(){
  this.consecutivoLote = this.consecutivoLote-1
  this.formDetalleHw.reset()
  this.datosdetalle.length=0
  }

  clicCancelarDetSw(){
  this.consecutivoLoteSw = this.consecutivoLoteSw-1
  this.formDetalle.reset()
  }

  eliminarDetalle(detalle,lote){
    this.detalleArrayGuardar.splice(detalle,1)
    for (let index = 0; index < this.detalleArrayGuardar.length; index++) {
      let cons = this.detalleArrayGuardar[index].lote.split("")[this.detalleArrayGuardar[index].lote.length - 2]
      let consborrar = lote.split("")[lote.length - 2]
      let indexMasUno = index +1
      let nvolote
      if (indexMasUno < 10) {
        nvolote = this.detalleArrayGuardar[index].lote.substring(0, this.detalleArrayGuardar[index].lote.length - 2)+'0'+indexMasUno;
      }else{
        nvolote = this.detalleArrayGuardar[index].lote.substring(0, this.detalleArrayGuardar[index].lote.length - 2)+indexMasUno;
      }
      
      this.detalleArrayGuardar[index].lote = nvolote
    }
    this.consecutivoLoteSw = this.detalleArrayGuardar.length
    this.validarForms()
  }

  eliminarDetalleHw(detalle,lote){
    this.detalleArrayGuardarHw.splice(detalle,1)
    for (let index = 0; index < this.datosDetalle.length; index++) {
      var size2 = Object.keys(this.datosDetalle[index]).length;
      if (size2 > 0) {
        if (lote != this.datosDetalle[index][0].lote) {
          this.diti.push(this.datosDetalle[index])
        }
      }
     
    }

    for (let indexcars = 0; indexcars < this.carsHw.length; indexcars++) {
     if (lote != this.carsHw[indexcars].lote) {
      this.diticars.push(this.carsHw[indexcars])
      }
      
    }
    this.carsHw = this.diticars.slice()
    this.datosDetalle = this.diti.slice()
    for (let index = 0; index < this.detalleArrayGuardarHw.length; index++) {
        let cons = this.detalleArrayGuardarHw[index].lote.split("")[this.detalleArrayGuardarHw[index].lote.length - 2]
        let consborrar = lote.split("")[lote.length - 1]
        let indexMasUno = index +1
        let nvolote
        if (indexMasUno < 10) {
          nvolote = this.detalleArrayGuardarHw[index].lote.substring(0, this.detalleArrayGuardarHw[index].lote.length - 2)+'0'+indexMasUno;
        }else{
          nvolote = this.detalleArrayGuardarHw[index].lote.substring(0, this.detalleArrayGuardarHw[index].lote.length - 2)+indexMasUno;
        }
        
        this.carsHw[index].lote = nvolote
        for (let index2 = 0; index2 < this.datosDetalle.length; index2++) {
            var size = Object.keys(this.datosDetalle[index2]).length;
          for (let index3 = 0; index3 < size; index3++) {
            if (this.detalleArrayGuardarHw[index].lote == this.datosDetalle[index2][index3].lote) {
              this.datosDetalle[index2][index3].lote = nvolote
            }
          }
        }
        this.detalleArrayGuardarHw[index].lote = nvolote
    }
    this.consecutivoLote = this.detalleArrayGuardarHw.length
    this.diti.length = 0
    this.diticars.length = 0
    this.validarForms()
  }

  guardaFactura(){
    this.facturaGuardada = true
     this.detalles = this.detalleArrayGuardar.concat(this.detalleArrayGuardarHw);
    this.facturasSoftwareGuardar = this.formFactura.value
    let datoPostFactura={
      "proveedor":this.formFactura.value['proveedor'],
      "subtotal":this.subTotalFinal,
      "total":this.totalFinal,
      "numeroFactura":this.folioFiscal,
      "observacion":this.formFactura.value['observacion'],
      "fecha":this.formFactura.value['fecha'],
      "idResponsable":this.idResponsable,
      "DetalleFacturas": this.detalles,
      "DatosDetalles": this.datosDetalle,
      "caracteristicas": this.carsHw,

    }
      this.inventarioService.createFactura(datoPostFactura).subscribe(data => {
        if (data['result'] === 1) {
          this.formFactura.controls['numeroFactura'].enable()
          this.formFactura.reset();
          this.muestraFacturas()
          this.hayInvHw = false
          this.hayInvSw = false
          this.detalleArrayGuardar.length = 0
          this.detalleArrayGuardarHw.length = 0
          this.detalles.length = 0
          this.datosDetalle.length = 0
          this.btnAgregaDetHw = false
          this.btnAgregaDetSw = false
          this.btnGuardaFactura = true
          this.consecutivoLote = 0
          this.consecutivoLoteSw = 0
          this.folioFiscal = 0
          this.bandFolioFiscal = false;
          Swal.fire({
            title: "Guardado",
            text: "Se ha registrado exitosamente",
            type: "success",
            // timer: 3000
          })
        }else{
          Swal.fire({
            title: "ERROR",
            text: data['message'],
            type: "warning",
            // timer: 2000
          })
        }
        
    })   
  }
  
  cambioBuscaFacSoftInput(){
    let valor = this.txtBuscaFacSoft.nativeElement.value;
    if (valor === '') {
      this.muestraFacturas();
    }
  }

  buscaFacSoftInput(){
    let valor = this.txtBuscaFacSoft.nativeElement.value;
    if (valor === '') {
      this.muestraFacturas();
    } else {
      //
      let datoPostFacSoft={
          "nombreTabla": "fsw",
          "valorBusqueda": valor,
          "Page": this.page
        }
      this.inventarioService.getFacturaTablaCampo(datoPostFacSoft).subscribe((respInvSoft:any) =>{
        if (respInvSoft['result'] === 0) this.funcionErrorPaginacion();
        else {
          this.facturasSoftware = respInvSoft.data;
          this.totalRecords = respInvSoft.totalRecords;
          this.cargarLoader = false;
        }
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
   
  };

  abreEditaFactura(idFactura){
    this.inventarioService.getFacturasSw(idFactura).subscribe((resped:any) =>{
       this.facturaSwEditar=resped.data[0]
       this.facturaSwEditar.fecha = formatDate(this.facturaSwEditar.fecha,'yyy-MM-dd','en-US')
    })
  }

  editaFactura(){
      this.inventarioService.editaFactura(this.formEditaFactura.value).subscribe(data => {
        if (data['result'] === 1) {
          this.formEditaFactura.reset();
          this.muestraFacturas()
          Swal.fire({
            title: "Guardado",
            text: "Se ha registrado exitosamente",
            type: "success",
            // timer: 3000
          })
        }else{
          Swal.fire({
            title: "ERROR",
            text: data['message'],
            type: "warning",
            // timer: 2000
          })
        }
    })   
  }

  abreBajaFactura(idFactura){
    this.idFacturaBaja = idFactura
  }  

  bajaFactura(idFactura){
    this.facturaSwBaja = this.formBajaFactura.value
    this.facturaSwBaja.idFactura = idFactura
    this.facturaSwBaja.detalleFacturas = []
      this.inventarioService.bajaFactura(this.facturaSwBaja).subscribe(data => {
        if (data['result'] === 1) {
          this.formEditaFactura.reset();
          this.muestraFacturas()
          Swal.fire({
            title: "Guardado",
            text: "Se ha registrado exitosamente",
            type: "success",
            // timer: 3000
          })
        }else{
          Swal.fire({
            title: "ERROR",
            text: data['message'],
            type: "warning",
            // timer: 2000
          })
        }
    }, error => {
  })   
  }

  agregarDetalle(){
    let det =this.formDetalle.value
    this.detalleArrayGuardar.push(det)
    this.formDetalle.reset();
    this.validarForms()
    this.hayInvSw = true
  }

  agregarDetalleHw(){
    let hayloteEnDatosDetalle = 0
    let loteHw = this.loteHw.nativeElement.value;
    let det =this.formDetalleHw.value
    for (let index = 0; index < this.datosdetalle.length; index++) {
      var size = Object.keys(this.datosdetalle[index]).length;
      if (loteHw == this.datosdetalle[index].lote) {
            hayloteEnDatosDetalle = 1
          }
    }
    if (hayloteEnDatosDetalle == 0) {
      let datosDetallehw={
        'numeroSerie':    '',
        'numeroInvAlm':   '',
        "lote": loteHw,
      }
      this.datosdetalle.push(datosDetallehw)
    }
    let deta = this.datosdetalle.slice()
    this.datosDetalle.push(deta)
    let detallehw={
      "cantidad":parseInt(this.formDetalleHw.value['cantidad']),
      "idHardware":parseInt(this.formDetalleHw.value['idHardware']),
      "lote":loteHw,
      "precioUnitario": this.formDetalleHw.value['precioUnitario'],
      "nombreSw":       '',
      "auxiliar":       '',
    }
    let carsLocal = {
      "lote":loteHw,
      "listaCaracteristicas":this.formDetalleHw.value['cars']
    }
    this.carsHw.push(carsLocal)
    this.detalleArrayGuardarHw.push(detallehw)
    this.hayInvHw = true
    this.validarForms()
    this.formDetalleHw.reset();
    this.datosdetalle.length = 0
    this.validarForms()
  }

  vaciardatosdetalle(){
  }

  menosTotal(){
    let tootal = this.totalEnc.nativeElement.value;
      tootal = tootal.replace('-', '');
      this.totalEnc.nativeElement.value = tootal
  }

  menosSubTotal(){
    let subtootal = this.subtotalEnc.nativeElement.value;
      subtootal = subtootal.replace('-', '');
      this.subtotalEnc.nativeElement.value = subtootal
  }

  clicAgregarDetalle(){ 
    this.consecutivoLote = this.consecutivoLote+1
    this.vaciardatosdetalle()
    let fecha = this.txtFecha.nativeElement.value;
    let noFactura = this.txtNumeroFactura.nativeElement.value;
    let armaLote   
    fecha = fecha.slice(0, 7)
    fecha = fecha.replace('-', ''); 
    if (this.consecutivoLote < 10) {
      armaLote = fecha+noFactura+'H0'+this.consecutivoLote
    }else{
      armaLote = fecha+noFactura+'H'+this.consecutivoLote
    }
    this.formDetalleHw.controls['lote'].setValue(armaLote)
  }

  clicAgregarDetalleSw(){
    this.consecutivoLoteSw = this.consecutivoLoteSw+1
    let fecha = this.txtFecha.nativeElement.value;
    let noFactura = this.txtNumeroFactura.nativeElement.value;
    fecha = fecha.slice(0, 7)
    fecha = fecha.replace('-', ''); 
    let armaLoteSw
    if (this.consecutivoLoteSw < 10) {
      armaLoteSw = fecha+noFactura+'S0'+this.consecutivoLoteSw
    }else{
      armaLoteSw = fecha+noFactura+'S'+this.consecutivoLoteSw
    }
    this.formDetalle.controls['lote'].setValue(armaLoteSw)
  }

  cambioCantHw(valor){
    let cantHw = parseInt(this.txtCantHw.nativeElement.value);
    let loteHw = this.loteHw.nativeElement.value;
    if (cantHw > 0 && loteHw != '') {
    this.btnAgregaDatosDetalle = true
    this.tblAgregaDatosDetalle = true
    this.txtCantHw.nativeElement.disabled
    }
    this.validarAgregarDetalle()
  }
  
  validarForms(){
    let hwLen = this.detalleArrayGuardarHw.length
    let swLen = this.detalleArrayGuardar.length
    if ((hwLen > 0 || swLen > 0) && this.formFactura.valid && this.validaAgregarDetalle == true && this.bandFolioFiscal == true ) {
      this.btnGuardaFactura = false
    }else{ 
      this.btnGuardaFactura = true
    }
  }
  
  validarAgregarDetalle(){
    let idHardware = this.formDetalleHw.value['idHardware']
    let cantHw = parseInt(this.txtCantHw.nativeElement.value);
   if (this.formDetalleHw.valid && cantHw > 0 && (idHardware != 0 || idHardware == null || idHardware == undefined )) {
      this.validaAgregarDetalle = false
    }else{
      this.validaAgregarDetalle = true
    }
  }

  eliminarDatosDetalleHw(detalle){
    this.datosdetalle.splice(detalle,1)
    let cantHw = this.txtCantHw.nativeElement.value;
    // if (parseInt(cantHw) === this.datosdetalle.length) {
    //   this.btnAgregaDatosDetalle = false
    // }else{
    //   this.btnAgregaDatosDetalle = true
    // }
    this.validarForms()
    this.validarAgregarDetalle()
  }
    
  noSpecialChar(char){
  const onlyspecial = /[°¬_"&@!=¡¿¨´~`',;:.*+\-?^$/{}<>()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
        return false;
    }
    return true
  }
 
  noSpecialCharNoInv(char){
  const onlyspecial = /[A-Za-z°¬_"&@!=¡¿¨´~`',;:.*+\-?^$/{}<>s()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
        return false;
    }
    return true
  }

  noSpecialCharPrecios(char){
  const onlyspecial = /[A-Z°¬_"&@!=¡¿¨´~`',;:*+\-?^$/{}<>()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
        return false;
    }
    return true
  }
  
  disableDate(){
    return false;
  }  

  abreModalDocumentos(idFactura){
    this.muestraTipoDocs()
    this.idFacturaDoc = idFactura
    this.inventarioService.getListDocumentosFactura(idFactura).subscribe((respuesta:any) =>{
      this.documentosFactura = respuesta.data;
    })
  }

  nuevoDoc(event){
    this.toFile = event.target.files;
  }

  async subirArchivo() {
    const encrypted = localStorage.getItem('akuhandler');
    if (encrypted) {
      const bytes = CryptoJS.AES.decrypt(encrypted, this.encryptionKey);
      // console.log(bytes.toString(CryptoJS.enc.Utf8));
      // debugger;
    }

    const file = this.toFile.item(0);
    this.uploadService.fileUpload(file);
    let idFacturaDoc = this.idFacturaDoc
    let datoPost={
        "tipoDocumento": "F",
        "idFHA": idFacturaDoc,
        "listaDocumentos": [{
          "idTipoDocumento": '1',
          "ruta": file.name
        }]
      }
    this.inventarioService.saveDocumento(datoPost).subscribe((respuesta:any) =>{
      if (respuesta['result'] === 1) {
        this.formDocumento.reset()
        Swal.fire({
          title: "Guardado",
          text: "Se ha registrado exitosamente",
          type: "success",
        })
      }else{
        Swal.fire({
          title: "ERROR",
          text: respuesta['message'],
          type: "warning",
        })
      }
    }) 
  }

  agregarDatosDetalle(){
    if (this.bandNoInvRepetido == true && this.bandNoSerieRepetido == true) {
      this.btnCerrarModalDatosDetalle.nativeElement.click();
        Swal.fire({
          title: "Advertencia",
          text: "Este número de serie o número de inventario ya está registrado con otro equipo",
          type: "warning",
        }).then(()=>{
          let element: HTMLElement = document.getElementsByClassName('btn_agregarDatosDetalle')[0] as HTMLElement;
          element.click()
        })
    }else{
      let loteHw = this.loteHw.nativeElement.value;
      let det =this.formDatosDetalle.value
      let datosDetallehw={
        'numeroSerie':    this.formDatosDetalle.value['numeroSerie'],
        'numeroInvAlm':   this.formDatosDetalle.value['numeroInvAlm'],
        "lote": loteHw,
      }
      this.datosdetalle.push(datosDetallehw)
      this.formDatosDetalle.reset();
      let cantHw = this.txtCantHw.nativeElement.value;
      if (parseInt(cantHw) === this.datosdetalle.length) {
        this.btnAgregaDatosDetalle = false
      }else{
        this.btnAgregaDatosDetalle = true
      }
      this.bandNoInvRepetido = false
      this.bandNoSerieRepetido = false
      this.bandAgregaDatosDetalle = true
      this.validarAgregarDetalle()
      this.validarForms()
    }
   
  }

  noSerieRepetido(event){
    if (event == '') {
      this.bandNoSerieRepetido = false
      this.validaAgregaDatosDetalle()
    } else {
      this.inventarioService.getInvHwExisteNoSerie(event).subscribe((respuesta:any) =>{
        if (respuesta['result'] == 1) {
          this.bandNoSerieRepetido = true
          this.validaAgregaDatosDetalle()
          this.btnCerrarModalDatosDetalle.nativeElement.click();
          Swal.fire({
            title: "Advertencia",
            text: "Este número de serie ya está registrado con otro equipo",
            type: "warning",
          }).then(()=>{
            let element: HTMLElement = document.getElementsByClassName('btn_agregarDatosDetalle')[0] as HTMLElement;
            element.click()
          })
        }else{
          this.bandNoSerieRepetido = false
          this.validaAgregaDatosDetalle()
        }
      }) 
    }
  }
 
  noInvRepetido(event){
    if (event == '') {
      this.bandNoInvRepetido = false
      this.validaAgregaDatosDetalle()
    } else {
      this.inventarioService.getInvHwExisteNoInv(event).subscribe((respuesta:any) =>{
        if (respuesta['result'] === 1) {
          this.bandNoInvRepetido = true
          this.validaAgregaDatosDetalle()
          this.btnCerrarModalDatosDetalle.nativeElement.click();
          Swal.fire({
            title: "Advertencia",
            text: "Este número de inventario ya está registrado con otro equipo",
            type: "warning",
          }).then(()=>{
            let element: HTMLElement = document.getElementsByClassName('btn_agregarDatosDetalle')[0] as HTMLElement;
            element.click()
          })
        }else{
          // if (this.formDatosDetalle.value['numeroSerie'] || this.formDatosDetalle.value['numeroInvAlm']) {
            this.bandNoInvRepetido = false
            this.validaAgregaDatosDetalle()
          // }
           
        }
      }) 
    }
  }

  validaFolioFiscal(event){
    if (event == '') {
      
    } else if( this.formFactura.value['numeroFactura'].length < 12){
      let element: HTMLElement = document.getElementsByClassName('cerrarModalNuevaFactura')[0] as HTMLElement;
      element.click()
      Swal.fire({
        title: "Advertencia",
        text: "El folio fiscal debe ser de 12 caracteres, el último bloque",
        type: "warning",
      }).then(()=>{
        let element2: HTMLElement = document.getElementsByClassName('btnNuevaFactura')[0] as HTMLElement;
        element2.click()
      })
    }else{
      this.inventarioService.getFacturasxFolio(event).subscribe((respuesta:any) =>{
        if (respuesta['result'] === 1) {
          this.formFactura.controls['numeroFactura'].disable()
          this.bandFolioFiscal = true
          this.folioFiscal = event
          // this.txtNumeroFactura.nativeElement.disabled
        }else{
          let element: HTMLElement = document.getElementsByClassName('cerrarModalNuevaFactura')[0] as HTMLElement;
          element.click()
          Swal.fire({
            title: "Advertencia",
            text: "Este folio ya está registrado",
            type: "warning",
          }).then(()=>{
            let element2: HTMLElement = document.getElementsByClassName('btnNuevaFactura')[0] as HTMLElement;
            element2.click()
          })
        }
      })
    }
    
  }

  validaAgregaDatosDetalle(){
    if ((this.formDatosDetalle.value['numeroSerie'] != '' || this.formDatosDetalle.value['numeroSerie'] != null) || (this.formDatosDetalle.value['numeroInvAlm'] != '' || this.formDatosDetalle.value['numeroInvAlm'] != null ) ) {
      if (this.bandNoInvRepetido == false  && this.bandNoSerieRepetido == false) {
       
        if((this.formDatosDetalle.value['numeroSerie'] == '' || this.formDatosDetalle.value['numeroSerie'] == null) && (this.formDatosDetalle.value['numeroInvAlm'] == '' || this.formDatosDetalle.value['numeroInvAlm'] == null ) ){
          this.bandAgregaDatosDetalle = true
        } else{
          this.bandAgregaDatosDetalle = false
        }
      }else{
        this.bandAgregaDatosDetalle = true
      }
    } else {
      this.bandAgregaDatosDetalle = true
    }
  }
  
  limpiarFactura(){
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se perderán todos los datos que se han ingresado",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar'
    }).then((result) => {
      if (result.dismiss) {
        let element: HTMLElement = document.getElementsByClassName('btnNuevaFactura')[0] as HTMLElement;
        element.click()
      }else{
          this.folioFiscal = 0
          this.detalleArrayGuardar = [];
          this.detalleArrayGuardarHw = [];
          this.datosArrayGuardar = [];
          this.datosDetalle = [];
          this.datosDetalleMuestra = [];
          this.facturasSoftwareGuardar = [];
          this.detalleArraySw = [];
          this.detalleArrayHw = [];
          this.diti = [];
          this.diticars = [];
          this.datosdetalle = [];
          this.detalle = [];
          this.detalles = [];
          this.situacion = []
          this.datosInvHw = []
          this.carsHw = []
          this.formFactura.reset();
          this.detalleArrayGuardar.length = 0
          this.detalleArrayGuardarHw.length = 0
          this.detalles.length = 0
          this.datosDetalle.length = 0
          this.btnAgregaDetHw = false
          this.btnAgregaDetSw = false
          this.btnGuardaFactura = true
          this.consecutivoLote = 0
          this.consecutivoLoteSw = 0
          this.totalFinal = 0
          this.subTotalFinal = 0
          this.hayInvHw = false
          this.hayInvSw = false
          this.tblAgregaDatosDetalle = false
          this.validaAgregarDetalle = true;
          this.bandAgregaDatosDetalle = true;
          this.modalAgregarDetalleAbierto = false;
          this.inputLote = false;
          this.bandNoSerieRepetido = false;
          this.bandNoInvRepetido = false;
          this.bandFolioFiscal = false;
          this.formFactura.controls['numeroFactura'].enable()
          let element: HTMLElement = document.getElementsByClassName('btnNuevaFactura')[0] as HTMLElement;
          element.click()
      }
    })  
  }

  loadPage(page: number) {
    this.page = page;
    this.muestraFacturas();
  }

  funcionErrorPaginacion(){
    if (!Swal.isVisible()) {
      Swal.fire({
        title: "ERROR",
        text: "No hay coincidencias con tu criterio de búsqueda",
        type: "warning"
      }).then(() => {
        if (this.page != 1) {
          this.page = 1;
          this.muestraFacturas();
        } else {
          this.txtBuscaFacSoft.nativeElement.value = "";
          this.page = 1;
          this.muestraFacturas();
        }
      })
    }
  }
}