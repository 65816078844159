import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { Caracteristicas } from '../../../model/caracteristicas';
import { Roles } from 'src/app/model';

@Component({
  selector: 'app-catalogo-caracteristicas',
  templateUrl: './catalogo-caracteristicas.component.html',
  styleUrls: ['./catalogo-caracteristicas.component.scss']
})
export class CatalogoCaracteristicasComponent implements OnInit, AfterViewInit {
  public formCaracteristica: FormGroup;
  public formEditaCaracteristica: FormGroup;
  public caracteristicas: Caracteristicas[] = [];
  public tiposHw: any[] = [];

  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;

  caracteristicaEditar: any = {
    idCaracteristica: null,
    idTipoHw: null,
    caracteristica: '',
    descripcion: '',
  }

  public rolesAzure = Roles;

  @ViewChild('txtBuscaCaracteristica', { static: false }) txtBuscaCaracteristica!: ElementRef<HTMLInputElement>;

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService) {
    this.formCaracteristica = this.formBuilder.group({
      idTipoHw: ['', [Validators.required]],
      caracteristica: ['', [Validators.required]],
      descripcion: ['', [Validators.required]],
    })

    this.formEditaCaracteristica = this.formBuilder.group({
      idCaracteristica: ['', [Validators.required]],
      idTipoHw: ['', [Validators.required]],
      caracteristica: ['', [Validators.required]],
      descripcion: ['', [Validators.required]],
    })
  }

  ngOnInit() { this.buscarTipoHw() }

  ngAfterViewInit(): void { this.buscarCaracteristicas() }

  buscarTipoHw() {
    this.inventarioService.getTipoHardware().subscribe((respTiposHw: any) => {
      this.tiposHw = respTiposHw.data;
    })
  }

  buscarCaracteristicas() {
    this.caracteristicas = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    if (this.txtBuscaCaracteristica.nativeElement.value === '') {
      this.inventarioService.getCaracteristicasLimit(this.page).subscribe((respCaracteristicas: any) => {
        if (respCaracteristicas.data != null && respCaracteristicas.data.length > 0) {
          this.caracteristicas = respCaracteristicas.data;
          this.totalRecords = respCaracteristicas.totalRecords;
          this.cargarLoader = false;
        }
        else this.funcionErrorPaginacion();
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
    else {
      let datoPost = {
        "nombreTabla": "caracteristica",
        "valorBusqueda": this.txtBuscaCaracteristica.nativeElement.value,
        "Page": this.page
      }
      this.inventarioService.getCaracteristicasTablaCampo(datoPost).subscribe((respCaracteristicas: any) => {
        if (respCaracteristicas['result'] === 0) this.funcionErrorPaginacion();
        else {
          this.caracteristicas = respCaracteristicas.data;
          this.totalRecords = respCaracteristicas.totalRecords;
          this.cargarLoader = false;
        }
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
  }

  buscaCaracteristicasInput() {
    let valor = this.txtBuscaCaracteristica.nativeElement.value;
    if (valor === '') {
      this.buscarCaracteristicas();
    } else {
      let datoPost = {
        "nombreTabla": "caracteristica",
        "valorBusqueda": valor,
        "Page": this.page
      }
      this.inventarioService.getCaracteristicasTablaCampo(datoPost).subscribe((respCaracteristicas: any) => {
        if (respCaracteristicas['result'] === 0) this.funcionErrorPaginacion();
        else {
          this.caracteristicas = respCaracteristicas.data;
          this.totalRecords = respCaracteristicas.totalRecords;
          this.cargarLoader = false;
        }
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }

  };

  guardaCaracteristica(): any {
    this.inventarioService.createCaracteristica(this.formCaracteristica.value).subscribe(data => {
      if (data['result'] === 1) {
        this.page = 1
        this.formCaracteristica.reset();
        this.buscarCaracteristicas();
        Swal.fire({
          title: "Guardado",
          text: "Se ha registrado exitosamente",
          type: "success",
          // timer: 3000
        })
      } else {
        Swal.fire({
          title: "ERROR",
          text: data['message'],
          type: "warning",
          // timer: 2000
        })
      }

    })
  }

  editaCaracteristica(): any {
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se modificará el nombre de la característica, aunque ya esté registrada con algun equipo",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.dismiss) {

      } else {
        this.inventarioService.updateCaracteristica(this.formEditaCaracteristica.value).subscribe(data => {
          if (data['result'] === 1) {
            this.page = 1
            this.formEditaCaracteristica.reset();
            this.buscarCaracteristicas();
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
              // timer: 3000
            })
          } else {
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
              // timer: 2000
            })
          }
        })
      }
    })
  }

  abreEditaCaracteristicas(idCaracteristica) {
    this.inventarioService.getCaracteristicasXId(idCaracteristica).subscribe((respCaracteristicas: any) => {
      this.caracteristicaEditar = respCaracteristicas.data;
    })
  }

  cambioBuscaCaracteristicasInput() {
    let valor = this.txtBuscaCaracteristica.nativeElement.value;
    if (valor === '') {
      this.buscarCaracteristicas();
    }
  }

  noSpecialChar(char) {
    const onlyspecial = /[°¬_"&@!=¡¿¨´~`',;:*+?^${}<>()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
      return false;
    }
    return true
  }

  loadPage(page: number) {
    this.page = page;
    this.buscarCaracteristicas();
  }

  funcionErrorPaginacion() {
    if (!Swal.isVisible()) {
      Swal.fire({
        title: "ERROR",
        text: "No hay coincidencias con tu criterio de búsqueda",
        type: "warning"
      }).then(() => {
        if (this.page != 1) {
          this.page = 1;
          this.buscarCaracteristicas();
        } else {
          this.txtBuscaCaracteristica.nativeElement.value = "";
          this.page = 1;
          this.buscarCaracteristicas();
        }
      })
    }
  }
}
