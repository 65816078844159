import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor() { }

  
 async fileUploadCreado(file,nombre) {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: 'AKIATS4AYSRQODPHLGFF',
        secretAccessKey: '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',
        region: 'us-east-1',
      }
    );
    const params = {
      Bucket: 'inventario-rfid',
      Key: nombre,
      Body: file,
      // Body: Binary,
      // ACL: 'public-read',
      contentType : 'application/pdf'
    };
    bucket.upload(params, function (err, data) {
      if (err) {
        console.log('EROOR: '+file.name+ err,JSON.stringify( err));
        return false;
      }
      console.log('File Uploaded.', data);
      return true;

    });
  }
  
 async fileUpload(file) {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: 'AKIATS4AYSRQODPHLGFF',
        secretAccessKey: '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',
        region: 'us-east-1',
      }
    );
    const params = {
      Bucket: 'inventario-rfid',
      Key: file.name,
      Body: file,
      // ACL: 'public-read',
      ContentType: contentType
    };
    bucket.upload(params, function (err, data) {
      if (err) {
        console.log('EROOR: '+file.name+ err,JSON.stringify( err));
        return false;
      }
      console.log('File Uploaded.', data);
      return true;

    });
  }
}