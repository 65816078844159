<div class="shadow p-3 mb-5 bg-body rounded">
            <div style="text-align: center;">
                <span><h3>Catálogo Tipo Sw</h3></span>
            </div>
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-5">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Buscar Tipo Sw" 
                        #txtBuscaTipoSw aria-label="Buscar Tipo Sw" aria-describedby="basic-addon2" 
                        (keyup.enter)="buscarTipoSw()" (change)="buscarTipoSw()">
                        <div class="input-group-append">
                        <button class="btn btn-outline-secondary" type="button" (click)="buscarTipoSw()"><i class="feather icon-search"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-md-2" 
                *appHasAnyRole="[
                rolesAzure.SUPERADMIN, 
                rolesAzure.SUPERVISOR, 
                ]">
                    <div class="div-btn-nuevo-hardware">
                        <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalNuevoTipoSw">Agregar</button>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-5">
                    <!-- <form [formGroup]="formTipoSw" (submit)="guardaTipoSw()">
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Ingresar Tipo Sw*" (keypress)="noSpecialChar($event)" maxlength="255" aria-label="Ingresar Tipo Sw" aria-describedby="basic-addon2" formControlName="nombre">
                            <div class="input-group-append">
                            <button class="btn btn-primary" [disabled]="formTipoSw.invalid">Agregar</button>
                            </div>
                        </div>
                    </form>    -->
                </div>
                <div class="col-md-3">
                   
                </div>
            </div>

            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-9" *ngIf="!cargarLoader">
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <th scope="col">Tipo Sw</th>
                            <th scope="col">Tiempo Notificación</th>
                            <th scope="col"
                            *appHasAnyRole="[
                            rolesAzure.SUPERADMIN, 
                            rolesAzure.SUPERVISOR, 
                            ]">Acciones</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let tiposw of tiposSw">
                                <td>
                                        {{tiposw.nombre}}
                                </td>
                                <td>
                                        {{tiposw.tiempoNotificacion}} Mes(es)
                                </td>
                                <td style="text-align: center;"
                                *appHasAnyRole="[
                                rolesAzure.SUPERADMIN, 
                                rolesAzure.SUPERVISOR, 
                                ]">
                                    <button class="btn btn-warning" (click)="abreEditarTipoSw(tiposw.idTipoSw)" data-bs-toggle="modal" data-bs-target="#modalEditaTipoSw">Editar<i class="feather icon-edit iconos-acciones"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                    <div class="d-flex justify-content-start align-items-center p-2">
                        <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" (pageChange)="loadPage($event)" [disabled]="tiposSw == null || tiposSw.length == 0">
                          <ng-template ngbPaginationPrevious>Anterior</ng-template>
                          <ng-template ngbPaginationNext>Siguiente</ng-template>
                        </ngb-pagination>
                      
                        <div class="d-flex align-items-center ml-3">
                          <label for="pageInput" class="mr-2">Buscar página específica:</label>
                          <input id="pageInput" type="number" class="form-control w-auto"
                                 #pageInput
                                 (change)="loadPage(pageInput.value)"
                                 (keyup.enter)="loadPage(pageInput.value)"
                                 onkeypress="return(event.charCode >= 48 && event.charCode <= 57)"
                                 placeholder="" aria-label="Buscar página específica" />
                        </div>
                      </div>
                </div>
                    <div class="col-md-2"></div>
            </div>
            
            
        </div>
        
        <div class="modal fade" id="modalNuevoTipoSw" tabindex="-1" aria-labelledby="modalNuevoTipoSw" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="modalNuevoTipoSw">Editar</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="formTipoSw" (submit)="guardaTipoSw()">
                        <div class="form-group">
                            <label class="control-label is-required" for="nombreDoc">Nombre</label>
                            <input type="text" class="form-control" placeholder="Ingresar Tipo Sw*" (keypress)="noSpecialChar($event)" maxlength="255" aria-label="Ingresar Tipo Sw" aria-describedby="basic-addon2" formControlName="nombre">
                            <!-- <div class="input-group-append">
                            <button class="btn btn-primary" [disabled]="formTipoSw.invalid">Agregar</button>
                            </div> -->
                        </div>
                        <div class="form-group"> 
                            <label for="tiempoNotificacion" class="control-label is-required.">Tiempo Notificación</label>
                            <select class="form-control" name="tiempoNotificacion" id="tiempoNotificacion" formControlName="tiempoNotificacion">
                                <option value="" disabled selected>Elige tiempo de notificación</option>
                                <option value="1">1 Mes</option>
                                <option value="1.5">Mes y medio</option>
                            </select>                    
                        </div>
                        <div style="text-align: center;"> 
                            <button type="submit" [disabled]="formTipoSw.invalid" data-bs-dismiss="modal" class="btn btn-primary">Guardar</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        </div>     
                    </form>
                </div>
                <div class="modal-footer">
                    
                </div>
              </div>
            </div>
          </div>


        <div class="modal fade" id="modalEditaTipoSw" tabindex="-1" aria-labelledby="modalEditaTipoSw" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="modalEditaTipoSw">Editar</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="formEditaTipoSw" (submit)="editaTipoSw()">
                        <input hidden="true" [(ngModel)]="tipoSwEditar.idTipoSw"  formControlName="idTipoSw">
                        <div class="form-group"> 
                            <label class="control-label is-required" for="nombreCar">Nombre Tipo Sw</label>
                            <input type="text" class="form-control" id="nombreCar" (keypress)="noSpecialChar($event)" maxlength="200" placeholder="Ingresar Tipo Sw*" formControlName="nombre" [(ngModel)]="tipoSwEditar.nombre">
                        </div>   
                        <div class="form-group"> 
                            <label for="tiempoNotificacion" class="control-label is-required.">Tiempo Notificación</label>
                            <select class="form-control" name="tiempoNotificacion" id="tiempoNotificacion" formControlName="tiempoNotificacion" [(ngModel)]="tipoSwEditar.tiempoNotificacion">
                                <option value="" disabled selected>Elige tiempo de notificación</option>
                                <option value="1" [selected]="tipoSwEditar.tiempoNotificacion == '1'">1 Mes</option>
                                <option value="1.5" [selected]="tipoSwEditar.tiempoNotificacion == '1.5'">Mes y medio</option>
                            </select>                    
                        </div>      
                        <div style="text-align: center;"> 
                            <button type="submit" [disabled]="formEditaTipoSw.invalid" data-bs-dismiss="modal" class="btn btn-primary">Guardar</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        </div>     
                    </form>
                </div>
                <div class="modal-footer">
                    
                </div>
              </div>
            </div>
          </div>