import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { Documento } from '../../../model/documento';
import { Roles } from 'src/app/model';

@Component({
  selector: 'app-catalogo-documentos',
  templateUrl: './catalogo-documentos.component.html',
  styleUrls: ['./catalogo-documentos.component.scss']
})
export class CatalogoDocumentosComponent implements OnInit, AfterViewInit {
  public formCatDocumento: FormGroup;
  public formEditaCatDocumento: FormGroup;
  public tipoDocumentos: Documento[] = []

  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;

  documentoEditar: any = {
    idTipoDocumento: null,
    nombreDocumento: '',
    tipoDocumento: '',
  }

  public rolesAzure = Roles;

  @ViewChild('txtBuscaDocumento', { static: false }) txtBuscaDocumento!: ElementRef<HTMLInputElement>;

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService) {
    this.formCatDocumento = this.formBuilder.group({
      nombreDocumento: ['', [Validators.required]],
      tipoDocumento: ['', [Validators.required]],
    });

    this.formEditaCatDocumento = this.formBuilder.group({
      idTipoDocumento: ['', [Validators.required]],
      nombreDocumento: ['', [Validators.required]],
      tipoDocumento: ['', [Validators.required]],
    });
  }

  ngOnInit() { }

  ngAfterViewInit(): void { this.muestraCatdocumento() }

  muestraCatdocumento() {
    this.tipoDocumentos = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    if (this.txtBuscaDocumento.nativeElement.value === '') {
      this.inventarioService.getDocumentosLimit(this.page).subscribe((respCatDoc: any) => {
        if (respCatDoc.data != null && respCatDoc.data.length > 0) {
          this.tipoDocumentos = respCatDoc.data;
          this.totalRecords = respCatDoc.totalRecords;
          this.cargarLoader = false;
        }
        else this.funcionErrorPaginacion();
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
    else {
      let datoPostDocs = {
        "nombreTabla": "documento",
        "valorBusqueda": this.txtBuscaDocumento.nativeElement.value,
        "Page": this.page
      }
      this.inventarioService.getDocumentosTablaCampo(datoPostDocs).subscribe((respDocs: any) => {
        if (respDocs['result'] === 0) this.funcionErrorPaginacion();
        else {
          this.tipoDocumentos = respDocs.data;
          this.totalRecords = respDocs.totalRecords;
          this.cargarLoader = false;
        }
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
  }

  abreEditarTipoDocumentos(idDoc) {
    this.inventarioService.getTipoDocumentosxId(idDoc).subscribe((respCatDoc: any) => {
      this.documentoEditar = respCatDoc.data;
    })
  }

  guardaCatDocumento() {
    this.inventarioService.createDocumento(this.formCatDocumento.value).subscribe(data => {
      if (data['result'] === 1) {
        this.formCatDocumento.reset();
        this.muestraCatdocumento();
        Swal.fire({
          title: "Guardado",
          text: "Se ha registrado exitosamente",
          type: "success",
        })
      } else {
        Swal.fire({
          title: "ERROR",
          text: data['message'],
          type: "warning",
        })
      }
    })
  }

  editaCatDocumento() {
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se modificará el catalogo de documentos, aunque ya exista conexión de este registro",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.dismiss) {

      } else {
        this.inventarioService.updateDocumento(this.formEditaCatDocumento.value).subscribe(data => {
          if (data['result'] === 1) {
            this.formEditaCatDocumento.reset();
            this.muestraCatdocumento();
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
            })
          } else {
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
            })
          }
        })
      }
    })

  }

  buscaCtlDocumentoInput() {
    if (this.txtBuscaDocumento.nativeElement.value === '') {
      this.muestraCatdocumento();
    } else {
      let datoPostDocs = {
        "nombreTabla": "documento",
        "valorBusqueda": this.txtBuscaDocumento.nativeElement.value,
        "Page": this.page
      }
      this.inventarioService.getDocumentosTablaCampo(datoPostDocs).subscribe((respDocs: any) => {
        if (respDocs['result'] === 0) this.funcionErrorPaginacion();
        else {
          this.tipoDocumentos = respDocs.data;
          this.totalRecords = respDocs.totalRecords;
          this.cargarLoader = false;
        }
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }
  }

  cambioBuscaCtlDocumentoInput() {
    let valor = this.txtBuscaDocumento.nativeElement.value;
    if (valor === '') {
      this.page = 1
      this.muestraCatdocumento();
    }
  }


  noSpecialChar(char) {
    const onlyspecial = /[°¬_"&@!=¡¿¨´~`',;:.*+\-?^$/{}<>()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
      return false;
    }
    return true
  }

  loadPage(page: number) {
    this.page = page;
    this.muestraCatdocumento();
  }

  funcionErrorPaginacion() {
    if (!Swal.isVisible()) {
      Swal.fire({
        title: "ERROR",
        text: "No hay coincidencias con tu criterio de búsqueda",
        type: "warning"
      }).then(() => {
        if (this.page != 1) {
          this.page = 1;
          this.muestraCatdocumento();
        } else {
          this.txtBuscaDocumento.nativeElement.value = "";
          this.page = 1;
          this.muestraCatdocumento();
        }
      })
    }
  }
}
