<div class="shadow p-3 mb-5 bg-body rounded">
  <div style="text-align: center;">
    <span>
      <h3>Reporte de Inventario de Hardware</h3>
    </span>
  </div>
  <div class="row">
    <div class="col-md-12" style="overflow: auto;"  *ngIf="!cargarLoader">
      <div class="row">
        <div class="col-8"></div>
        <div class="col-2" style="text-align:right ;"
        *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
          <button class="btn btn-primary mb-5" (click)="generatePDF()">Generar PDF</button>
        </div>
        <div class="col-2" style="text-align:right ;"
        *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
          <button class="btn btn-primary mb-5" (click)="exportExcel()">Generar Excel</button>
        </div>
      </div>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Tipo</th>
            <th scope="col">Marca</th>
            <th scope="col">Modelo</th>
            <th scope="col"># Inventario</th>
            <th scope="col"># Serie</th>
            <th scope="col">TAG</th>
            <th scope="col">Lote</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let per of invHardware">
            <td>
              {{per.tipoHW}}
            </td>
            <td>
              {{per.marca}}
            </td>
            <td>
              {{per.modelo}}
            </td>
            <td>
              {{per.numeroInvAlm}}
            </td>
            <td>
              {{per.numeroSerie}}
            </td>
            <td>
              {{per.numeroTag}}
            </td>
            <td>
              {{per.lote}}
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <pagination-controls (pageChange)="page = $event" previousLabel="Anterior" S nextLabel="Siguiente"></pagination-controls> -->

      <div class="d-flex justify-content-start align-items-center p-2">
        <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
          (pageChange)="loadPage($event)" [disabled]="invHardware == null || invHardware.length == 0">
          <ng-template ngbPaginationPrevious>Anterior</ng-template>
          <ng-template ngbPaginationNext>Siguiente</ng-template>
        </ngb-pagination>

        <div class="d-flex align-items-center ml-3">
          <label for="pageInput" class="mr-2">Buscar página específica:</label>
          <input id="pageInput" type="number" class="form-control w-auto" #pageInput
            (change)="loadPage(pageInput.value)" (keyup.enter)="loadPage(pageInput.value)"
            onkeypress="return(event.charCode >= 48 && event.charCode <= 57)" placeholder=""
            aria-label="Buscar página específica" />
        </div>
      </div>

    </div>
  </div>
</div>