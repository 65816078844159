<div class="shadow p-3 mb-5 bg-body rounded">
  <div style="text-align: center;">
    <span>
      <h1>Catálogo de Hardware</h1>
    </span>
  </div>
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-8">
      <div class="input-group mb-3">
        <input type="text" class="form-control" (change)="cambioBuscaHardwareInput()" placeholder="Buscar Hardware"
          #txtBuscaHardware aria-label="Buscar Tipo Hw" aria-describedby="basic-addon2"
          (keyup.enter)="buscaHardwareInput()">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="buscaHardwareInput()"><i
              class="feather icon-search"></i></button>
        </div>
      </div>
    </div>
    <div class="col-md-2" 
      *appHasAnyRole="[
      rolesAzure.SUPERADMIN,
      rolesAzure.SUPERVISOR,
      ]"
    >
      <div class="div-btn-nuevo-hardware">
        <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalNuevoCatSoftware">Agregar</button>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>



  <div *ngIf="!cargarLoader">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Modelo</th>
          <th scope="col">Tipo</th>
          <th scope="col">Marca</th>
          <th scope="col"
          *appHasAnyRole="[
          rolesAzure.SUPERADMIN,
          rolesAzure.SUPERVISOR,
          ]"
      >Editar</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let h of hardware">
          <td>
            {{h.modelo}}
          </td>
          <td>
            {{h.tipoHW}}
          </td>
          <td>
            {{h.marca}}
          </td>
          <td style="text-align: center;"
          *appHasAnyRole="[
            rolesAzure.SUPERADMIN,
            rolesAzure.SUPERVISOR,
            ]">
            <button class="btn btn-warning" (click)="abreEditaCatHardware(h.idHardware)" data-bs-toggle="modal"
              data-bs-target="#modalEditaHardware">Editar<i class="feather icon-edit iconos-acciones"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <pagination-controls (pageChange)="page = $event" previousLabel="Anterior" S nextLabel="Siguiente"></pagination-controls> -->
    <div class="d-flex justify-content-start align-items-center p-2">
      <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" (pageChange)="loadPage($event)" [disabled]="hardware == null || hardware.length == 0">
        <ng-template ngbPaginationPrevious>Anterior</ng-template>
        <ng-template ngbPaginationNext>Siguiente</ng-template>
      </ngb-pagination>
    
      <div class="d-flex align-items-center ml-3">
        <label for="pageInput" class="mr-2">Buscar página específica:</label>
        <input id="pageInput" type="number" class="form-control w-auto"
               #pageInput
               (change)="loadPage(pageInput.value)"
               (keyup.enter)="loadPage(pageInput.value)"
               onkeypress="return(event.charCode >= 48 && event.charCode <= 57)"
               placeholder="" aria-label="Buscar página específica" />
      </div>
    </div>

  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="modalNuevoCatSoftware" tabindex="-1" aria-labelledby="modalNuevoCatSoftware"
  aria-hidden="true" style="z-index: 1072;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Agregar nuevo catalogo de Hardware</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formCatHardware" (submit)="guardaCatHardware()">

          <div class="form-group">
            <label class="control-label is-required" for="nombreCar">Modelo</label>
            <input type="text" class="form-control" id="nombreCar" (keypress)="noSpecialChar($event)" #txtModelo
              placeholder="nombre" formControlName="modelo" maxlength="50">
          </div>
          <div class="form-group">
            <label for="state_id" class="control-label is-required">Tipo de Hardware</label>
            <select class="form-control" name="tipoHw" id="tipoHw" formControlName="idTipoHw">
              <option value="" disabled selected>Elige tipo de Hardware</option>
              <option [value]=tipohw.idTipoHw *ngFor="let tipohw of tipohw">{{tipohw.nombre}}</option>
            </select>
          </div>

          <div class="form-group">
            <label for="state_id" class="control-label is-required">Marca</label>
            <select class="form-control" name="marca" id="marca" formControlName="idMarca">
              <option value="" disabled selected>Elige la marca</option>
              <option [value]=marca.idMarca *ngFor="let marca of marcas">{{marca.nombreMarca}}</option>
            </select>
          </div>
          <div class="form-group">
          </div>

        </form>
      </div>
      <div class="modal-footer">
        <button type="submit" [disabled]="formCatHardware.invalid" data-bs-dismiss="modal" class="btn btn-primary"
          (click)="guardaCatHardware()">Guardar</button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="modalEditaHardware" tabindex="-1" aria-labelledby="modalEditaHardware" aria-hidden="true"
  style="z-index: 1072;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Editar catalogo de Hardware</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formEditaCatHardware" (submit)="editaCatHardware()">
          <input hidden="true" [(ngModel)]="hardwareEditar.idHardware" formControlName="idHardware">
          <div class="form-group">
            <label class="control-label is-required" for="nombreCar">Modelo</label>
            <input type="text" class="form-control" id="nombreCar" (keypress)="noSpecialChar($event)" #txtModelo
              placeholder="nombre" formControlName="modelo" maxlength="50" [(ngModel)]="hardwareEditar.modelo">
          </div>
          <div class="form-group">
            <label for="state_id" class="control-label is-required">Tipo de Hardware</label>
            <select class="form-control" name="tipoHw" id="tipoHw" formControlName="idTipoHW"
              [(ngModel)]="hardwareEditar.idTipoHW">
              <option value="" disabled selected>Elige tipo de Hardware</option>
              <option [value]=tipohw.idTipoHw *ngFor="let tipohw of tipohw"
                [selected]="tipohw.idTipoHw == hardwareEditar.idTipoHW">{{tipohw.nombre}}</option>
            </select>
          </div>

          <div class="form-group">
            <label for="state_id" class="control-label is-required">Marca</label>
            <select class="form-control" name="marca" id="marca" formControlName="idMarca"
              [(ngModel)]="hardwareEditar.idMarca">
              <option value="" disabled selected>Elige la marca</option>
              <option [value]=marca.idMarca *ngFor="let marca of marcas"
                [selected]="marca.idMarca == hardwareEditar.idMarca">{{marca.nombreMarca}}</option>
            </select>
          </div>
          <div class="form-group">
          </div>

          <div style="text-align: center;">
            <button type="submit" [disabled]="formEditaCatHardware.invalid" data-bs-dismiss="modal"
              class="btn btn-primary">Guardar</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
          </div>

        </form>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>