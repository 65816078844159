import { Component, OnInit } from '@angular/core';
import { HttpClient        } from '@angular/common/http';
import   * as XLSX           from 'xlsx';

import { DatePipe                                                                           } from '@angular/common';
import { PdfMakeWrapper, Txt, Table, Img, Columns, Line, Rect, Stack, Canvas, ITable, IText } from 'pdfmake-wrapper';
import   html2canvas                                                                          from 'html2canvas';
import { element                                                                            } from 'protractor';
import { stringify                                                                          } from 'querystring';
import { read, utils, writeFileXLSX                                                         } from 'xlsx';

import { environment } from 'src/environments/environment';
import { PdfService  } from '../../../../app/data/service/pdf-excel/pdf.service';

class Area {
  country: string;
     area: number;
}

@Component({ 
  selector:     'app-pdf-alta-asignacion',
  templateUrl:  './pdf-alta-asignacion.component.html',
  styleUrls:   ['./pdf-alta-asignacion.component.scss']
})
export class PdfAltaAsignacionComponent implements OnInit {
  
  private url: string = environment.api;
  // data = []
  public data: any = [
     ['Equipo','# Serie', '# TAG','# Inventario'],

  ];
  public dataTable: any = []

  constructor(private _pdfService:PdfService,private http: HttpClient) {
    this.areas = this.getAreas()
  }

  pointClickHandler(e) {
    this.toggleVisibility(e.target);
  }

  legendClickHandler(e) {
    const arg = e.target;
    const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];

    this.toggleVisibility(item);
  }

  toggleVisibility(item) {
    if (item.isVisible()) {
      item.hide();
    } else {
      item.show();
    }
  }

  ngOnInit() {
    this.buscarUbicaciones()
    this.muestraAsignaciones()
  }

  buscarUbicaciones(){
    this.http.get(`${this.url}InventariosHW/limit/100`).subscribe((respUbicaciones:any) =>{
      //  this.data.push(jsontoarray)
      // let prb = JSON.stringify(respUbicaciones.data)
      for (let index = 4; index < respUbicaciones.data.length+4; index++) {
        let posicion = index - 4
        this.data.push([ 
          respUbicaciones.data[posicion]['tipoHW']+' - '+respUbicaciones.data[posicion]['marca']+' - '+respUbicaciones.data[posicion]['modelo'],
          respUbicaciones.data[posicion]['numeroSerie'],
          respUbicaciones.data[posicion]['numeroTag'],
          respUbicaciones.data[posicion]['numeroInvAlm'],
      ])
      
      }
      //  angular.forEach(respUbicaciones.data, function(value, key) {
      //   this.push(key + ': ' + value);
      // }, log);
    })
  }

  areas: Area[] = [{
    country: 'Russia',
    area: 12,
  }, {
    country: 'Canada',
    area: 7,
  }, {
    country: 'USA',
    area: 7,
  }, {
    country: 'China',
    area: 7,
  }, {
    country: 'Brazil',
    area: 6,
  }, {
    country: 'Australia',
    area: 5,
  }, {
    country: 'India',
    area: 2,
  }, {
    country: 'Others',
    area: 55,
  }];

  getAreas(): Area[] {
    return this.areas;
  }

  imgData;
  async generatePDF() {

    
    const FileName = 'Reporte de prueba';
    const embedHTML = document.getElementById('foo');
    const AreaName = 'Area de prueba';
    const caputureHTML = document.getElementById('captura');
    const tituloEncabezado = 'prueba';

    // motivo: string,numeroEmpleado:any,area:string,folio:string,empleado:string,tipoMov:string,fecha:string
    // this._pdfService.generatePDFWhitCaptureHTML(this.data, embedHTML, FileName,  AreaName, caputureHTML);
    // this._pdfService.generatePDFAsignacion(this.data, embedHTML,FileName, motivo,numeroEmpleado,area,folio,empleado,tipoMov,fecha);
    // this._pdfService.generatePDF(this.data, embedHTML,FileName, motivo,tituloEncabezado);
   
  }

  muestraAsignaciones(){
    let rep = {
      "limit":300
    }
    this.http.post(`${this.url}Reportes/AsignacionUbicacionSW`,rep).subscribe((respAsignaciones:any) =>{
      this.dataTable = respAsignaciones.data;
   })
  }

  exportExcel(){
    /* DESDE AQUI es codigo para exportar en excel lo que tiene la tabla con cierto id */
      // var wb = XLSX.utils.table_to_book(document.getElementById('sjs-table'));
      // XLSX.writeFile(wb, "export.xlsx");
    /* HASTA AQUI es codigo para exportar en excel lo que tiene la tabla con cierto id */
      
    /* DESDE AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
      // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
      // const wb: XLSX.WorkBook = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      // XLSX.writeFile(wb, "export.xlsx");
    /* HASTA AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
    

     /* DESDE AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
     const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.data);
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     XLSX.writeFile(wb, "export.xlsx");
   /* HASTA AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */

  }
}
