<div class="shadow p-3 mb-5 bg-body rounded">
        <div style="text-align: center;">
                <span><h3>Historial de antenas</h3></span>
        </div>

        <ng-template #content let-c="close" let-d="dismiss">
                <div class="modal-header">

                        <div style="text-align: center;">
                                <h4 class="modal-title"  >{{histTag[0].marca}} {{histTag[0].tipo}} {{histTag[0].modelo}}</h4>
                                <h4 class="modal-title">Tag : {{histTag[0].numeroTag}}</h4>
                        </div>
                       
                        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">X</button>
                </div>
                
                <div class="modal-body" *ngIf="histTag != null">
                        

                        <table class="table table-bordered">
                                <thead>
                                        <tr>
                                                 <th scope="col">Ubicación</th>
                                                 <th scope="col">Fecha</th>
                                                 <!-- <th scope="col">Tag</th> -->
                                        </tr>
                                </thead>
                                <tbody>
                                        <tr *ngFor="let h of histTag | paginate: { itemsPerPage: 5, currentPage: pageTag } ">
                                                <td>
                                                        {{h.ubicacion}}
                                                </td>
                                                <td>
                                                        {{h.horaFecha | date:'dd-MM-yyyy HH:mm:ss '}}
                                                </td>
                                                <!-- <td>
                                                        {{h.numeroTag}}
                                                </td> -->
                        </table>
                        <pagination-controls (pageChange)="pageTag = $event" previousLabel="Anterior"S nextLabel="Siguiente"></pagination-controls>
                </div>
                <div class="modal-footer">
                        
                </div>
                
        </ng-template>

        

        <form [formGroup]="formFechas">
                
                <div class="row">
                        <div class="col-md-2"></div>
                        <div class="form-group"> 
                                <label class="control-label is-required" for="numInvAlm">Número inventario</label>
                                <input type="text" class="form-control" id="numInvAlm"  maxlength="50" placeholder="Número inventario" formControlName="numInvAlm">
                            </div> 
                        <div class="form-group" style="margin-right: 25px;">
                                <div class="form-group"> 
                                <label for="tipohw" class="control-label">Tipo Hardware</label>
                                <select class="form-control" id="tipohw" formControlName="tipohw">
                                        <option value="" disabled selected>Elige el tipo</option>
                                        <option value="0">Ninguno</option>
                                        <option [value]=tipo.idTipoHw *ngFor="let tipo of tipohw">{{tipo.nombre}}</option>
                                </select>                    
                                </div>
                        </div>
                        <div class="form-group" style="margin-right: 25px;">
                                <div class="form-group"> 
                                <label for="marca" class="control-label">Marca</label>
                                <select class="form-control" id="marca" formControlName="marca">
                                        <option value="" disabled selected>Elige la marca</option>
                                        <option value="0">Ninguno</option>
                                        <option [value]=marca.idMarca *ngFor="let marca of marcahw">{{marca.nombreMarca}}</option>
                                </select>                    
                                </div>
                        </div>
                        <div class="form-group" style="margin-right: 25px;">
                                <div class="form-group"> 
                                <label for="modelo" class="control-label">Modelo</label>
                                <select class="form-control" id="modelo" formControlName="modelo">
                                        <option value="" disabled selected>Elige el modelo</option>
                                        <option value="0">Ninguno</option>
                                        <option [value]=modelo.idHardware *ngFor="let modelo of modelohw">{{modelo.modelo}}</option>
                                </select>                    
                                </div>
                        </div>
                        <div class="form-group" style="margin-right: 25px;">
                                <div class="form-group"> 
                                <label class="control-label" for="fechaInicio">Fecha Inicio</label>
                                <input type="datetime-local" class="form-control" id="fechaInicio" placeholder="Fecha Inicio" formControlName="fini">
                                </div>
                        </div>
                        <div class="form-group" style="margin-right: 25px;">
                                <div class="form-group"> 
                                        <label class="control-label" for="fechaFin">Fecha Fin</label>
                                        <input type="datetime-local" class="form-control" id="fechaFin" placeholder="Fecha Fin" formControlName="ffin">
                                </div>
                        </div>
                </div>
        </form>
        
        <div class="row">
                
                <div class="col-md-12"  style="overflow: auto;" *ngIf="histAntena.length > 0">
                        <div class="col-2" style="text-align:right ;">
                                <button class="btn btn-primary mb-5" (click)="exportExcel()">Generar Excel</button>
                        </div>
                        <table class="table table-bordered" style="width:100%">
                        <thead>
                                <tr>
                                <th style="width:5%">Id </th>
                                <th style="width:15%">Ubicación</th>
                                <th style="width:20%">Fecha y Hora</th>
                                <th style="width:15%">Número inventario</th>
                                <th style="width:20%">Tag</th>
                                <th style="width:30%">Nombre del bien</th>
                                
                                <!-- <th scope="col">Entrada</th>
                                <th scope="col">Salida</th> -->
                                </tr>
                        </thead>
                        <tbody>
                                <tr *ngFor="let hist of histAntena">
                                        <td >
                                                {{hist.idHistorialAntena}}
                                        </td>
                                        <td>
                                                {{hist.ubicacion}}
                                        </td>
                                        <td>
                                                {{hist.horaFecha | date:'dd-MM-yyyy HH:mm:ss '}}
                                        </td>
                                        <td>
                                                {{hist.numeroInvAlm}}
                                        </td>
                                        <td>
                                                {{hist.numeroTag}}
                                        </td>
                                        <td>
                                                <span>{{hist.tipo}} </span><span>{{hist.marca}} </span><span>{{hist.modelo}}</span> 
                                        </td>
                                                <td style="text-align: center;">
                                                <button class="btn btn-primary" (click)="verRuta(hist.numeroTag, content)" data-bs-toggle="modal" data-bs-target="#modalRuta"><i class="feather icon-eye"></i></button>
                                        </td>
        
                        </tr>
                        </tbody>
                        </table>
                        <div class="d-flex justify-content-between p-2">
                                <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" (pageChange)="loadPage($event)" [disabled]="histAntena == null || histAntena.length == 0">
                                  <ng-template ngbPaginationPrevious>Anterior</ng-template>
                                  <ng-template ngbPaginationNext>Siguiente</ng-template>
                                </ngb-pagination>
                            </div>
        
                </div>

        </div>



</div>



