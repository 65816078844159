import { Component, LOCALE_ID, OnInit } from '@angular/core';
import { PdfService } from 'src/app/data/service/pdf-excel/pdf.service';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';

import { environment } from 'src/environments/environment';
import { invSoftware } from 'src/app/model/invSoftware';
import { InventarioService } from 'src/app/data/service/inventario.service';
import Swal from 'sweetalert2';
import { Roles } from 'src/app/model';
@Component({
  selector: 'app-disponible-asignar-sw',
  templateUrl: './disponible-asignar-sw.component.html',
  styleUrls: ['./disponible-asignar-sw.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: "es" }],
})
export class DisponibleAsignarSwComponent implements OnInit {
  //#region PAGINATION
  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;
  //#endregion

  private url: string = environment.api;
  public invSoftware: invSoftware[] = [];
  public date: Date = new Date();
  public data: any = [['Software', 'Dato Auxiliar', 'Temporalidad', 'Vencimiento', 'Stock'],];
  public rolesAzure = Roles;

  constructor(private _pdfService: PdfService, private http: HttpClient, private inventarioService: InventarioService) { }

  ngOnInit() {
    this.muestraDisponibleAsignar()
  }

  muestraDisponibleAsignar() {
    this.invSoftware = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    this.inventarioService.getSwDisponible(this.page).subscribe((respuesta: any) => {
      if (respuesta['result'] === 0) {
        if (!Swal.isVisible()) {
          Swal.fire({
            title: "ERROR",
            text: "No hay coincidencias con tu criterio de búsqueda",
            type: "warning"
          }).then(() => {
            if (this.page != 1) {
              this.page = 1;
              this.muestraDisponibleAsignar();
            } else {
              this.page = 1;
              this.muestraDisponibleAsignar();
            }
          })
        }
      }
      this.invSoftware = respuesta.data;
      this.totalRecords = respuesta.totalRecords;
      this.cargarLoader = false;
    }, error => {
      Swal.fire({
        title: "ERROR",
        text: `Error ${error}`,
        type: "warning"
      })
      this.cargarLoader = false;
    })
  }

  exportExcel() {
    this.inventarioService.getSwDisponible(-1).subscribe((respuesta: any) => {
      /* DESDE AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(respuesta.data);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, "SoftwareDisponibleAsignar-" + this.date.toLocaleDateString() + ".xlsx");
      /* HASTA AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
    }, error => {
      Swal.fire({
        title: "ERROR",
        text: `Error ${error}`,
        type: "warning"
      })
      this.cargarLoader = false;
    })
  }

  async generatePDF() {
    this.inventarioService.getSwDisponible(-1).subscribe((respuesta: any) => {
      const tituloEncabezado = 'Reporte de Software Disponible Para Asignar';
      const FileName = 'SoftwareDisponible' + this.date.toLocaleDateString();
      const embedHTML = document.getElementById('foo');
      const widths = [250, 100, 50, 200, 50];
      this._pdfService.generatePDF(respuesta.data, embedHTML, FileName, widths, tituloEncabezado);
    }, error => {
      Swal.fire({
        title: "ERROR",
        text: `Error ${error}`,
        type: "warning"
      })
      this.cargarLoader = false;
    })

  }

  loadPage(page: number) {
    this.page = page;
    this.muestraDisponibleAsignar();
  }
}
