import { FormGroup } from '@angular/forms';

export class Utils {
  /**
   * Convierte una imagen ubicada en un path local a su representación en formato `dataURL` (base64).
   * El formato de la imagen devuelta será PNG.
   *
   * @param {string} localPath - La ruta local de la imagen.
   * @returns {Promise<string>} - Una promesa que se resuelve con el `dataURL` de la imagen si se carga correctamente,
   *                              o se rechaza si ocurre un error al cargar la imagen.
   *
   * @throws {Error} - Rechaza la promesa si la imagen no se puede cargar.
   */
  static getImageDataUrlFromLocalPath1(localPath: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let canvas = document.createElement('canvas');
      let img = new Image();

      img.onload = () => {
        canvas.height = img.height;
        canvas.width = img.width;
        canvas.getContext('2d').drawImage(img, 0, 0);
        resolve(canvas.toDataURL('image/png')); // Convierte la imagen a formato PNG
      };

      img.onerror = () => reject('Imagen no disponible'); // Maneja el error si la imagen no se carga

      img.src = localPath;
    });
  }

  /**
   * Formatea una fecha en el formato requerido por el input de tipo 'datetime-local'.
   * El formato resultante es 'yyyy-MM-ddTHH:mm'.
   *
   * @param {Date} fecha - La fecha que se quiere formatear.
   * @returns {string} - La fecha formateada en el formato 'yyyy-MM-ddTHH:mm'.
   */
  static formatearFecha(fecha: Date): string {
    return fecha.toISOString().slice(0, 16); // Extrae el formato yyyy-MM-ddTHH:mm
  }

  /**
   * Verifica si un campo de un formulario es válido y ha sido tocado.
   *
   * @param {string} campo - El nombre del campo a validar.
   * @param {FormGroup} form - El grupo de formularios donde se encuentra el campo.
   * @returns {boolean | undefined} - `true` si el campo es válido y ha sido tocado, de lo contrario `false`. Retorna `undefined` si el campo no existe.
   */
  static campoValido(campo: string, form: FormGroup): boolean | undefined {
    return form.get(campo)?.valid && form.get(campo)?.touched;
  }

  /**
   * Verifica si un campo de un formulario es inválido y ha sido tocado.
   *
   * @param {string} campo - El nombre del campo a validar.
   * @param {FormGroup} form - El grupo de formularios donde se encuentra el campo.
   * @returns {boolean | undefined} - `true` si el campo es inválido y ha sido tocado, de lo contrario `false`. Retorna `undefined` si el campo no existe.
   */
  static campoInvalido(campo: string, form: FormGroup): boolean | undefined {
    return form.get(campo)?.invalid && form.get(campo)?.touched;
  }

  /**
   * Pone en mayúscula la primera letra de una cadena de texto.
   *
   * @param {string} value - La cadena de texto a capitalizar.
   * @returns {string} - La cadena de texto con la primera letra en mayúscula.
   */
  static capitalizeFirstLetter(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
