import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators       } from '@angular/forms';
import { HttpClient                               } from '@angular/common/http';
import   Swal                                       from 'sweetalert2';

import { grupoPermiso } from 'src/app/model/grupoPermiso';
import { environment  } from 'src/environments/environment';
import { permiso      } from 'src/app/model/permiso';

@Component({
  selector:     'app-grupo-permiso',
  templateUrl:  './grupo-permiso.component.html',
  styleUrls:   ['./grupo-permiso.component.scss']
})
export class GrupoPermisoComponent implements OnInit {

  private url: string = environment.api;

  public formPermisos : FormGroup;
  public formGrupo : FormGroup;
  public permisos: permiso[] = [];
  public permisosLista: permiso[] = [];
  public grupoPermisos: grupoPermiso[] = [];
  public page : 1;
  
  @ViewChild('txtBuscaPermiso', {static: false}) txtBuscaPermiso!:ElementRef<HTMLInputElement>;
  @ViewChild('txtBuscaGrupoPermiso', {static: false}) txtBuscaGrupoPermiso!:ElementRef<HTMLInputElement>;

  constructor(private http: HttpClient, private formBuilder: FormBuilder){
    this.formPermisos = this.formBuilder.group({
      nombrePermiso: ['',[Validators.required]],
    })
    this.formGrupo = this.formBuilder.group({
      alias: ['',[Validators.required]],
      descripcion: ['',[Validators.required]],
      idPermiso: ['',[Validators.required]],
    })
  }


  ngOnInit() {
    this.buscaGrupoPermisos()
    this.buscaPermisos()
  }

  buscaGrupoPermisos(){
    this.http.get(`${this.url}Usuarios/GruposPermisos/limit/100`).subscribe((respGrupoPermisos:any) =>{
      this.grupoPermisos = respGrupoPermisos.data;
   })
  }

  guardaGrupo(){
      let perms = JSON.stringify(this.formGrupo.value['idPermiso'])
      let resform = this.formGrupo.value
      perms = perms.slice(1, -1);
      resform.idPermiso = perms.toString();
      this.http.post(`${this.url}Usuarios/GruposPermisos`, this.formGrupo.value ).subscribe(data => {
        if (data['result'] === 1) {
          this.page = 1
          this.formGrupo.reset();
          this.buscaGrupoPermisos();
          Swal.fire({
            title: "Guardado",
            text: "Se ha registrado exitosamente",
            type: "success",
          })
        }else{
          Swal.fire({
            title: "ERROR",
            text: data['message'],
            type: "warning",
          })
        }
    })   
  }

  buscaGrupoInput(){
    let valor = this.txtBuscaGrupoPermiso.nativeElement.value;
    if (valor === '') {
      this.page = 1
      this.buscaGrupoPermisos();
    } else {
      let datoPostGrupoPermiso={
          "nombreTabla": "gpermisos",
          "valorBusqueda": valor,
        }
      this.http.post(`${this.url}TablaCampo/`,datoPostGrupoPermiso).subscribe((respPermiso:any) =>{
        if (respPermiso['result'] === 0) {
          Swal.fire({
            title: "ERROR",
            text: "No hay coincidencias con tu criterio de búsqueda",
            type: "warning",
            // timer: 2000
          })
         } else {
          this.page = 1
          this.grupoPermisos = respPermiso.data;
         }
      }) 
    }
  }
  
  cambioBuscaGrupoInput(){
    let valor = this.txtBuscaGrupoPermiso.nativeElement.value;
    if (valor === '') {
      this.page = 1
      this.buscaGrupoPermisos();
    }
  }

  verPermisos(perms){
    this.http.get(`${this.url}Usuarios/Permisos/list?id=`+perms).subscribe((respPerm:any) =>{
      this.permisosLista = respPerm.data;
    })
  }

  buscaPermisos(){
    this.http.get(`${this.url}Usuarios/Permisos/limit/10`).subscribe((respPermisos:any) =>{
      this.permisos = respPermisos.data;
   })
  }

  noSpecialChar(char){
  const onlyspecial = /[°¬_"&@!=¡¿¨´~`',;:.*+\-?^$/{}<>()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
        return false;
    }
    return true
  }
  
}
