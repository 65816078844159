<div class="shadow p-3 mb-5 bg-body rounded">
    <div style="text-align: center;">
        <span><h3>Catálogo de antenas</h3></span>
    </div>
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-5">
            <div class="input-group mb-3">
                <input type="text" class="form-control" (change)="cambioBuscaAntenaInput()" #txtBuscaAntena placeholder="Buscar Antena" aria-label="Buscar Antena" aria-describedby="basic-addon2" (keyup.enter)="buscaAntenaInput()">
                <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button"><i class="feather icon-search" (click)="buscaAntenaInput()"></i></button>
                </div>
            </div>
        </div>
        <div class="col-md-1"
        *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              ]">
            <div class="div-btn-nuevo-hardware">
                <button class="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#modalNvoReceptor">Agregar</button>
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-9">
            <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Nombre antena</th>
                    <th scope="col">IP de receptor</th>
                    <th scope="col">Número de antena</th>
                    <th scope="col"
                    *appHasAnyRole="[
                    rolesAzure.SUPERADMIN, 
                    ]">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let ant of antenas | paginate: { itemsPerPage: 10, currentPage: page } ">
                        <td>
                                {{ant.ubicacion}}
                        </td>
                        <td>
                                {{ant.ipReceptor}}
                        </td>
                        <td>
                                {{ant.puerto}}
                        </td>
                        <td style="text-align: center;"
                        *appHasAnyRole="[
                        rolesAzure.SUPERADMIN, 
                        ]">
                            <button class="btn btn-warning" (click)="abreEditarAntena(ant.idAntena)" data-bs-toggle="modal" data-bs-target="#modalEditaAntena">Editar<i class="feather icon-edit iconos-acciones"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <pagination-controls (pageChange)="page = $event" previousLabel="Anterior"S nextLabel="Siguiente"></pagination-controls> 
        </div>
        <div class="col-md-2"></div>
    </div>
</div>

<div class="modal fade" id="modalNvoReceptor" tabindex="-1" aria-labelledby="modalNvoReceptor" aria-hidden="true" >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="modalNvoReceptor">Nuevo Receptor</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
          </div>
        <div class="modal-body">
            <form [formGroup]="formNvoReceptor" (submit)="nuevoReceptor()">
                <div class="form-group"> 
                    <label class="control-label is-required" for="ubicacionNvo">Ubicación Receptor</label>
                    <input type="text" class="form-control" id="ubicacionNvo" (keypress)="noSpecialChar($event)" maxlength="200" placeholder="Ubicación" formControlName="ubicacionNvo">
                </div> 
                <div class="form-group"> 
                    <label class="control-label is-required" for="ipReceptorNvo">IP Receptor</label>
                    <input type="text" class="form-control" id="ipReceptorNvo" (keypress)="noSpecialChar($event)" maxlength="200" placeholder="192.25.2.1" formControlName="ipReceptorNvo" >
                </div>   
                <div class="form-group"> 
                    <label class="control-label is-required" for="antenaNvo">Antena</label>
                    <input type="text" class="form-control" id="antenaNvo" (keypress)="noSpecialChar($event)" maxlength="200" placeholder="numero de antena" formControlName="antenaNvo">
                </div> 
                <div style="text-align: center;"> 
                    <button type="submit" [disabled]="formNvoReceptor.invalid" data-bs-dismiss="modal" class="btn btn-primary">Guardar</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                </div>     
            </form>
        </div>
        <div class="modal-footer">
            
        </div>
      </div>
    </div>
</div>

<div class="modal fade" id="modalEditaAntena" tabindex="-1" aria-labelledby="modalEditaAntena" aria-hidden="true" >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="modalEditaTipoSw">Editar</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
          </div>
        <div class="modal-body">
            <form [formGroup]="formEditaAntena" (submit)="editaAntena()">
                <input hidden="true" [(ngModel)]="antenaEditar.idAntena"  formControlName="idAntena">
                <div class="form-group"> 
                    <label class="control-label is-required" for="nombreCar">Ubicación Receptor</label>
                    <input type="text" class="form-control" id="nombreCar" (keypress)="noSpecialChar($event)" maxlength="200" placeholder="Ubicación" formControlName="ubicacion" [(ngModel)]="antenaEditar.ubicacion">
                </div> 
                <div class="form-group"> 
                    <label class="control-label is-required" for="ipReceptor">IP Receptor</label>
                    <input type="text" class="form-control" id="ipReceptor" (keypress)="noSpecialChar($event)" maxlength="200" placeholder="192.25.2.1" formControlName="ipReceptor" [(ngModel)]="antenaEditar.ipReceptor">
                </div>   
                <div class="form-group"> 
                    <label class="control-label is-required" for="puerto">Antena</label>
                    <input type="text" class="form-control" id="puerto" (keypress)="noSpecialChar($event)" maxlength="200" placeholder="numero de antena" formControlName="puerto" [(ngModel)]="antenaEditar.puerto">
                </div> 
                <div style="text-align: center;"> 
                    <button type="submit" [disabled]="formEditaAntena.invalid" data-bs-dismiss="modal" class="btn btn-primary">Guardar</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                </div>     
            </form>
        </div>
        <div class="modal-footer">
            
        </div>
      </div>
    </div>
  </div>