import { Injectable } from '@angular/core';

export class ProgressItem {
    val: number;
    text: string;
}
export class Task {
    id: number;
    parentId: number;
    title: string;
    start: Date;
    end: Date;
    progress: number;
    lote: string;
    proveedor: string;
    precioUnitario: number;
    dateDif: number;
}

export class Dependency {
    id: number;
    predecessorId: number;
    successorId: number;
    type: number;
}

export class Resource {
    id: number;
    text: string;
}

export class ResourceAssignment {
    id: number;
    taskId: number;
    resourceId: number;
}

let tasks: Task[] = [];

let dependencies: Dependency[] = [];

let resources: Resource[] = [];

let resourceAssignments: ResourceAssignment[] = [];


@Injectable()
export class Service {
    getTasks(): Task[] {
        return tasks;
    }
    getDependencies(): Dependency[] {
        return dependencies;
    }
    getResources(): Resource[] {
        return resources;
    }
    getResourceAssignments(): ResourceAssignment[] {
        return resourceAssignments;
    }
}
