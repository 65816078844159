import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AntenasResponse } from '../../model/antena';
import { UbicacionResponse } from 'src/app/model';
import { AsignacionResponse, AsignacionResponseResult } from 'src/app/model/asignacion';
import { InvHwResponse } from '../../model/inventarioHw';
import { CaracteristicasResponse } from 'src/app/model/caracteristicas';
import { HardwareResponse } from 'src/app/model/Hardware';
import { SituacionResponse } from 'src/app/model/situacion';
import { InvSwCompartidoResponse, InvSwResponse } from 'src/app/model/invSoftware';
import { DocumentoResponse } from 'src/app/model/documento';
import { MarcasResponse } from 'src/app/model/marcas';
import { SoftwareResponse } from 'src/app/model/Software';
import { FacturasResponse } from 'src/app/model/facturaSoftware';
import { DestinatarioResponse } from 'src/app/model/destinatario';
import { TipoHWResponse } from 'src/app/model/tipoHW';
import { TipoSWResponse } from 'src/app/model/tipoSW';
import   * as CryptoJS                                                        from 'crypto-js';
import { Reply } from 'src/app/model/reply';

const _url = environment.api;
const _microServiceS3UploadUrl = environment.microServiceS3UploadUrl;
const _encryptionKey = 'ak-ir.24-';

@Injectable({
  providedIn: 'root'
})
export class InventarioService {

  constructor( private _http: HttpClient ) { }

   /************************************* FUNCIONES DE ANTENAS *************************************/

   public getAntenas( limit: number ): Observable<AntenasResponse>{
    //return this._http.get<AntenasResponse>( `${_url}Antenas/limit/${limit}` );
    return this._http.get<AntenasResponse>( `${_url}Antenas` );
  }

  public createAntena( item: any ): Observable<AntenasResponse>{
    //return this._http.post<AntenasResponse>( `${_url}Antenas`, item );
    return this._http.post<AntenasResponse>( `${_url}antenas/`, item );
  }

  public getReceptor( limit: number ): Observable<AntenasResponse>{
    //return this._http.get<AntenasResponse>( `${_url}Antenas/limit/${limit}` );
    return this._http.get<AntenasResponse>( `${_url}Receptor` );
  }

  
  public getAntena(datoPost : any): Observable<InvHwResponse>{
    return this._http.post<InvHwResponse>( `${_url}Receptor/busqueda/`,datoPost);
  }
  

  public getAntenasTablaCampo( datoPostAntena: any ): Observable<AntenasResponse>{
    return this._http.post<AntenasResponse>( `${_url}TablaCampo/`, datoPostAntena );
  }

  public getHistorialAntena(): Observable<InvHwResponse>{
    return this._http.get<InvHwResponse>(`${_url}historial-antenas`);
  }

  public createReceptor( item: any ): Observable<AntenasResponse>{
    //return this._http.post<AntenasResponse>( `${_url}Antenas`, item );
    return this._http.post<AntenasResponse>( `${_url}Receptor/`, item );
  }

  public updateAntenas( item: FormData ): Observable<AntenasResponse>{
    //return this._http.put<AntenasResponse>( `${_url}Antenas/unica`, item );
    return this._http.put<AntenasResponse>( `${_url}Antenas/unica`, item );
  }

  public getReceptorxId( idReceptor: number ): Observable<AntenasResponse>{
    //return this._http.get<AntenasResponse>( `${_url}Antenas/${idAntena}` );
    return this._http.get<AntenasResponse>( `${_url}Receptor/${idReceptor}` );
  }

  
  public getAntenaxId( idAntena: number ): Observable<AntenasResponse>{
    //return this._http.get<AntenasResponse>( `${_url}Antenas/${idAntena}` );
    return this._http.get<AntenasResponse>( `${_url}Antenas/${idAntena}` );
  }

   /************************************* FUNCIONES DE UBICACIONES *************************************/
  public getUbicaciones(): Observable<UbicacionResponse>{
    return this._http.get<UbicacionResponse>( `${_url}Ubicaciones` );
  }
  
  public getUbicacionesLimit( limit: number ): Observable<UbicacionResponse>{
    return this._http.get<UbicacionResponse>( `${_url}Ubicaciones/limit/${limit}` );
  }
  
  public getUbicacionesTablaCampo( datoPost ): Observable<UbicacionResponse>{
    return this._http.post<UbicacionResponse>( `${_url}TablaCampo/`,datoPost );
  }
  
  public createUbicacion( datoPost ): Observable<UbicacionResponse>{
    return this._http.post<UbicacionResponse>( `${_url}Ubicaciones`,datoPost );
  }
  
  public updateUbicacion( datoPost ): Observable<UbicacionResponse>{
    return this._http.put<UbicacionResponse>( `${_url}Ubicaciones/unica`,datoPost );
  }

  public getUbicacionxId( idUbicacion: number ): Observable<UbicacionResponse>{
    return this._http.get<UbicacionResponse>( `${_url}Ubicaciones/${idUbicacion}` );
  }
  

   /************************************* FUNCIONES DE ASIGNACIONES DE HARDWARE*************************************/
  public getAsignacionesHw( datos: any ): Observable<AsignacionResponse>{
    return this._http.post<AsignacionResponse>( `${_url}Reportes/AsignacionUbicacionHW`, datos);
  }

  public createAsignacionHw (datoPost: any ): Observable<AsignacionResponseResult>{
    return this._http.post<AsignacionResponseResult>( `${_url}Asignaciones/hw`, datoPost );
  }

  public asignacionHwTablaCampoHw(datoPost : any): Observable<AsignacionResponse>{
    return this._http.post<AsignacionResponse>( `${_url}TablaCampo/`,datoPost);
  }

  public bajaAsignacionHw(asignacionBaja : any): Observable<AsignacionResponse>{
    return this._http.post<AsignacionResponse>( `${_url}Asignaciones/Baja/hw`, asignacionBaja);
  }

   /************************************* FUNCIONES DE ASIGNACIONES DE SOFTWARE*************************************/
  public getAsignacionesSw( datos: any ): Observable<AsignacionResponse>{
    return this._http.post<AsignacionResponse>( `${_url}Reportes/AsignacionUbicacionSW`, datos);
  }
  
  public getAsignacionesSwComp( datos: any ): Observable<AsignacionResponse>{//trae todas las asignaciones de sw que no se comparta su sw
    return this._http.post<AsignacionResponse>( `${_url}Reportes/AsignacionUbicacionSWComp/`, datos);
  }

  public createAsignacionSw (datoPost: any ): Observable<AsignacionResponseResult>{
    return this._http.post<AsignacionResponseResult>( `${_url}Asignaciones/sw`, datoPost );
  }

  public reAsignacionSw (datoPost: any ): Observable<AsignacionResponseResult>{
    return this._http.post<AsignacionResponseResult>( `${_url}Asignaciones/sw`, datoPost );
  }

  public asignacionSwTablaCampo(datoPost : any): Observable<AsignacionResponse>{
    return this._http.post<AsignacionResponse>( `${_url}TablaCampo/`,datoPost);
  }

  public bajaAsignacionSw(asignacionBaja : any): Observable<AsignacionResponse>{
    return this._http.post<AsignacionResponse>( `${_url}Asignaciones/Baja/sw`, asignacionBaja);
  }

  /************************************* FUNCIONES DE INVENTARIO DE HARDWARE *************************************/
 
  public getInventarioHwLimit( limite: any ): Observable<InvHwResponse>{
    return this._http.get<InvHwResponse>( `${_url}InventariosHW/limit/${limite}`);
  }
 
  public getInventarioHwPagination( page: any ): Observable<InvHwResponse>{
    return this._http.get<InvHwResponse>( `${_url}InventariosHW/pagination/${page}`);
  }

  public getInvHwXId(idInventarioHw : any): Observable<InvHwResponse>{
    return this._http.get<InvHwResponse>( `${_url}InventariosHW/`+idInventarioHw);
  }

  public getUrlsFirmadas(numeroInventario : number): Observable<any>{
    return this._http.get<any>( `${_url}InventariosHW/GeneratePreSignedURLs/`+numeroInventario);
  }

  public getInvHwCadena(idInventarioHw : any): Observable<InvHwResponse>{//Nos trae los datos del inv para mostrar el elegido
    return this._http.get<InvHwResponse>( `${_url}InventariosHW/cadena/`+idInventarioHw);
  }

  public getInvHwExisteNoSerie(noSerie : any): Observable<InvHwResponse>{//Nos dice si el numero de serie existe
    return this._http.get<InvHwResponse>( `${_url}InventariosHW/numeroSerie/`+noSerie);
  }

  public getInvHwExisteNoInv(noInv : any): Observable<InvHwResponse>{//Nos dice si el numero de serie existe
    return this._http.get<InvHwResponse>( `${_url}InventariosHW/numeroInvAlm/`+noInv);
  }

  public getInvHwTablaCampo(datoPost : any): Observable<InvHwResponse>{
    return this._http.post<InvHwResponse>( `${_url}TablaCampo/`,datoPost);
  }
  
  public bajaInvHw(invHwBaja : any): Observable<InvHwResponse>{
    return this._http.put<InvHwResponse>( `${_url}InventariosHW/delete`, invHwBaja);
  }

  public editInvHw(invHwEdit : any): Observable<InvHwResponse>{
    return this._http.put<InvHwResponse>( `${_url}InventariosHW/`, invHwEdit);
  }
  

  /************************************* FUNCIONES DE INVENTARIO DE SOFTWARE *************************************/
  public getInventarioSwLimit( page: any ): Observable<InvSwResponse>{
    return this._http.get<InvSwResponse>( `${_url}InventariosSW/page/`+page);
  }
 
  public getInvSwXId(idInventarioSw : any): Observable<InvSwResponse>{
    return this._http.get<InvSwResponse>( `${_url}InventariosSW/`+idInventarioSw);
  }

  public getInvSwTablaCampo(datoPost : any): Observable<InvSwResponse>{
    return this._http.post<InvSwResponse>( `${_url}TablaCampo/`,datoPost);
  }

  public bajaInvSw(invSwBaja : any): Observable<InvSwResponse>{
    return this._http.put<InvSwResponse>( `${_url}InventariosSW/delete`, invSwBaja);
  }

  public editInvSw(invSwEdit : any): Observable<InvSwResponse>{
    return this._http.put<InvSwResponse>( `${_url}InventariosSW/`, invSwEdit);
  }
  

   /************************************* FUNCIONES DE DESTINATARIOS *************************************/
  public getDestinatarios(limit: number): Observable<DestinatarioResponse>{
    return this._http.get<DestinatarioResponse>( `${_url}NotificacionLicencias/limit/${limit}`);
  }

  public updateDestinatario( datoPost: any ): Observable<DestinatarioResponse>{ 
    return this._http.put<DestinatarioResponse>( `${_url}NotificacionLicencias/unica`,datoPost);
  }
  
  public createDestinatario( datoPost: any ): Observable<DestinatarioResponse>{ 
    return this._http.post<DestinatarioResponse>( `${_url}NotificacionLicencias/`,datoPost);
  }
  
  public deleteDestinatario( idDestino: any ): Observable<DestinatarioResponse>{ 
    return this._http.delete<DestinatarioResponse>( `${_url}NotificacionLicencias/`+idDestino);
  }
  
  public getDestinatarioxId( idEmpleado ): Observable<DestinatarioResponse>{ 
    return this._http.get<DestinatarioResponse>( `${_url}NotificacionLicencias/`+idEmpleado);
  }
  

   /************************************* FUNCIONES DE CARACTERISTICAS *************************************/
  public getCaracteristicas(): Observable<CaracteristicasResponse>{
    return this._http.get<CaracteristicasResponse>( `${_url}Caracteristicas`);
  }
  
  public getCaracteristicasLimit( limite: any ): Observable<CaracteristicasResponse>{
    return this._http.get<CaracteristicasResponse>( `${_url}Caracteristicas/limit/`+limite);
  }

  public getCaracteristicasXInv( idInvHw: any ): Observable<CaracteristicasResponse>{ //Caracteristicas de un inventario de hw especifico
    return this._http.get<CaracteristicasResponse>( `${_url}Caracteristicas/list?idInventarioHw=`+idInvHw);
  }
  

  public getCaracteristicasXId( idCar: any ): Observable<CaracteristicasResponse>{ //Caracteristicas de un inventario de hw especifico
    return this._http.get<CaracteristicasResponse>( `${_url}Caracteristicas/`+idCar);
  }
  
  public getCaracteristicasXTipoHw( idInvHw: any ): Observable<CaracteristicasResponse>{ //Caracteristicas de un inventario de hw especifico
    return this._http.get<CaracteristicasResponse>( `${_url}Caracteristicas/TipoHW?idTipoHw=`+idInvHw);
  }
  
  public getCaracteristicasTablaCampo( datoPost: any ): Observable<CaracteristicasResponse>{ //Caracteristicas de un inventario de hw especifico
    return this._http.post<CaracteristicasResponse>( `${_url}TablaCampo/`,datoPost);
  }
  
  public saveCaracteristicasOnInvHw( datoPost: any ): Observable<CaracteristicasResponse>{ //Caracteristicas de un inventario de hw especifico
    return this._http.put<CaracteristicasResponse>( `${_url}Caracteristicas/`,datoPost);
  }
  
  public createCaracteristica( datoPost: any ): Observable<CaracteristicasResponse>{ //Caracteristicas de un inventario de hw especifico
    return this._http.post<CaracteristicasResponse>( `${_url}Caracteristicas/`,datoPost);
  }
  
  public updateCaracteristica( datoPost: any ): Observable<CaracteristicasResponse>{ //Caracteristicas de un inventario de hw especifico
    return this._http.put<CaracteristicasResponse>( `${_url}Caracteristicas/unica`,datoPost);
  }
  
   /************************************* FUNCIONES DE CATALOGO DE HARDWARE *************************************/
  public getHardware(): Observable<HardwareResponse>{
    return this._http.get<HardwareResponse>( `${_url}Hardware`);
  }
 
  public getHardwareLimit(limit): Observable<HardwareResponse>{
    return this._http.get<HardwareResponse>( `${_url}Hardware/limit/`+limit);
  }
  
  public getHardwareXTipoHw(idTipo): Observable<HardwareResponse>{
    return this._http.get<HardwareResponse>( `${_url}Hardware/TipoHw/`+idTipo);
  }
  
  public getHardwareXIdHw(idHardware): Observable<HardwareResponse>{
    return this._http.get<HardwareResponse>( `${_url}Hardware/`+idHardware);
  }
 
  public getHardwareTablaCampo(datoPost): Observable<HardwareResponse>{
    return this._http.post<HardwareResponse>( `${_url}TablaCampo/`,datoPost);
  }

  public createHardware(datoPost): Observable<HardwareResponse>{
    return this._http.post<HardwareResponse>( `${_url}Hardware/`,datoPost);
  }

  public updateHardware(datoPost): Observable<HardwareResponse>{
    return this._http.put<HardwareResponse>( `${_url}Hardware/unica`,datoPost);
  }
  

   /************************************* FUNCIONES DE CATALOGO DE TIPO DE HARDWARE *************************************/
  public getTipoHardware(): Observable<TipoHWResponse>{
    return this._http.get<TipoHWResponse>( `${_url}TipoHW`);
  }
  
  public getTipoHardwareLimit(limit): Observable<TipoHWResponse>{
    return this._http.get<TipoHWResponse>( `${_url}TipoHW/limit/`+limit);
  }

  public getTipoHardwareTablaCampo(datoPost): Observable<TipoHWResponse>{
    return this._http.post<TipoHWResponse>( `${_url}TablaCampo/`,datoPost);
  }

  public createTipoHardware(datoPost): Observable<TipoHWResponse>{
    return this._http.post<TipoHWResponse>( `${_url}TipoHW/`,datoPost);
  }

  public updateTipoHardware(datoPost): Observable<TipoHWResponse>{
    return this._http.put<TipoHWResponse>( `${_url}TipoHW/unica`,datoPost);
  }

  public getTipoHardwarexId(idTipo): Observable<TipoHWResponse>{
    return this._http.get<TipoHWResponse>( `${_url}TipoHW/`+idTipo);
  }


   /************************************* FUNCIONES DE CATALOGO DE TIPO DE SOFTWARE *************************************/
  
   public getTipoSoftware(): Observable<TipoSWResponse>{
    return this._http.get<TipoSWResponse>( `${_url}TipoSW`);
  }
  
  public getTipoSoftwareLimit(limit): Observable<TipoSWResponse>{
    return this._http.get<TipoSWResponse>( `${_url}TipoSW/limit/`+limit);
  }

  public getTipoSoftwareTablaCampo(datoPost): Observable<TipoSWResponse>{
    return this._http.post<TipoSWResponse>( `${_url}TablaCampo/`,datoPost);
  }

  public createTipoSoftware(datoPost): Observable<TipoSWResponse>{
    return this._http.post<TipoSWResponse>( `${_url}TipoSW/`,datoPost);
  }  

  public updateTipoSoftware(datoPost): Observable<TipoSWResponse>{
    return this._http.put<SoftwareResponse>( `${_url}TipoSW/unico`,datoPost);
  }
  
  public getTipoSoftwarexId(idTipoSoftware): Observable<TipoSWResponse>{
    return this._http.get<TipoSWResponse>( `${_url}TipoSW/`+idTipoSoftware);
  }
  
   /************************************* FUNCIONES DE CATALOGO DE SOFTWARE *************************************/
  public getSoftware(): Observable<SoftwareResponse>{
    return this._http.get<SoftwareResponse>( `${_url}Software`);
  }
  
  public getSoftwareLimit(limit): Observable<SoftwareResponse>{
    return this._http.get<SoftwareResponse>( `${_url}Software/limit/`+limit);
  }
  
  public getSoftwareTablaCampo(datoPost): Observable<SoftwareResponse>{
    return this._http.post<SoftwareResponse>( `${_url}TablaCampo/`,datoPost);
  }

  public createSoftware(datoPost): Observable<SoftwareResponse>{
    return this._http.post<SoftwareResponse>( `${_url}Software/`,datoPost);
  }
 
  public updateSoftware(datoPost): Observable<SoftwareResponse>{
    return this._http.put<SoftwareResponse>( `${_url}Software/unico`,datoPost);
  }

  public getSoftwarexId(idSoftware): Observable<SoftwareResponse>{
    return this._http.get<SoftwareResponse>( `${_url}Software/`+idSoftware);
  }


   /************************************* FUNCIONES DE SITUACIONES *************************************/
  public getSituaciones(): Observable<SituacionResponse>{
    return this._http.get<SituacionResponse>( `${_url}Situaciones`);
  }
  
  public getSituacionesLimit(limite): Observable<SituacionResponse>{
    return this._http.get<SituacionResponse>( `${_url}Situaciones/limit/`+limite);
  }
  
  public getSituacionesTablaCampo(datoPost): Observable<SituacionResponse>{
    return this._http.post<SituacionResponse>( `${_url}TablaCampo/`,datoPost);
  }
  
  public createSituaciones(datoPost): Observable<SituacionResponse>{
    return this._http.post<SituacionResponse>( `${_url}Situaciones`,datoPost);
  }
  
  public getSituacionxId(idSituacion): Observable<SituacionResponse>{
    return this._http.get<SituacionResponse>( `${_url}Situaciones/`+idSituacion);
  }
  
  public updateSituaciones(datoPost): Observable<SituacionResponse>{
    return this._http.put<SituacionResponse>( `${_url}Situaciones/unica`,datoPost);
  }
  
   /************************************* FUNCIONES DE MARCAS *************************************/
  public getMarcas(): Observable<MarcasResponse>{
    return this._http.get<MarcasResponse>( `${_url}Marcas`);
  }
  
  public getMarcasLimit(limit): Observable<MarcasResponse>{
    return this._http.get<MarcasResponse>( `${_url}Marcas/limit/`+limit);
  }
  
  public getMarcasTablaCampo(datoPost): Observable<MarcasResponse>{
    return this._http.post<MarcasResponse>( `${_url}TablaCampo/`,datoPost);
  }
  
  public createMarca(datoPost): Observable<MarcasResponse>{
    return this._http.post<MarcasResponse>( `${_url}Marcas/`,datoPost);
  }
  
  public updateMarca(datoPost): Observable<MarcasResponse>{
    return this._http.put<MarcasResponse>( `${_url}Marcas/unica`,datoPost);
  }
  
  public getMarcasxId(idMarca): Observable<MarcasResponse>{
    return this._http.get<MarcasResponse>( `${_url}Marcas/`+idMarca);
  }
  
   /************************************* FUNCIONES DE SOFTWARE COMPARTIDO *************************************/
  public getSwCompartido(idAsignacionSw): Observable<InvSwCompartidoResponse>{
    return this._http.get<InvSwCompartidoResponse>( `${_url}SwCompartidos/SwCompartido/`+idAsignacionSw);
  }
  
  public quitarSwCompartido(idSwCompartido): Observable<InvSwCompartidoResponse>{
    return this._http.delete<InvSwCompartidoResponse>( `${_url}SwCompartidos/SwCompartido/`+idSwCompartido);
  }
  
  public compartirSw(datosCompartir): Observable<InvSwCompartidoResponse>{
    return this._http.post<InvSwCompartidoResponse>( `${_url}SwCompartidos/SwCompartido`, datosCompartir);
  }
  

   /************************************* FUNCIONES DE DOCUMENTOS *************************************/
  public getDocumentosLimit(limite): Observable<DocumentoResponse>{
    return this._http.get<DocumentoResponse>( `${_url}tiposDocumentos/limit/`+limite);
  }

  public getListDocumentosAsignacion(idAsignacion): Observable<DocumentoResponse>{
    return this._http.get<DocumentoResponse>( `${_url}Documentos/asignacion/list?folioAsignacion=`+idAsignacion);
  }
  

  public crearDocumentoHandheld(folioAsignacion): Observable<DocumentoResponse>{
    
    
    return this._http.get<DocumentoResponse>( `${_url}Asignaciones/createDocument?folioAsignacion=`+folioAsignacion);
  }
  
  public getListDocumentosFactura(idFactura): Observable<DocumentoResponse>{
    return this._http.get<DocumentoResponse>( `${_url}Documentos/factura/list?idFactura=`+idFactura);
  }
  
  public getTipoDocumentos(tipo): Observable<DocumentoResponse>{
    return this._http.get<DocumentoResponse>( `${_url}Documentos/`+tipo);
  }

  public getTipoDocumentosxId(idTipoDoc): Observable<DocumentoResponse>{
    return this._http.get<DocumentoResponse>( `${_url}TiposDocumentos/`+idTipoDoc);
  }
  
  public saveDocumento(datoPost): Observable<DocumentoResponse>{
    return this._http.post<DocumentoResponse>( `${_url}Documentos`,datoPost);
  }
  
  public createDocumento(datoPost): Observable<DocumentoResponse>{//guarda el tipo de documentos en el catalogo
    return this._http.post<DocumentoResponse>( `${_url}TiposDocumentos`,datoPost);
  }
  
  public updateDocumento(datoPost): Observable<DocumentoResponse>{//guarda el tipo de documentos en el catalogo
    return this._http.put<DocumentoResponse>( `${_url}TiposDocumentos/unico/`,datoPost);
  }

  public getDocumentosTablaCampo( datoPost: any ): Observable<DocumentoResponse>{ //Caracteristicas de un inventario de hw especifico
    return this._http.post<DocumentoResponse>( `${_url}TablaCampo/`,datoPost);
  }
  
  
   /************************************* FUNCIONES DE FACTURAS *************************************/
  public getFacturas(page: number): Observable<FacturasResponse>{
    return this._http.get<FacturasResponse>( `${_url}Facturas/HWpaginacion/${page}`);
  }
   
  public getFacturasSw(idFactura): Observable<FacturasResponse>{
    return this._http.get<FacturasResponse>( `${_url}Facturas/SW/`+idFactura);
  }
   
  public getFacturasxFolio(folioFactura): Observable<FacturasResponse>{
    return this._http.get<FacturasResponse>( `${_url}Facturas/existeFactura/`+folioFactura);
  }

   public getDetalleHwFacturas(factura): Observable<InvSwResponse>{
    return this._http.get<InvSwResponse>( `${_url}Facturas/detailHw/`+factura);
  }
  
   public getDetalleSwFacturas(factura): Observable<InvSwResponse>{
    return this._http.get<InvSwResponse>( `${_url}Facturas/detailSw/`+factura);
  }
  
   public getDatosDetalleHwFacturas(datoPost): Observable<InvSwResponse>{
    return this._http.post<InvSwResponse>( `${_url}Facturas/datadetailHw`,datoPost);
  }

  public createFactura(datoPost): Observable<FacturasResponse>{
    return this._http.post<FacturasResponse>( `${_url}Facturas`,datoPost);
  }

  public editaFactura(datoPost): Observable<FacturasResponse>{
    return this._http.put<FacturasResponse>( `${_url}Facturas`,datoPost);
  }  

  public bajaFactura(datoPost): Observable<FacturasResponse>{
    return this._http.put<FacturasResponse>( `${_url}Facturas/delete`,datoPost);
  }
  
  public getFacturaTablaCampo(datoPost): Observable<FacturasResponse>{
    return this._http.post<FacturasResponse>( `${_url}TablaCampo/`,datoPost);
  }
  
   /************************************* FUNCIONES DE REPORTES *************************************/
   public getHwDisponible(limite): Observable<InvHwResponse>{//Regresa el inventario hw disponible para asignar
    return this._http.get<InvHwResponse>( `${_url}Reportes/DisponibleAsignacionHW/`+limite);
  }

   public getSwDisponible(limite): Observable<InvSwResponse>{
    return this._http.get<InvSwResponse>( `${_url}Reportes/DisponibleAsignacionSW/`+limite);
  }

   public getHistorialAsignHw(datos): Observable<InvHwResponse>{
    return this._http.post<InvHwResponse>( `${_url}Reportes/historialAsignacionHW/`,datos);
  }
  
  public getHistorialAsignSw(datos): Observable<InvSwResponse>{
    return this._http.post<InvSwResponse>( `${_url}Reportes/historialAsignacionSW/`,datos);
  }
  
  public getAsignacionUbicacionHw(datos): Observable<InvHwResponse>{
    return this._http.post<InvHwResponse>( `${_url}Reportes/AsignacionUbicacionHW/`,datos);
  }
  
  public getAsignacionUbicacionSw(datos): Observable<InvSwResponse>{
    return this._http.post<InvSwResponse>( `${_url}Reportes/AsignacionUbicacionSW/`,datos);
  }
  
  public getBajaInvHw(datos): Observable<InvSwResponse>{
    return this._http.post<InvSwResponse>( `${_url}Reportes/InventarioHBaja/`,datos);
  }
  
  public getReporteCarsHw(datos): Observable<InvHwResponse>{
    return this._http.post<InvHwResponse>( `${_url}Reportes/Caracteristicas/`,datos);
  }
  
  public getReporteInvHw(limite): Observable<InvHwResponse>{
    return this._http.get<InvHwResponse>( `${_url}Reportes/InventarioH/`+limite);
  }
  
  public getReporteInvsw(limite): Observable<InvSwResponse>{
    return this._http.get<InvSwResponse>( `${_url}Reportes/InventarioS/`+limite);
  }
 
  public getReporteValorInvHw(datos): Observable<InvHwResponse>{
    return this._http.post<InvHwResponse>( `${_url}Reportes/ValorInventarioH/`,datos);
  }
 
  public getReporteValorInvsw(datos): Observable<InvSwResponse>{
    return this._http.post<InvSwResponse>( `${_url}Reportes/ValorInventarioS/`,datos);
  }
 
  public getReporteVenciminetoLicencias(datos): Observable<InvSwResponse>{
    return this._http.post<InvSwResponse>( `${_url}Reportes/VencimientoLicencias/`,datos);
  }

  public getHistorialAntenasFiltro(datos, page): Observable<InvSwResponse>{
    
    
    return this._http.post<InvSwResponse>( `${_url}Antenas/historial-antenas-filtro/${page}`,datos);
  }
  public getHistorialAntenasReporte(datos, page): Observable<InvSwResponse>{
    
    
    return this._http.post<InvSwResponse>( `${_url}Antenas/historial-antenas-filtro`,datos);
  }

  public getHistorialInd(datos): Observable<InvSwResponse>{
    
    
    return this._http.post<InvSwResponse>( `${_url}Antenas/historial-antena-filtro/`,datos);
  }
  
  private getHeaders(): HttpHeaders {
    const encrypted = localStorage.getItem('akuhandler');
    let apiKey = "";
    if (encrypted) {
      const bytes = CryptoJS.AES.decrypt(encrypted, _encryptionKey);
      apiKey = bytes.toString(CryptoJS.enc.Utf8);
    }
    return new HttpHeaders({
      'x-api-key': apiKey
    });
  }

  // Genera una URL pre-firmada para la carga de archivos a S3.
  getUrlSignedToUploadFiles(data: any) {
    const encrypted = localStorage.getItem('akuhandler');
    let apiKey = "";
    if (encrypted) {
      const bytes = CryptoJS.AES.decrypt(encrypted, _encryptionKey);
      apiKey = bytes.toString(CryptoJS.enc.Utf8);
    }
    const headers = new HttpHeaders({
      'X-Api-Key': apiKey,
      'Content-Type': 'application/json'
    });
    return this._http.post<{ body: string }>(`${_microServiceS3UploadUrl}`, data,  { headers });
  }

  uploadFileToS3(url: string, file: File) {
    return this._http.put(url, file, {
      headers: {
        'Content-Type': file.type
      },
      reportProgress: true,
      observe: 'events'
    });
  }

  public DeleteFileFromS3(numeroInventario : string, nombreImagen : string): Observable<Reply>{
    return this._http.delete<Reply>( `${_url}InventariosHW/DeleteFileFromS3/${numeroInventario}/${nombreImagen}`);
  }
}
