import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, LOCALE_ID, OnInit } from '@angular/core';
import { registerLocaleData } from "@angular/common";
import { invHardware } from 'src/app/model/invHArdware';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
import localeEs from "@angular/common/locales/es";
import Swal from 'sweetalert2';
import { PdfService } from 'src/app/data/service/pdf-excel/pdf.service';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { Roles } from 'src/app/model';

registerLocaleData(localeEs, 'es');

@Component({
  selector: 'app-reporte-valor-inventario-hw',
  templateUrl: './reporte-valor-inventario-hw.component.html',
  styleUrls: ['./reporte-valor-inventario-hw.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: "es" }],
})
export class ReporteValorInventarioHwComponent implements OnInit {
  public invHardware: invHardware[] = [];
  public tipohw: any = [];
  public ubicacion: any = [];
  public formFechas: FormGroup;

  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;

  date = new Date().toISOString().slice(0, 10);
  public dateTable: Date = new Date();

  public rolesAzure = Roles;

  constructor(private _pdfService: PdfService, private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService) {
    this.formFechas = this.formBuilder.group({
      fini: ['', [Validators.required]],
      ffin: ['', [Validators.required]],
      tipohw: ['', [Validators.required]],
      ubicacion: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.muestraTipoHw()
    this.muestraUbicaciones()
  }

  mostrarReporte() {
    this.invHardware = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    let tipo
    let ubi
    if (this.formFechas.value['tipohw'] == '') tipo = 0
    else tipo = this.formFechas.value['tipohw']

    if (this.formFechas.value['ubicacion'] == '') ubi = 0
    else ubi = this.formFechas.value['ubicacion']

    let lotefac = {
      "fini": this.formFechas.value['fini'],
      "ffin": this.formFechas.value['ffin'],
      "tipoHw": tipo,
      "idUbicacion": ubi,
      "limit": 0,
      "Page": this.page
    }
    this.inventarioService.getReporteValorInvHw(lotefac).subscribe((respuesta: any) => {
      if (respuesta.data != null && respuesta.data.length > 0) {
        this.invHardware = respuesta.data;
        this.totalRecords = respuesta.totalRecords;
        this.cargarLoader = false;
      }
      else this.funcionErrorPaginacion();
    })
  }

  muestraTipoHw() {
    this.inventarioService.getTipoHardware().subscribe((respHardware: any) => {
      this.tipohw = respHardware.data;
    })
  }

  muestraUbicaciones() {
    this.inventarioService.getUbicaciones().subscribe((respHardware: any) => {
      this.ubicacion = respHardware.data;
    })
  }

  exportExcel() {
    let tipo
    let ubi
    if (this.formFechas.value['tipohw'] == '') tipo = 0
    else tipo = this.formFechas.value['tipohw']

    if (this.formFechas.value['ubicacion'] == '') ubi = 0
    else ubi = this.formFechas.value['ubicacion']

    let lotefac = {
      "fini": this.formFechas.value['fini'],
      "ffin": this.formFechas.value['ffin'],
      "tipoHw": tipo,
      "idUbicacion": ubi,
      "limit": 0,
      "Page": -1
    }
    this.inventarioService.getReporteValorInvHw(lotefac).subscribe((respuesta: any) => {
      if (respuesta['result'] == 1) {
        /* DESDE AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.invHardware);
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(respuesta.data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, "ReporteValorInventarioHardware-" + this.dateTable.toLocaleDateString() + ".xlsx");
        /* HASTA AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
      } else {
        Swal.fire({
          title: "Advertencia",
          text: respuesta['message'],
          type: "warning",
          // timer: 2000
        })
      }
    })
  }

  async generatePDF() {
    let tipo
    let ubi
    if (this.formFechas.value['tipohw'] == '') tipo = 0
    else tipo = this.formFechas.value['tipohw']

    if (this.formFechas.value['ubicacion'] == '') ubi = 0
    else ubi = this.formFechas.value['ubicacion']

    let lotefac = {
      "fini": this.formFechas.value['fini'],
      "ffin": this.formFechas.value['ffin'],
      "tipoHw": tipo,
      "idUbicacion": ubi,
      "limit": 0,
      "Page": -1
    }
    this.inventarioService.getReporteValorInvHw(lotefac).subscribe((respuesta: any) => {
      if (respuesta['result'] == 1) {
        const tituloEncabezado = 'Reporte de Valor de Inventario de Hardware';
        const FileName = 'ValorInventarioHardware' + this.dateTable.toLocaleDateString();
        const embedHTML = document.getElementById('foo');
        const widths = '*';
        this._pdfService.generatePDF(respuesta.data, embedHTML, FileName, widths, tituloEncabezado);
      } else {
        Swal.fire({
          title: "Advertencia",
          text: respuesta['message'],
          type: "warning",
          // timer: 2000
        })
      }
    })
  }

  loadPage(page: number) {
    this.page = page;
    this.mostrarReporte();
  }

  funcionErrorPaginacion() {
    if (!Swal.isVisible()) {
      Swal.fire({
        title: "ERROR",
        text: "No hay coincidencias con tu criterio de búsqueda",
        type: "warning"
      }).then(() => {
        if (this.page != 1) {
          this.page = 1;
          this.mostrarReporte();
        } else {
          this.page = 1;
          this.mostrarReporte();
        }
      })
    }
  }
}
