
    <div class="shadow p-3 mb-5 bg-body rounded">
        <div style="text-align: center;">
            <span><h3>Catálogo Ubicaciones</h3></span>
        </div>
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-5">
                <div class="input-group mb-3">
                    <input type="text" class="form-control" (change)="cambioBuscaUbicacionesInput()" #txtBuscaUbicaciones placeholder="Buscar Ubicaciones" aria-label="Buscar Ubicaciones" aria-describedby="basic-addon2" (keyup.enter)="buscarUbicaciones()">
                    <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button"><i class="feather icon-search" (click)="buscarUbicaciones()"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-md-4"></div>
        </div>
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-5"
            *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
                <form [formGroup]="formUbicaciones" (submit)="guardaUbicaciones()">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" (keypress)="noSpecialChar($event)" placeholder="Ingresar Ubicacion*" maxlength="255" aria-label="Ingresar Ubicacion" aria-describedby="basic-addon2"  formControlName="nombreUbicacion">
                        <div class="input-group-append">
                            <button class="btn btn-primary" [disabled]="formUbicaciones.invalid">Agregar</button>
                        </div>
                    </div>
                </form> 
                
            </div>
                <div class="col-md-4"></div>
        </div>

        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-9" *ngIf="!cargarLoader">
                <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Nombre Ubicación</th>
                        <th scope="col"
                        *appHasAnyRole="[
                        rolesAzure.SUPERADMIN, 
                        rolesAzure.SUPERVISOR, 
                        rolesAzure.ING_SOPORTE
                        ]">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ubi of ubicacion">
                            <td>
                                    {{ubi.nombreUbicacion}}
                            </td>
                            <td style="text-align: center;"
                            *appHasAnyRole="[
                            rolesAzure.SUPERADMIN, 
                            rolesAzure.SUPERVISOR, 
                            rolesAzure.ING_SOPORTE
                            ]">
                                <button class="btn btn-warning" (click)="abreEditarUbicaciones(ubi.idUbicacion)" data-bs-toggle="modal" data-bs-target="#modalEditaUbicacion">Editar<i class="feather icon-edit iconos-acciones"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-start align-items-center p-2">
                    <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" (pageChange)="loadPage($event)" [disabled]="ubicacion == null || ubicacion.length == 0">
                      <ng-template ngbPaginationPrevious>Anterior</ng-template>
                      <ng-template ngbPaginationNext>Siguiente</ng-template>
                    </ngb-pagination>
                  
                    <div class="d-flex align-items-center ml-3">
                      <label for="pageInput" class="mr-2">Buscar página específica:</label>
                      <input id="pageInput" type="number" class="form-control w-auto"
                             #pageInput
                             (change)="loadPage(pageInput.value)"
                             (keyup.enter)="loadPage(pageInput.value)"
                             onkeypress="return(event.charCode >= 48 && event.charCode <= 57)"
                             placeholder="" aria-label="Buscar página específica" />
                    </div>
                  </div>
            </div>
                <div class="col-md-2"></div>
        </div>
    </div>

    <div class="modal fade" id="modalEditaUbicacion" tabindex="-1" aria-labelledby="modalEditaUbicacion" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalEditaTipoSw">Editar</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formEditaUbicaciones" (submit)="editaUbicaciones()">
                    <input hidden="true" [(ngModel)]="ubicacionEditar.idUbicacion"  formControlName="idUbicacion">
                    <div class="form-group"> 
                        <label class="control-label is-required" for="nombreCar">Nombre Ubicación</label>
                        <input type="text" class="form-control" id="nombreCar" (keypress)="noSpecialChar($event)" maxlength="200" placeholder="Ingresar Ubicacion*" formControlName="nombreUbicacion" [(ngModel)]="ubicacionEditar.nombreUbicacion">
                    </div>   
                    <div style="text-align: center;"> 
                        <button type="submit" [disabled]="formEditaUbicaciones.invalid" data-bs-dismiss="modal" class="btn btn-primary">Guardar</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    </div>     
                </form>
            </div>
            <div class="modal-footer">
                
            </div>
          </div>
        </div>
      </div>