import { Component,  OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { InventarioService } from '../../../data/service/inventario.service';
import { HistorialAntena } from '../../../model/HistorialAntena';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import   * as XLSX from 'xlsx';

@Component({
  selector: 'app-historial-antenas',
  templateUrl: './historial-antenas.component.html',
  styleUrls: ['./historial-antenas.component.scss']
})
export class HistorialAntenasComponent implements OnInit {
  public formFechas: FormGroup;

  public histAntena: HistorialAntena[] = [];
  public histTag: HistorialAntena[] = [];
  public tag = '';

  public page: number = 1;
  public pageTag: number = 1;
  public pageSize: number = 10;
  public totalRecords: number;
  public previousPage: any;
  public nextPage: any = 0;
  public tipohw: any = [];
  public marcahw: any = [];
  public modelohw: any = [];

  tipo: number = null;
  marca: number = null;
  modelo: number = null;
  numInv : string = null;

  today: Date = new Date();
  pipe = new DatePipe('en-US');
  public date: Date = new Date();
  public data: any = [ ['ID','Ubicación', 'Fecha y hora','Tag','# Inventario','Tipo', 'Marca', 'Modelo'],];



  constructor( private formBuilder: FormBuilder, private inventarioService: InventarioService,  private modalService: NgbModal) {


    this.formFechas = this.formBuilder.group({
      fini: ['', [Validators.required]],
      ffin: ['', [Validators.required]],
      tipohw: ['', [Validators.required]],
      marca: ['', [Validators.required]],
      modelo: ['', [Validators.required]],
      numInvAlm: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    //this.muestraInvHardware();
    this.muestraTipoHw();
    this.muestraMarca();
    this.muestraModelo();
    //this.checar();
    this.llenar();
  }



  muestraInvHardware() {
    this.inventarioService.getHistorialAntena().subscribe((HistorialAntena: any) => {
      console.log(HistorialAntena);
      
      this.histAntena = HistorialAntena.data;

      this.totalRecords = HistorialAntena.totalRecords;

    })
  }



  checar() {


    this.formFechas.get('ffin').valueChanges.subscribe(ffin => {
      if (this.formFechas.value['fini'] != '') {
        this.llenar();
      }

    })
    this.formFechas.get('fini').valueChanges.subscribe(fini => {
      if (this.formFechas.value['ffin'] != '') {
        this.llenar();
      }
    })
    this.formFechas.get('tipohw').valueChanges.subscribe(tipohw => {
      if (this.formFechas.value['ffin'] != '' && this.formFechas.value['fini'] != '') {
        this.llenar();
      }
    })
    this.formFechas.get('marca').valueChanges.subscribe(marca => {
      if (this.formFechas.value['ffin'] != '' && this.formFechas.value['fini'] != '') {
        this.llenar();
      }
    })
    this.formFechas.get('modelo').valueChanges.subscribe(modelo => {
      if (this.formFechas.value['ffin'] != '' && this.formFechas.value['fini'] != '') {
        this.llenar();
      }
    })



  }


  verRuta(numeroTag, content) {
    let reporte = {
      "fechaInicio": this.formFechas.value['fini'],
      "fechaFin": this.formFechas.value['ffin'],
      "numeroTag": numeroTag
    }

    this.inventarioService.getHistorialInd(reporte).subscribe((respuesta: any) => {
      // console.log(reporte);
      if (respuesta['data'] != null) {
        // console.log(respuesta);
        this.histTag = respuesta.data;
        this.modalService.open(content);
        this.pageTag = 1

      }


    })
  }

  llenar() {


    this.formFechas.valueChanges.subscribe(llenar => {
      if (this.formFechas.value['fini'] != '' && this.formFechas.value['ffin'] != '') {
        this.tipo = this.formFechas.value['tipohw'];
        this.marca = this.formFechas.value['marca'];
        this.modelo = this.formFechas.value['modelo'];
        this.numInv = this.formFechas.value['numInvAlm'];

        if (this.tipo == 0) {
          this.tipo = null;
        } else {
          this.tipo = this.formFechas.value['tipohw'];
        }
        if (this.marca == 0) {
          this.marca = null;
        } else {
          this.marca = this.formFechas.value['marca'];
        }

        if (this.modelo == 0) {
          this.modelo = null;
        } else {
          this.modelo = this.formFechas.value['modelo'];
        }

        if (this.numInv == "") {
          this.numInv = null;
        } else {
          this.numInv = this.formFechas.value['numInvAlm'];
        }

        this.enviar();

      }


    })


  }

  exportExcel(){
    let reporte = {
      "fechaInicio": this.formFechas.value['fini'],
      "fechaFin": this.formFechas.value['ffin'],
      "idTipoHW": this.tipo,
      "idMarca": this.marca,
      "idHardware": this.modelo,
      "numeroInvAlm": this.numInv,
    }
    // console.log(reporte);
    
    //
    this.inventarioService.getHistorialAntenasReporte(reporte, this.page).subscribe((respuesta: any) => {
      if (respuesta['result'] == 1) {
        this.histAntena = respuesta.data;
        this.totalRecords = respuesta.totalRecords;
        this.data = [ ['ID','Ubicación', 'Fecha y hora','Tag','# Inventario','Tipo', 'Marca', 'Modelo'],];
        for (let index = 8; index < this.histAntena.length+8; index++) {
          let posicion = index - 8
          this.data.push([ 
            respuesta.data[posicion]['idHistorialAntena'],
            respuesta.data[posicion]['ubicacion'],
            respuesta.data[posicion]['horaFecha'],
            respuesta.data[posicion]['numeroTag'],
            respuesta.data[posicion]['numeroInvAlm'],
            respuesta.data[posicion]['tipo'],
            respuesta.data[posicion]['marca'],
            respuesta.data[posicion]['modelo'],
        ])
        
        }
        

        this.generaExcel();
      } else {
        Swal.fire({
          title: "Advertencia",
          text: respuesta['message'],
          type: "warning",
          // timer: 2000
          
        })
      }
      // console.log(respuesta);
      
    })
  }

  generaExcel(){
    /* DESDE AQUI es codigo para exportar en excel lo que tiene la tabla con cierto id */
      // var wb = XLSX.utils.table_to_book(document.getElementById('sjs-table'));
      // XLSX.writeFile(wb, "export.xlsx");
    /* HASTA AQUI es codigo para exportar en excel lo que tiene la tabla con cierto id */
      
    /* DESDE AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
      // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.invSoftware);
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, "HistorialAntenas-"+this.date.toLocaleDateString()+".xlsx");
    /* HASTA AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
  }

  enviar() {
    let reporte = {
      "fechaInicio": this.formFechas.value['fini'],
      "fechaFin": this.formFechas.value['ffin'],
      "idTipoHW": this.tipo,
      "idMarca": this.marca,
      "idHardware": this.modelo,
      "numeroInvAlm": this.numInv,
    }
    // console.log(reporte);
    
    //
    this.inventarioService.getHistorialAntenasFiltro(reporte, this.page).subscribe((respuesta: any) => {
      if (respuesta['result'] == 1) {
        this.histAntena = respuesta.data;
        this.totalRecords = respuesta.totalRecords;

        for (let index = 4; index < this.histAntena.length+4; index++) {
          let posicion = index - 4
          this.data.push([ 
            respuesta.data[posicion]['idHistorialAntena'],
            respuesta.data[posicion]['ubicacion'],
            respuesta.data[posicion]['horaFecha'],
            respuesta.data[posicion]['numeroTag'],
            respuesta.data[posicion]['numeroInvAlm'],
            respuesta.data[posicion]['tipo'],
        ])
        
        }
      } else {
        Swal.fire({
          title: "Advertencia",
          text: respuesta['message'],
          type: "warning",
          // timer: 2000
          
        })
      }
      // console.log(respuesta);
      
    })
  }

  muestraTipoHw() {
    this.inventarioService.getTipoHardware().subscribe((respuesta: any) => {
      this.tipohw = respuesta.data;
    })
  }
  muestraMarca() {
    this.inventarioService.getMarcas().subscribe((respuesta: any) => {
      this.marcahw = respuesta.data;

    })
  }
  muestraModelo() {
    this.inventarioService.getHardware().subscribe((respuesta: any) => {
      this.modelohw = respuesta.data;


    })
  }




  loadPage(page: number) {
    this.page = page;
    this.pageTag = page;

    this.nextPageData();
  }

  nextPageData() {
    this.histAntena = [];
    this.enviar();
  }
}
