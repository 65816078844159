
        
        <div class="shadow p-3 mb-5 bg-body rounded">
            <div style="text-align: center;">
                <span><h3>Catálogo Permisos</h3></span>
            </div>
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-5">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" (change)="cambioPermisoInput()" #txtBuscaPermiso placeholder="Buscar Permiso" aria-label="Buscar Permiso" aria-describedby="basic-addon2" (keyup.enter)="buscaPermisoInput()">
                        <div class="input-group-append">
                        <button class="btn btn-outline-secondary" type="button" (click)="buscaPermisoInput()"><i class="feather icon-search"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-5">  
                    <form [formGroup]="formPermisos" (submit)="guardaPermiso()">
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Ingresar Permiso*" (keypress)="noSpecialChar($event)" maxlength="150" aria-label="Ingresar Permiso" aria-describedby="basic-addon2"  formControlName="nombrePermiso">
                            <div class="input-group-append">
                                <button class="btn btn-primary">Agregar</button>
                            </div>
                        </div>
                    </form> 
                </div>
                <div class="col-md-4"></div>
            </div>
            

            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-8">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Permiso</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let per of permisos | paginate: { itemsPerPage: 10, currentPage: page } ">
                          <td>
                                  {{per.nombrePermiso}}
                          </td>
                      </tr>
                  </tbody>
                </table>
                <pagination-controls (pageChange)="page = $event" previousLabel="Anterior"S nextLabel="Siguiente"></pagination-controls>
              </div>
              <div class="col-md-2"></div>
          </div>
        </div>

