import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@Injectable({
  providedIn: 'root'
})


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class PeticionesModule { 



}


