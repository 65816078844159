import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';

import { environment } from 'src/environments/environment';
import { invSoftware } from '../../../model/invSoftware';
import { PdfService } from 'src/app/data/service/pdf-excel/pdf.service';
import { InventarioService } from 'src/app/data/service/inventario.service';
import Swal from 'sweetalert2';
import { Roles } from 'src/app/model';

@Component({
  selector: 'app-reporte-inventario-sw',
  templateUrl: './reporte-inventario-sw.component.html',
  styleUrls: ['./reporte-inventario-sw.component.scss']
})
export class ReporteInventarioSwComponent implements OnInit {
  //#region PAGINATION
  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;
  //#endregion

  private url: string = environment.api;
  public invSoftware: invSoftware[] = [];
  public date: Date = new Date();
  public data: any = [['Tipo', 'Software', 'Auxiliar', 'Stock', 'Lote'],];

  public rolesAzure = Roles;

  constructor(private _pdfService: PdfService, private http: HttpClient, private inventarioService: InventarioService) { }


  ngOnInit() {
    this.muestraInventario()
  }

  muestraInventario() {
    this.invSoftware = [];
    this.totalRecords = 0;
    this.cargarLoader = true;
    this.inventarioService.getReporteInvsw(this.page).subscribe((respuesta: any) => {
      if (respuesta['result'] === 0) {
        if (!Swal.isVisible()) {
          Swal.fire({
            title: "ERROR",
            text: "No hay coincidencias con tu criterio de búsqueda",
            type: "warning"
          }).then(() => {
            if (this.page != 1) {
              this.page = 1;
              this.muestraInventario();
            } else {
              this.page = 1;
              this.muestraInventario();
            }
          })
        }
      }
      this.invSoftware = respuesta.data;
      this.totalRecords = respuesta.totalRecords;
      this.cargarLoader = false;
    })
  }

  exportExcel() {
    this.inventarioService.getReporteInvsw(-1).subscribe((respuesta: any) => {
      if (respuesta['result'] === 0) {
        if (!Swal.isVisible()) {
          Swal.fire({
            title: "ERROR",
            text: "No hay coincidencias con tu criterio de búsqueda",
            type: "warning"
          }).then(() => {
            if (this.page != 1) {
              this.page = 1;
              this.muestraInventario();
            } else {
              this.page = 1;
              this.muestraInventario();
            }
          })
        }
      }

      /* DESDE AQUI es codigo para exportar en excel lo que tiene la tabla con cierto id */
      // var wb = XLSX.utils.table_to_book(document.getElementById('sjs-table'));
      // XLSX.writeFile(wb, "export.xlsx");
      /* HASTA AQUI es codigo para exportar en excel lo que tiene la tabla con cierto id */

      /* DESDE AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
      // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.invSoftware);
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(respuesta.data);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, "ReporteInventarioSoftware-" + this.date.toLocaleDateString() + ".xlsx");
      /* HASTA AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */

    })
  }

  async generatePDF() {
    this.inventarioService.getReporteInvsw(-1).subscribe((respuesta: any) => {
      if (respuesta['result'] === 0) {
        if (!Swal.isVisible()) {
          Swal.fire({
            title: "ERROR",
            text: "No hay coincidencias con tu criterio de búsqueda",
            type: "warning"
          }).then(() => {
            if (this.page != 1) {
              this.page = 1;
              this.muestraInventario();
            } else {
              this.page = 1;
              this.muestraInventario();
            }
          })
        }
      }

      const tituloEncabezado = 'Reporte de Inventario de Software';
      const FileName = 'InventarioSoftware' + this.date.toLocaleDateString();
      const embedHTML = document.getElementById('foo');
      const widths = '*';
      this._pdfService.generatePDF(respuesta.data, embedHTML, FileName, widths, tituloEncabezado);
    })
  }

  loadPage(page: number) {
    this.page = page;
    this.muestraInventario();
  }
}
