<div class="shadow p-3 mb-5 bg-body rounded">
  <div style="text-align: center;">
    <span>
      <h3>Historial de Asignación de Hardware</h3>
    </span>
  </div>
  <form [formGroup]="formFechas">
    <div class="row">
      <div class="form-group" style="margin-right: 25px;">
        <label for="divIdUsuario" class="control-label">Usuario</label>
        <div class="input-group mb-3" id="divIdUsuario">
          <input type="text" class="form-control" id="idUsuario" placeholder="Usuario" formControlName="usuario"
            [(ngModel)]="usuarioElegido.nombreEmpleado" disabled>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="quitarUsuario()"><i
                class="feather icon-x"></i></button>
            <button class="btn btn-outline-secondary" type="button" data-bs-target="#modalEligeUsuario"
              data-bs-toggle="modal"><i class="feather icon-search"></i></button>
          </div>
          <input type="hidden" formControlName="idUsuario" [(ngModel)]="usuarioElegido.numeroEmpleado">
        </div>
      </div>
      <div class="form-group" style="margin-right: 25px;">
        <label for="divIdUsuario" class="control-label">Inventario</label>
        <div class="input-group mb-3" id="divIdInventario">
          <input type="text" class="form-control" id="idHardware" placeholder="Tag Inventario"
            formControlName="hardware" [(ngModel)]="hardwareElegido.modelo" disabled>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="quitarInventario()"><i
                class="feather icon-x"></i></button>
            <button class="btn btn-outline-secondary" type="button" data-bs-target="#modalEligeInventario"
              data-bs-toggle="modal"><i class="feather icon-search"></i></button>
          </div>
          <input type="hidden" formControlName="idInventarioHw" [(ngModel)]="hardwareElegido.idInventarioHW">
        </div>
      </div>
      <div class="form-group" style="margin-right: 25px;">
        <div class="form-group">
          <label class="control-label is-required" for="fechaInicio">Fecha Inicio</label>
          <input type="date" class="form-control" id="fechaInicio" placeholder="Fecha Inicio" formControlName="fini">
        </div>
      </div>
      <div class="form-group" style="margin-right: 25px;">
        <div class="form-group">
          <label class="control-label is-required" for="vencimiento">Fecha Fin</label>
          <input type="date" class="form-control" id="vencimiento" placeholder="Vencimiento" formControlName="ffin">
        </div>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-4"></div>
    <div class="col-4" style="text-align: center; margin-bottom: 20px;"
    *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
      <button class="btn btn-lg btn-primary" (click)="mostrarReporte()">Mostrar Reporte</button>
    </div>
    <div class="col-4"></div>
  </div>

  <div class="row">
    <div class="col-md-12" style="overflow: auto;" *ngIf="!cargarLoader">
      <div class="row">
        <div class="col-8"></div>
        <div class="col-2" style="text-align:right ;"
        *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
          <button class="btn btn-primary mb-5" (click)="generatePDF()">Generar PDF</button>
        </div>
        <div class="col-2" style="text-align:right ;"
        *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
          <button class="btn btn-primary mb-5" (click)="exportExcel()">Generar Excel</button>
        </div>
      </div>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Folio Asignación</th>
            <th scope="col">Fecha Asignación</th>
            <th scope="col">Asignado</th>
            <th scope="col">Tipo Hardware</th>
            <th scope="col">Marca</th>
            <th scope="col">Modelo</th>
            <th scope="col"># Inventario</th>
            <th scope="col"># TAG</th>
            <th scope="col"># Serie</th>
            <th scope="col">Responsable</th>
            <th scope="col">Movimiento</th>
            <th scope="col">Documentos</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let per of asignacion">
            <td>
              {{per.folioAsignacion}}
            </td>
            <td>
              {{per.fechaHoraAsignacion | date:'longDate'}}
            </td>
            <td>
              {{per.usuario}}
            </td>
            <td>
              {{per.nombre}}
            </td>
            <td>
              {{per.nombreMarca}}
            </td>
            <td>
              {{per.modelo}}
            </td>
            <td>
              {{per.numeroInvAlm}}
            </td>
            <td>
              {{per.numeroTag}}
            </td>
            <td>
              {{per.numeroSerie}}
            </td>
            <td>
              {{per.responsable}}
            </td>
            <td>
              {{per.tipoMovimiento}}
            </td>
            <td style="text-align: center;">
              <button class="btn btn-secondary" (click)="abreModalDocumentos(per.folioAsignacion)"
                data-bs-toggle="modal" data-bs-target="#modalDocumentos">Documentos <i
                  class="feather icon-upload iconos-acciones"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <pagination-controls *ngIf="asignacion.length > 0" (pageChange)="page = $event" previousLabel="Anterior"S nextLabel="Siguiente"></pagination-controls> -->

      <div class="d-flex justify-content-start align-items-center p-2">
        <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
          (pageChange)="loadPage($event)" [disabled]="asignacion == null || asignacion.length == 0">
          <ng-template ngbPaginationPrevious>Anterior</ng-template>
          <ng-template ngbPaginationNext>Siguiente</ng-template>
        </ngb-pagination>

        <div class="d-flex align-items-center ml-3">
          <label for="pageInput" class="mr-2">Buscar página específica:</label>
          <input id="pageInput" type="number" class="form-control w-auto" #pageInput
            (change)="loadPage(pageInput.value)" (keyup.enter)="loadPage(pageInput.value)"
            onkeypress="return(event.charCode >= 48 && event.charCode <= 57)" placeholder=""
            aria-label="Buscar página específica" />
        </div>
      </div>

    </div>
  </div>
</div>


<div class="modal fade" id="modalEligeInventario" tabindex="-1" role="dialog" aria-labelledby="modalEligeInventario"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Elegir Inventario</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <div class="input-group mb-3">
              <input type="text" class="form-control" #txtBuscaInventario placeholder="Buscar Inventario"
                aria-label="Buscar Inventario" aria-describedby="basic-addon2" (keyup.enter)="buscaInventario()">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="buscaInventario()"><i
                    class="feather icon-search"></i></button>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>

        <div class="col-md-12" *ngIf="buscaInv">
          <table class="table table-bordered" *ngIf="invHardware.length > 0">
            <thead>
              <tr>
                <th scope="col">Hardware</th>
                <th scope="col">Marca</th>
                <th scope="col">Modelo</th>
                <th scope="col"># Serie</th>
                <th scope="col">TAG</th>
                <th scope="col">Elegir</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let det of invHardware">
                <td>
                  {{det.tipoHW}}
                </td>
                <td>
                  {{det.marca}}
                </td>
                <td>
                  {{det.modelo}}
                </td>
                <td>
                  {{det.numeroSerie}}
                </td>
                <td>
                  {{det.numeroTag}}
                </td>
                <td style="text-align: center;">
                  <button class="btn btn-warning" (click)="eligeInventario(det.idInventarioHW)"
                    data-bs-dismiss="modal">Elegir <i class="feather icon-edit iconos-acciones"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table table-bordered" *ngIf="invHardware.length == 0">
            <thead>
              <tr>
                <th scope="col">Hardware</th>
                <th scope="col">Marca</th>
                <th scope="col">Modelo</th>
                <th scope="col"># Serie</th>
                <th scope="col">TAG</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="center" colspan="5">
                  No hay resultados
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- Modal -->
<div class="modal fade" id="modalEligeUsuario" tabindex="-1" aria-labelledby="modalEligeUsuario" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Elegir Usuario</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <div class="input-group mb-3">
              <input type="text" class="form-control" #txtBuscaUsuario placeholder="Buscar Usuario"
                aria-label="Buscar Usuario" aria-describedby="basic-addon2" (keyup.enter)="buscaUsuario()">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="buscaUsuario()"><i
                    class="feather icon-search"></i></button>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="buscaUsu">
        <table class="table table-bordered" *ngIf="usuarios.length > 0">
          <thead>
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Número</th>
              <th scope="col">Correo</th>
              <th scope="col">Elegir</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let per of usuarios">
              <td>
                {{per.nombreEmpleado}}
              </td>
              <td>
                {{per.numeroEmpleado}}
              </td>
              <td>
                {{per.correo}}
              </td>
              <td style="text-align: center;">
                <button class="btn btn-warning" data-bs-dismiss="modal"
                  (click)="eligeUsuario(per.numeroEmpleado)">Elegir <i
                    class="feather icon-edit iconos-acciones"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table table-bordered" *ngIf="usuarios.length == 0">
          <thead>
            <tr>
              <th scope="col">Usuario</th>
              <th scope="col">Elegir</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center" colspan="2">
                No hay resultados
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="modalDocumentos" tabindex="-1" role="dialog" aria-labelledby="modalDocumentos"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Documentos</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <div class="container">
          <form [formGroup]="formDocumento" autocomplete="off">
            <div id="subirDoc" style="text-align: center;">
              <h5>Subir Nuevo Documento</h5>
            </div>
            <div id="subirDoc" class="row well">
              <div class="form-group col-5">
                <label class="control-label is-required" for="motivo">Documento</label>
                <input type="file" (change)="nuevoDoc($event)" formControlName="file">
              </div>
              <div class="form-group col-5">
                <label for="state_id" class="control-label is-required">Tipo Documento</label>
                <select class="form-control" id="tipoDoc" formControlName="tipoDoc">
                  <option value="" disabled selected>Elige el tipo de documento</option>
                  <option [value]=doc.idTipoDocumento *ngFor="let doc of tiposDocumentos">{{doc.nombreDocumento}}
                  </option>
                </select>
              </div>
              <div class="col-2" style="text-align: center;">
                <button type="submit" class="btn btn-primary" style="margin-top: 28px;" (click)="subirArchivo()"
                  data-bs-dismiss="modal" [disabled]="formDocumento.invalid">Subir</button>
              </div>
              <div class="modal-footer">
              </div>

            </div>
            <hr>
            <div id="subirDoc" style="text-align: center;">
              <h5>Documentos Existentes</h5>
            </div>
            <div class="row">
              <div class="col-md-12" style="overflow: auto;">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <!-- <th scope="col">Folio Asignación</th>
                            <th scope="col">Fecha Asignación</th> -->
                      <th scope="col">Documento</th>
                      <th scope="col">Descargar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let doc of documentosAsignacion">
                      <!-- <td>
                                        {{doc.tipoDocumento}}
                                </td> -->
                      <td>
                        {{doc.rutaAsignacion}}
                      </td>
                      <td style="text-align: center;">
                        <!-- <button class="btn btn-primary">Descargarr<a href="https://d2kjhhjsuvwfd6.cloudfront.net/{{doc.rutaAsignacion}}"  target="_blank"><i class="feather icon-download iconos-acciones"></i></a></button> -->
                        <a href="https://d2kjhhjsuvwfd6.cloudfront.net/{{doc.rutaAsignacion}}" class="btn btn-primary"
                          target="_blank">Descargar<i class="feather icon-download iconos-acciones"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>