import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { MicrosoftAuthService } from '../../../../../data/service/auth/microsoft-auth.service';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
  isLoggedIn: boolean;
  name: string;

  constructor(private MicrosoftAuthService: MicrosoftAuthService) {
    this.isLoggedIn = (localStorage.getItem('isLoggedIn') == 'true') ? true : false;
    this.name = localStorage.getItem('name');
   }

  ngOnInit() { }

  logout() {
    this.MicrosoftAuthService.logout()
  }
}
