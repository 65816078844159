import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators       } from '@angular/forms';
import { HttpClient                               } from '@angular/common/http';
import   Swal                                       from 'sweetalert2';

import { environment } from 'src/environments/environment';
import { Situacion } from '../../../model/situacion';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { Roles } from 'src/app/model';

@Component({
  selector:     'app-catalogo-situaciones',
  templateUrl:  './catalogo-situaciones.component.html',
  styleUrls:   ['./catalogo-situaciones.component.scss']
})
export class CatalogoSituacionesComponent implements AfterViewInit {
    //#region PAGINATION
    public page: number = 1;
    public pageSize: number = 10;
    public totalRecords: number = 0;
    public cargarLoader: boolean = true;
    //#endregion

  public formSituacion : FormGroup;
  public formEditarSituacion : FormGroup;
  private url: string = environment.api;
  public situacion: Situacion[] = [];
  
  situacionEditar: any = {
    idSituacion: null,
    nombre: '',
}

public rolesAzure = Roles;

  @ViewChild('txtBuscaSituacion', {static: false}) txtBuscaSituacion!:ElementRef<HTMLInputElement>;
  // @ViewChild('nameInput', {static: false}) component : Component

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService){
    this.formSituacion= this.formBuilder.group({
      nombre: ['',[Validators.required]],
    })

    this.formEditarSituacion= this.formBuilder.group({
      idSituacion: ['',[Validators.required]],
      nombre: ['',[Validators.required]],
    })
  }
  ngAfterViewInit(): void {
    this.buscarSituaciones()
  }

  abreEditarSituaciones(idSituacion){
    this.inventarioService.getSituacionxId(idSituacion).subscribe((respSituacion:any) =>{
       this.situacionEditar = respSituacion.data;
    })
  }

  buscarSituaciones(){
    this.situacion = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    if (this.txtBuscaSituacion.nativeElement.value === '') {
      this.inventarioService.getSituacionesLimit(this.page).subscribe((respSituacion:any) =>{
        if (respSituacion['result'] === 0) {
          if (!Swal.isVisible()) {
            Swal.fire({
              title: "ERROR",
              text: "No hay coincidencias con tu criterio de búsqueda",
              type: "warning"
            }).then(() => {
              if (this.page != 1) {
                this.page = 1;
                this.buscarSituaciones();
              } else {
                this.txtBuscaSituacion.nativeElement.value = "";
                this.page = 1;
                this.buscarSituaciones();
              }
            })
          }
        }
  
         this.situacion = respSituacion.data;
         this.totalRecords = respSituacion.totalRecords;
          this.cargarLoader = false;
      })
    } else {
      let datoPostsituacion={
          "nombreTabla": "situacion",
          "valorBusqueda": this.txtBuscaSituacion.nativeElement.value,
          "Page": this.page
        }
      this.inventarioService.getSituacionesTablaCampo(datoPostsituacion).subscribe((respSituacion:any) =>{
        if (respSituacion['result'] === 0) {
          if (!Swal.isVisible()) {
            Swal.fire({
              title: "ERROR",
              text: "No hay coincidencias con tu criterio de búsqueda",
              type: "warning"
            }).then(() => {
              if (this.page != 1) {
                this.page = 1;
                this.buscarSituaciones();
              } else {
                this.txtBuscaSituacion.nativeElement.value = "";
                this.page = 1;
                this.buscarSituaciones();
              }
            })
          }
        } else {
          this.situacion = respSituacion.data;
          this.totalRecords = respSituacion.totalRecords;
          this.cargarLoader = false;
        }
      }) 
    }
  }

 cambioBuscaSituacionInput(){
  let valor = this.txtBuscaSituacion.nativeElement.value;
  if (valor === '') {
    this.page = 1
    this.buscarSituaciones();
  }
  }

  editaSituacion(){
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se modificará el nombre de la situación, aunque ya exista conexión de este registro",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.dismiss) {
       
      }else{
        this.inventarioService.updateSituaciones(this.formEditarSituacion.value).subscribe(data => {
          if (data['result'] === 1) {
            this.page = 1
            this.formEditarSituacion.reset();
            this.buscarSituaciones();
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
              // timer: 3000
            })
          }else{
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
              // timer: 2000
            })
          }
        })
      }

    })
        
      
 }

  guardaSituacion(){
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se guardará como nuevo registro",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.dismiss) {
      
      }else{
        this.inventarioService.createSituaciones(this.formSituacion.value).subscribe(data => {
          if (data['result'] === 1) {
            this.page = 1
            this.formSituacion.reset();
            this.buscarSituaciones();
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
              // timer: 3000
            })
          }else{
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
              // timer: 2000
            })
          }
        }) 
      }
    })
      
 }

 noSpecialChar(char){
    const onlyspecial = /[°¬_"&@!=¡¿¨´~`',;:.*+\-?^$/{}<>()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
        return false;
    }
    return true
  }

  loadPage(page: number) {
    this.page = page;
    this.buscarSituaciones();
  }

}
