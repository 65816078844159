import { Component, ElementRef, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';

import { environment } from 'src/environments/environment';
import { PdfService } from 'src/app/data/service/pdf-excel/pdf.service';
import { Usuario } from 'src/app/model/usuario';
import { InventarioHW } from '../../../model/inventarioHw';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { downloadPDF } from '../../shared/functions/downloadPDF';
import { Roles } from 'src/app/model';

@Component({
  selector: 'app-rep-asignacion-ubicacion-hw',
  templateUrl: './rep-asignacion-ubicacion-hw.component.html',
  styleUrls: ['./rep-asignacion-ubicacion-hw.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: "es" }],
})

export class RepAsignacionUbicacionHwComponent implements OnInit {
  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;


  private url: string = environment.api;
  public invHardware: any[] = [];
  public ubicacion: any = [];
  public formFechas: FormGroup;
  public buscaUsu = false
  public usuarios: Usuario[] = [];
  public date: Date = new Date();
  documentosAsignacion: any[] = []

  usuarioElegido: any = []

  public rolesAzure = Roles;

  @ViewChild('txtBuscaUsuario', { static: false }) txtBuscaUsuario!: ElementRef<HTMLInputElement>;

  constructor(private _pdfService: PdfService, private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService) {
    this.formFechas = this.formBuilder.group({
      fini: ['', [Validators.required]],
      ffin: ['', [Validators.required]],
      usuario: ['', [Validators.required]],
      idUsuario: ['', [Validators.required]],
      ubicacion: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.muestraUbicaciones()
  }

  mostrarReporte() {
    this.invHardware = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    if ((this.formFechas.value['fini'] != '' && this.formFechas.value['ffin'] == '') || (this.formFechas.value['fini'] == '' && this.formFechas.value['ffin'] != '')) {
      Swal.fire({
        title: "Advertencia",
        text: 'No puede llevar valor solo una fecha',
        type: "warning",
        // timer: 2000
      })
    } else {
      let ubi
      let usu
      if (this.formFechas.value['ubicacion'] == '') ubi = 0
      else ubi = this.formFechas.value['ubicacion']
      if (this.formFechas.value['idUsuario'] == '' || this.formFechas.value['idUsuario'] == null) usu = 0
      else usu = this.formFechas.value['idUsuario']

      let reporte = {
        "fini": this.formFechas.value['fini'],
        "ffin": this.formFechas.value['ffin'],
        "idUsuario": usu,
        "idUbicacion": ubi,
        "limit": 0,
        "Page": this.page
      }
      this.inventarioService.getAsignacionUbicacionHw(reporte).subscribe((respuesta: any) => {
        if (respuesta.data != null && respuesta.data.length > 0) {
          this.invHardware = respuesta.data;
          this.totalRecords = respuesta.totalRecords;
          this.cargarLoader = false;
        }
        else this.funcionErrorPaginacion();
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
      })
    }

  }

  muestraUbicaciones() {
    this.inventarioService.getUbicaciones().subscribe((respHardware: any) => {
      this.ubicacion = respHardware.data;
    })
  }

  abreModalDocumentos(idAsignacion) {
    this.inventarioService.getListDocumentosAsignacion(idAsignacion).subscribe((respuesta: any) => {
      this.documentosAsignacion = respuesta.data;
    })
  }

  buscaUsuario() {
    let valor = this.txtBuscaUsuario.nativeElement.value;
    if (valor === '') {
      this.usuarios.length = 0
      this.buscaUsu = true
    } else {
      let datoPostUsuario = {
        "nombreTabla": "usr",
        "valorBusqueda": valor,
      }
      this.http.get(`${this.url}Usuarios/nombre/` + valor).subscribe((respUsuario: any) => {
        if (respUsuario['result'] === 0) {
          this.usuarios.length = 0
          this.buscaUsu = true
        } else {
          this.buscaUsu = true
          this.usuarios = respUsuario.data;
        }
      })
    }
  }

  eligeUsuario(idUsuario) {
    this.http.get(`${this.url}Usuarios/` + idUsuario).subscribe((respUsuario: any) => {
      this.usuarioElegido = respUsuario.data;
    })
  }

  quitarUsuario() {
    this.usuarioElegido = []
  }

  async exportExcel() {
    let ubi
    let usu
    if (this.formFechas.value['ubicacion'] == '') ubi = 0
    else ubi = this.formFechas.value['ubicacion']
    if (this.formFechas.value['idUsuario'] == '' || this.formFechas.value['idUsuario'] == null) usu = 0
    else usu = this.formFechas.value['idUsuario']

    let reporte = {
      "fini": this.formFechas.value['fini'],
      "ffin": this.formFechas.value['ffin'],
      "idUsuario": usu,
      "idUbicacion": ubi,
      "limit": 0,
      "Page": -1
    }
    this.inventarioService.getAsignacionUbicacionHw(reporte).subscribe((respuesta: any) => {
      if (respuesta['result'] == 1) {
        /* DESDE AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.invHardware);
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(respuesta.data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, "AsignacionUbicacionHardware-" + this.date.toLocaleDateString() + ".xlsx");
        /* HASTA AQUI es codigo para imprimir en formato excel datos que estan en la variable data, asi como vienen */
      } else {

        Swal.fire({
          title: "Advertencia",
          text: respuesta['message'],
          type: "warning",
          // timer: 2000
        })
      }
    }, error => {
      Swal.fire({
        title: "ERROR",
        text: `Error ${error}`,
        type: "warning"
      })
    })
  }

  async generatePDF() {
    let ubi
    let usu
    if (this.formFechas.value['ubicacion'] == '') ubi = 0
    else ubi = this.formFechas.value['ubicacion']
    if (this.formFechas.value['idUsuario'] == '' || this.formFechas.value['idUsuario'] == null) usu = 0
    else usu = this.formFechas.value['idUsuario']

    let reporte = {
      "fini": this.formFechas.value['fini'],
      "ffin": this.formFechas.value['ffin'],
      "idUsuario": usu,
      "idUbicacion": ubi,
      "limit": 0,
      "Page": -1
    }
    this.inventarioService.getAsignacionUbicacionHw(reporte).subscribe(async (respuesta: any) => {
      if (respuesta['result'] == 1) {
        const tituloEncabezado = 'Reporte de Asignación y Ubicación de Hardware';
        const FileName = 'AsignacionUbicacionHardware' + this.date.toLocaleDateString();
        const embedHTML = document.getElementById('foo');
        const widths = 'auto';
        await this._pdfService.generatePDF(respuesta.data, embedHTML, FileName, widths, tituloEncabezado);
      } else {
        Swal.fire({
          title: "Advertencia",
          text: respuesta['message'],
          type: "warning",
          // timer: 2000
        });
      }
    }, error => {
      Swal.fire({
        title: "ERROR",
        text: `Error ${error}`,
        type: "warning"
      })
    })
  }

  loadPage(page: number) {
    this.page = page;
    this.mostrarReporte();
  }

  funcionErrorPaginacion() {
    if (!Swal.isVisible()) {
      Swal.fire({
        title: "ERROR",
        text: "No hay coincidencias con tu criterio de búsqueda",
        type: "warning"
      }).then(() => {
        if (this.page != 1) {
          this.page = 1;
          this.mostrarReporte();
        } else {
          this.page = 1;
          this.formFechas.get("fini").setValue("");
          this.formFechas.get("ffin").setValue("");
          this.formFechas.get("usuario").setValue("");
          this.formFechas.get("idUsuario").setValue("");
          this.formFechas.get("ubicacion").setValue("");
          this.mostrarReporte();
        }
      })
    }
  }
}
