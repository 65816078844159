import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

import { environment } from 'src/environments/environment';
import { Marcas } from 'src/app/model/marcas';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { Software } from '../../../model/Software';
import { Roles } from 'src/app/model';

@Component({
  selector: 'app-catalogo-software',
  templateUrl: './catalogo-software.component.html',
  styleUrls: ['./catalogo-software.component.scss']
})
export class CatalogoSoftwareComponent implements AfterViewInit {
  //#region PAGINATION
  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;
  //#endregion

  private url: string = environment.api;
  public software: Software[] = [];
  public tiposw: any[] = [];
  public marcas: Marcas[] = [];
  public formCatSoftware: FormGroup;
  public formEditaCatSoftware: FormGroup;

  softwareEditar: any = {
    idSoftware: null,
    idTipoSw: null,
    idMarca: null,
    nombre: '',
    descripcion: '',
  }

  public rolesAzure = Roles;

  @ViewChild('txtBuscaSoftware', { static: false }) txtBuscaSoftware!: ElementRef<HTMLInputElement>;

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService) {
    this.formCatSoftware = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      descripcion: ['', [Validators.required]],
      idTipoSw: ['', [Validators.required]],
      idMarca: ['', [Validators.required]],
    });

    this.formEditaCatSoftware = this.formBuilder.group({
      idSoftware: ['', [Validators.required]],
      nombre: ['', [Validators.required]],
      descripcion: ['', [Validators.required]],
      idTipoSw: ['', [Validators.required]],
      idMarca: ['', [Validators.required]],
    });
  }
  ngAfterViewInit(): void {
    this.muestraSoftware();
    this.muestraTipoSw()
    this.muestraMarcas()
  }

  muestraSoftware() {
    this.software = [];
    this.totalRecords = 0;
    this.cargarLoader = true;

    if (this.txtBuscaSoftware.nativeElement.value === '') {
      this.inventarioService.getSoftwareLimit(this.page).subscribe((respSoftware: any) => {
        if (respSoftware['result'] === 0) {
          if (!Swal.isVisible()) {
            Swal.fire({
              title: "ERROR",
              text: "No hay coincidencias con tu criterio de búsqueda",
              type: "warning"
            }).then(() => {
              if (this.page != 1) {
                this.page = 1;
                this.muestraSoftware();
              } else {
                this.txtBuscaSoftware.nativeElement.value = "";
                this.page = 1;
                this.muestraSoftware();
              }
            })
          }
        }
        this.software = respSoftware.data;
        this.totalRecords = respSoftware.totalRecords;
        this.cargarLoader = false;
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
        this.cargarLoader = false;
      })
    } else {
      let datoPost = {
        "nombreTabla": "sw",
        "valorBusqueda": this.txtBuscaSoftware.nativeElement.value,
        "Page": this.page
      }
      this.inventarioService.getSoftwareTablaCampo(datoPost).subscribe((respSoftware: any) => {
        if (respSoftware['result'] === 0) {
          if (!Swal.isVisible()) {
            Swal.fire({
              title: "ERROR",
              text: "No hay coincidencias con tu criterio de búsqueda",
              type: "warning"
            }).then(() => {
              if (this.page != 1) {
                this.page = 1;
                this.muestraSoftware();
              } else {
                this.txtBuscaSoftware.nativeElement.value = "";
                this.page = 1;
                this.muestraSoftware();
              }
            })
          }
        } else {
          this.software = respSoftware.data;
          this.totalRecords = respSoftware.totalRecords;
          this.cargarLoader = false;
        }
      })
    }




  }

  muestraTipoSw() {
    this.inventarioService.getTipoSoftware().subscribe((respSoftware: any) => {
      this.tiposw = respSoftware.data;
    })
  }

  muestraMarcas() {
    this.inventarioService.getMarcas().subscribe((respMarcas: any) => {
      this.marcas = respMarcas.data;
    })
  }

  abreEditaCatSoftware(idSoftware) {
    this.inventarioService.getSoftwarexId(idSoftware).subscribe((respSoftware: any) => {
      this.softwareEditar = respSoftware.data;
    })
  }


  cambioBuscaSoftwareInput() {
    let valor = this.txtBuscaSoftware.nativeElement.value;
    if (valor === '') {
      this.page = 1
      this.muestraSoftware();
    }
  }

  editaCatSoftware(): any {
    Swal.fire({
      title: '¿Estás Seguro?',
      text: "Se modificará el catálogo de software, aunque ya exista conexión con algún registro",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.dismiss) {

      } else {
        this.inventarioService.updateSoftware(this.formEditaCatSoftware.value).subscribe(data => {
          if (data['result'] === 1) {
            this.page = 1
            this.formEditaCatSoftware.reset();
            this.muestraSoftware();
            Swal.fire({
              title: "Guardado",
              text: "Se ha registrado exitosamente",
              type: "success",
              // timer: 3000
            })
          } else {
            Swal.fire({
              title: "ERROR",
              text: data['message'],
              type: "warning",
              // timer: 2000
            })
          }

        })
      }
    })

  }

  guardaCatSoftware(): any {
    this.inventarioService.createSoftware(this.formCatSoftware.value).subscribe(data => {
      if (data['result'] === 1) {
        this.page = 1
        this.formCatSoftware.reset();
        this.muestraSoftware();
        Swal.fire({
          title: "Guardado",
          text: "Se ha registrado exitosamente",
          type: "success",
          // timer: 3000
        })
      } else {
        Swal.fire({
          title: "ERROR",
          text: data['message'],
          type: "warning",
          // timer: 2000
        })
      }

    })
  }

  noSpecialChar(char) {
    const onlyspecial = /[°¬_"&@!=¡¿¨´~`',;:.*+\-?^$/{}<>()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
      return false;
    }
    return true
  }

  noSpecialCharNombre(char) {
    const onlyspecial = /[°¬_"&@!=¡¿¨´~`',;:*+?^${}<>()|#%[\]\\]/g;
    if (char.key.match(onlyspecial)) {
      event.preventDefault();
      return false;
    }
    return true
  }

  loadPage(page: number) {
    this.page = page;
    this.muestraSoftware();
  }
}
