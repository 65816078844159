<div class="row">
  <div class="col-md-2"></div>
  <div class="col-md-8">
    <div class="input-group mb-3">

      <ng-select class="form-control" name="opcBuscarTipoHW" id="opcBuscarTipoHW" #opcBuscarTipoHW
        *ngIf="flagTipoHW" [options]="opcTipoHW" placeholder="Seleccione tipo de Hardware"
      >
      </ng-select>

      <ng-select class="form-control" name="opcBuscarTipoMarcas" id="opcBuscarTipoMarcas" #opcBuscarTipoMarcas
        *ngIf="flagTipoMarca" [options]="opcTipoMarcas" placeholder="Seleccione tipo de Marca"
      >
      </ng-select>

      <ng-select class="form-control" name="opcBuscarAsignaNoAsigna" id="opcBuscarAsignaNoAsigna" #opcBuscarAsignaNoAsigna
        [options]="opcAsignaNoAsigna" placeholder="Seleccione Estado De Asignación"
      >
      </ng-select>

      <input type="text" class="form-control" #txtBuscaInvHard placeholder="Buscar Hardware" aria-label="Buscar Hardware" aria-describedby="basic-addon2">

      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="buscaInvHardInput()" type="button"> <i class="feather icon-search"></i> </button>
        <button class="btn btn-outline-secondary" (click)="limpiarBusqueda()" type="button" [disabled]="isBusquedaLimpia()">
          <img src="../../../../assets/fonts/fa-filter-personal.svg" alt="icono" width="82%">
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-2">
  </div>
</div>
<div style="overflow: auto;" *ngIf="!cargarLoader">
  <div class="form-group">
    <div class="m-2">
      <button [disabled]="( someComplete() && hayHardwareAsignadoEnSeleccion  && !allComplete) ||
                          (!someComplete() && !hayHardwareAsignadoEnSeleccion && !allComplete) ||
                          (!someComplete() && hayHardwareAsignadoEnSeleccion  &&  allComplete)" class="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#modalNuevaAsignacion">
        Asignar
      </button>
      <button type="button" class="btn btn-primary  ml-4" (click)="FuncionalidadOption()">Opcion 1 sin función</button>
      <button type="button" class="btn btn-primary  ml-4" (click)="FuncionalidadOption()">Opcion 2 sin función</button>
      <button type="button" class="btn btn-primary  ml-4" (click)="FuncionalidadOption()">Opcion 3 sin función</button>
    </div>
  </div>
  <div class="example-table-container">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col" class="Padding-Table" *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR]">
            <mat-checkbox [checked]="allComplete" color="primary" [indeterminate]="someComplete()" (change)="setAll($event.checked)"></mat-checkbox>
          </th>
          <th scope="col" class="Padding-Table">Tipo</th>
          <th scope="col" class="Padding-Table">Marca</th>
          <th scope="col" class="Padding-Table">Modelo</th>
          <th scope="col" class="Padding-Table">Asignado</th>
          <th scope="col" class="Padding-Table"># inventario</th>
          <th scope="col" class="Padding-Table"># serie</th>
          <!-- <th scope="col" class="Padding-Table"># tag</th> -->
          <th scope="col" class="Padding-Table">Caracteristicas</th>
          <th scope="col" class="Padding-Table"
          *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR]">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let inv of checkSelect.arrayInventaHw">
          <td class="Padding-Table" *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR]">
            <mat-checkbox [(ngModel)]="inv.completed" color="primary" (ngModelChange)="updateAllComplete()"></mat-checkbox>
          </td>
          <td class="Padding-Table">
            {{inv.tipoHW}}
          </td>
          <td class="Padding-Table">
            {{inv.marca}}
          </td>
          <td class="Padding-Table cursor-point" (mouseenter)="showTooltipCaracteristicas($event, inv)" (mouseleave)="hideTooltipCaracteristicas()"
            *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR]"
          >
            <em>{{inv.modelo}}</em>
          </td>
          <td class="Padding-Table" *appHasAnyRole="[rolesAzure.ING_SOPORTE]">{{inv.modelo}}</td>

          <ng-container *ngIf="inv.asignado == 1 && inv.nombreEmpleado != 'Almacen'">
            <td class="Padding-Table cursor-point" (mouseenter)="showTooltipAsignado($event, inv)" (mouseleave)="hideTooltipAsignado()"
              *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR, rolesAzure.ING_SOPORTE]"
            >
              <span class="led-green"></span>
              <span class="ml-1"><em>Asignado</em></span>
            </td>
          </ng-container>
          <td class="Padding-Table" *ngIf="inv.asignado == 0 || (inv.asignado == 1 && inv.nombreEmpleado == 'Almacen')">
            <span class="led-red"></span>
            <span class="ml-1">No Asignado</span>
          </td>

          <td class="Padding-Table cursor-point" (mouseenter)="showTooltipNumInv($event, inv)" (mouseleave)="hideTooltipNumInv()"
            *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR]"
          >
            <em>{{inv.numeroInvAlm}}</em>
          </td>
          <td class="Padding-Table" *appHasAnyRole="[rolesAzure.ING_SOPORTE]">{{inv.numeroInvAlm}}</td>
          <td class="Padding-Table">
            {{inv.numeroSerie}}
          </td>
          <!-- <td class="Padding-Table">
            {{inv.numeroTag}}
          </td> -->
          <td class="Padding-Table" style="text-align: center;">
            <button class="btn btn-primary"
              *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR, rolesAzure.ING_SOPORTE]"
              (click)="verCaracteristicas(inv.idInventarioHW)" data-bs-toggle="modal"
              data-bs-target="#modalCaracteristicas"><i class="feather icon-eye"></i></button>

            <button class="btn btn-warning" 
              *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR]"
              (click)="abreEditaCaras(inv.idInventarioHW,inv.idTipoHw)" data-bs-toggle="modal"
              data-bs-target="#editaCars"><i class="feather icon-edit iconos-acciones"></i></button>
          </td>
          <td class="Padding-Table" style="text-align: center;"
          *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR]">
            <button class="btn btn-warning"
            (click)="abreEditaHardware(inv.idInventarioHW)" data-bs-toggle="modal"
              data-bs-target="#editaHardware">Editar <i class="feather icon-edit iconos-acciones"></i></button>
            <button class="btn btn-danger" (click)="abreBajaHardware(inv.idInventarioHW)" style="margin-left: 5px;"
              data-bs-toggle="modal" data-bs-target="#modalBajaHardware">Baja <i
                class="feather icon-trash-2 iconos-acciones"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- <pagination-controls (pageChange)="page = $event" previousLabel="Anterior"S nextLabel="Siguiente"></pagination-controls> -->
  <div class="d-flex justify-content-start align-items-center p-2">
    <ngb-pagination [collectionSize]="currentPage" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
      (pageChange)="loadPage($event)" [disabled]="checkSelect.arrayInventaHw == null || checkSelect.arrayInventaHw.length == 0">
      <ng-template ngbPaginationPrevious>Anterior</ng-template>
      <ng-template ngbPaginationNext>Siguiente</ng-template>
    </ngb-pagination>

    <div class="d-flex align-items-center ml-3">
      <label for="pageInput" class="mr-2">Buscar página específica:</label>
      <input id="pageInput" type="number" class="form-control w-auto" #pageInput (change)="loadPage(pageInput.value)"
        (keyup.enter)="loadPage(pageInput.value)" onkeypress="return(event.charCode >= 48 && event.charCode <= 57)"
        placeholder="" aria-label="Buscar página específica" />
    </div>
  </div>

  <!-- Tooltip personalizado en forma de tarjeta -->
  <div class="custom-tooltip" *ngIf="caracteristicasItem" [ngStyle]="{ top: tooltipPosition.top, left: tooltipPosition.left }">
    <div class="tooltip-header">Caracteristicas</div>
    <div class="tooltip-body">
      <div *ngIf="caracteristicasItem.caracteristicas != null">
        <p [innerHTML]="caracteristicasItem.caracteristicas"></p>
      </div>
      <div *ngIf="caracteristicasItem.caracteristicas == null">
        <p>Sin Información</p>
      </div>
    </div>
  </div>

  <div class="custom-tooltip" *ngIf="asignadoItem" [ngStyle]="{ top: tooltipPosition.top, left: tooltipPosition.left }">
    <div class="tooltip-header">Asignado</div>
    <div class="tooltip-body">
      <p><strong>Nombre:</strong> {{ asignadoItem.nombreEmpleado }}</p>
      <p><strong>Ubicación:</strong> {{ asignadoItem.nombreUbicacion }}</p>
      <p><strong>Fecha:</strong> {{ asignadoItem.fechaHoraAsignacion }}</p>
    </div>
  </div>

  <div class="custom-tooltip" *ngIf="numInvItem" [ngStyle]="{ top: tooltipPosition.top, left: tooltipPosition.left }">
    <div class="tooltip-header">Información Tag</div>
    <div class="tooltip-body">
      <div *ngIf="numInvItem.infoTag != null">
        <p [innerHTML]="numInvItem.infoTag"></p>
      </div>
      <div *ngIf="numInvItem.infoTag == null">
        <p>Sin Información</p>
      </div>
    </div>
  </div>


</div>

<!-- Modal -->
<div class="modal fade" id="editaHardware" tabindex="-1" aria-labelledby="editaHardware" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Hardware</h5>
        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="resetImagenes()">X</button> -->
        <button type="button" class="close" data-bs-dismiss="modal"  aria-label="Close" (click)="resetImagenes()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="carousel-container">
          <button (click)="prev()" class="carousel-btn prev-btn" *ngIf="!inventarioSinImagen && images.length > 1">&#10094;</button>
          <div class="carousel">
            <div class="carousel-item" *ngFor="let image of images; let i = index" [class.active]="i === currentIndex">
              <a [href]="image" target="_blank" *ngIf="!inventarioSinImagen">
                <img [src]="image" style="width: 410px; height: 250px; object-fit: cover;" />
              </a>
              <img [src]="image" style="width: 410px; height: 250px; object-fit: cover;"  *ngIf="inventarioSinImagen"/>
              <button class="delete-icon" (click)="removeImage(i, $event)" *ngIf="!inventarioSinImagen">
                &#10006;
              </button>
            </div>
          </div>
          <button (click)="next()" class="carousel-btn next-btn" *ngIf="!inventarioSinImagen && images.length > 1">&#10095;</button>
        </div>
        <br>
        <form [formGroup]="formEditaHardware" (submit)="editaInvHard()">
          <input hidden="true" [(ngModel)]="invHwEditar.idInventarioHw" formControlName="idInventarioHw">

          <div class="form-group">
            <label class="control-label" for="numeroSerie">Número de Serie</label>
            <input type="text" class="form-control" id="numeroSerie" placeholder="Número de Serie"
              formControlName="numeroSerie" [(ngModel)]="invHwEditar.numeroSerie">
          </div>
          <div class="form-group">
            <label class="control-label" for="noInvAlm">Número de Inventario</label>
            <input type="text" class="form-control" id="noInvAlm" placeholder="Número de Inventario"
              formControlName="numeroInvAlm" [(ngModel)]="invHwEditar.numeroInvAlm">
          </div>
          <div class="form-group">
            <label for="state_id" class="control-label">Situación</label>
            <select class="form-control" name="tipoHw" id="situacion" formControlName="idSituacion"
              [(ngModel)]="invHwEditar.idSituacion">
              <option value="" disabled selected>Elige Situacion</option>
              <option [value]=sit.idSituacion *ngFor="let sit of situaciones"
                [selected]="sit.idSituacion == invHwEditar.idSituacion">{{sit.nombre}}</option>
            </select>
          </div>
          <!-- <div>
            <file-upload
              [maxsize]="1480000"
              accept=".jpg"
              formControlName="filesForm"
              *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR, rolesAzure.ING_SOPORTE]"
            >
              <ng-template
                let-isFileDragDropAvailable="isFileDragDropAvailable"
                #placeholder
              >
                <file-upload-drop-zone
                >
                  <ng-container
                    *ngIf="
                      isFileDragDropAvailable;
                      else isNotDragDropAvailable
                    "
                  >
                    <b>Arrastra</b><br />para agregar archivos
                  </ng-container>
                  <ng-template #isNotDragDropAvailable>
                    <b>Haga clic</b> para<br />
                    seleccionar un archivo
                  </ng-template>
                </file-upload-drop-zone>
              </ng-template>

              <ng-template
                let-i="index"
                let-file="file"
                let-control="control"
                #item
              >
                <file-upload-list-item
                  [index]="i"
                  [file]="file"
                  [control]="control"
                  >Eliminar</file-upload-list-item
                >
              </ng-template>
            </file-upload>
            <div *ngIf="campoInvalido('filesForm')" class="invalid">
              Debe cargar al menos un archivo con un peso máximo de 20MB.
            </div>
            <button
            class="btn btn-primary"
            type="button"
            (click)="subirDocumentos()"
            [disabled]="!filesForm.valid"
            *appHasAnyRole="[rolesAzure.SUPERADMIN, rolesAzure.SUPERVISOR, rolesAzure.ING_SOPORTE]"
          >
            <i class="fas fa-upload d-inline-block mr-2"></i>
            Subir documentos
          </button>
            <div *ngIf="campoInvalido('filesForm')" class="invalid">
              Debe cargar al menos un archivo con un peso máximo de 20MB.
            </div>
          </div> -->
          <div style="text-align: center;">
            <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Guardar información</button>
          </div>
        </form>
        <br>
        <div  class="card">
          <div class="card-body">
            <h4 class="card-title">Agregar fotografías</h4>
            <div class="row">
              <div class="col-md-8">
                <input
                  type="file"
                  (change)="onFilesSelected($event)"
                  multiple
                  accept="image/*"
                />
                <ul>
                  <li *ngFor="let file of selectedFiles">{{ file.name }}</li>
                </ul>
              </div>
              <div class="col-md-4">
                <button  class="btn btn-primary" [disabled]="!selectedFiles.length" (click)="subirArchivos()">
                  <i class="fas fa-upload d-inline-block mr-2"></i> Subir
                </button>
              </div>
            </div>
            <div
              *ngIf="showError"
              class="alert alert-danger"
              role="alert"
              [@fadeInOut]
            >
              Algunos archivos que no son imágenes o pesan más de 1.5 MB se han descartado.
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalBajaHardware" tabindex="-1" role="dialog" aria-labelledby="modalBajaHardware"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Baja de Hardware</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div id="divFactura">
            <form [formGroup]="formBajaInvHard">
              <input hidden="true" [(ngModel)]="InvHwBaja.idInventarioHw" formControlName="idInventarioHw">
              <div class="form-group">
                <label class="control-label" for="motivo">Motivo</label>
                <textarea type="text" min="1" class="form-control" id="motivoBaja" placeholder="Motivo de la baja"
                  formControlName="motivoBaja" maxlength="200"></textarea>
              </div>
              <div style="text-align: center;">
                <button type="submit" class="btn btn-primary" data-bs-dismiss="modal"
                  (click)="bajaInvHardware(this.idInvHardware)">Dar de baja</button>
              </div>
              <div class="modal-footer">
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalCaracteristicas" tabindex="-1" aria-labelledby="modalCaracteristicas"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Características del Hardware</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Característica</th>
              <th scope="col">Descripción</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let h of carsHardware">
              <td>
                {{h.caracteristica}}
              </td>
              <td>
                {{h.descripcion}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="editaCars" tabindex="-1" aria-labelledby="editaCars" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Características del Hardware</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-9"></div>
          <div class="col-2">
            <div>
              <span class="btn btn-primary" #btnAgregarCar (click)="clicAgregarCar()" data-bs-toggle="modal"
                data-bs-target="#modalAgregaCars">Agregar</span>
            </div>
          </div>
          <div class="col-1"></div>
        </div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Característica</th>
              <th scope="col">Descripción</th>
              <th scope="col">Eliminar</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let h of carsEditar; let i=index">
              <td>
                {{h.caracteristica}}
              </td>
              <td>
                {{h.descripcion}}
              </td>
              <td>
                <button class="btn btn-danger" (click)="eliminaCaracteristica(i)" style="margin-left: 5px;"><i
                    class="feather icon-trash-2 iconos-acciones"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="text-align: center;">
          <button type="submit" class="btn btn-primary" (click)="guardaCars()" data-bs-dismiss="modal">Guardar</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalAgregaCars" tabindex="-1" role="dialog" aria-labelledby="modalAgregaCars"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Elegir Caracteristicas</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <div class="input-group mb-3">
              <input type="text" class="form-control" #txtBuscaCars placeholder="Buscar Característica"
                aria-label="Buscar Característica" aria-describedby="basic-addon2"
                (keyup.enter)="buscaCaracteristicas()">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="buscaCaracteristicas()"><i
                    class="feather icon-search"></i></button>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>

        <div class="col-md-12">
          <table class="table table-bordered" *ngIf="hayCarsTipoHw">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Característica</th>
                <th scope="col">Descripción</th>
                <th scope="col">Elegir</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let h of carsTipoHw; let i=index">
                <td>
                  {{h.idCaracteristica}}
                </td>
                <td>
                  {{h.caracteristica}}
                </td>
                <td>
                  {{h.descripcion}}
                </td>
                <td>
                  <button class="btn btn-success" (click)="eligeCar(i)" data-bs-toggle="modal"
                    data-bs-target="#editaCars" style="margin-left: 5px;">Elegir</button>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table table-bordered" *ngIf="hayCarsTipoHw == false">
            <thead>
              <tr>
                <th scope="col">Hardware</th>
                <th scope="col">Marca</th>
                <th scope="col">Modelo</th>
                <th scope="col"># Serie</th>
                <th scope="col">TAG</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="center" colspan="5">
                  No hay Caracteristicas registradas para este tipo de hardware
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Asignación-->
<div class="modal fade" id="modalNuevaAsignacion" tabindex="-1" role="dialog" aria-labelledby="modalNuevoCatSoftware"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Asignación de inventario de hardware</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formAsignacionHw" (submit)="guardaAsignacionHw()">
          <div class="row">
            <div class="col-6">
              <label for="divIdUsuario" class="control-label is-required">Usuario</label>
              <div class="input-group mb-3" id="divIdUsuario">
                <input type="text" class="form-control" id="idUsuario" placeholder="Usuario" formControlName="usuario"
                  [(ngModel)]="usuarioElegido.nombreEmpleado" disabled>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" data-bs-target="#modalEligeUsuario"
                    data-bs-toggle="modal"><i class="feather icon-search"></i></button>
                </div>
                <input type="hidden" formControlName="idUsuario" [(ngModel)]="usuarioElegido.numeroEmpleado">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="idGrupoPermiso" class="control-label is-required">Ubicación</label>
                <select class="form-control" name="Ubicacion" id="Ubicacion" formControlName="idUbicacion"
                  (change)="validaBtnAsignarHw()">
                  <option value="" disabled selected>Elige la ubicación</option>
                  <option [value]=gp.idUbicacion *ngFor="let gp of ubicacion">{{gp.nombreUbicacion}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-3"></div>
            <div class="col-7">
              <div class="form-group">
                <label class="control-label is-required" for="total">Motivo</label>
                <textarea type="number" min="1" class="form-control" (keypress)="noSpecialChar($event)" id="motivo"
                  (change)="validaBtnAsignarHw()" placeholder="Motivo" formControlName="motivo"></textarea>
              </div>
            </div>
            <div class="col-3"></div>
          </div>
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4" style="text-align: center;">
              <button class="btn btn-primary" [disabled]="btnAsignarHw" data-bs-dismiss="modal" style="margin-left: 5px;">Asignar</button>
            </div>
            <div class="col-4"></div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>

<!-- Modal Usuario-->
<div class="modal fade" id="modalEligeUsuario" tabindex="-1" aria-labelledby="modalEligeUsuario" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Elegir Usuario</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal"
          data-bs-target="#modalNuevaAsignacion" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <div class="input-group mb-3">
              <input type="text" class="form-control" #txtBuscaUsuario placeholder="Buscar Usuario"
                aria-label="Buscar Usuario" aria-describedby="basic-addon2" (keyup.enter)="buscaUsuario()">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="buscaUsuario()"><i
                    class="feather icon-search"></i></button>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="buscaUsu" style="overflow: auto;">
        <table class="table table-bordered" *ngIf="usuarios.length > 0">
          <thead>
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Número</th>
              <th scope="col">Correo</th>
              <th scope="col">Elegir</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let per of usuarios">
              <td>
                {{per.nombreEmpleado}}
              </td>
              <td>
                {{per.numeroEmpleado}}
              </td>
              <td>
                {{per.correo}}
              </td>
              <td style="text-align: center;">
                <button class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#modalNuevaAsignacion"
                  (click)="eligeUsuario(per.numeroEmpleado)">Elegir <i
                    class="feather icon-edit iconos-acciones"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table table-bordered" *ngIf="usuarios.length == 0">
          <thead>
            <tr>
              <th scope="col">Usuario</th>
              <th scope="col">Elegir</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center" colspan="2">
                No hay resultados
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>