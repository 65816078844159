<div class="shadow p-3 mb-5 bg-body rounded">
  <div style="text-align: center;">
      <span><h1>Catálogo de Software</h1></span>
  </div>
<div class="row">
  <div class="col-md-1"></div>
  <div class="col-md-8">
      <div class="input-group mb-3">
        <input type="text" class="form-control"  (change)="muestraSoftware()" placeholder="Buscar Software" #txtBuscaSoftware aria-label="Buscar Software" aria-describedby="basic-addon2" (keyup.enter)="muestraSoftware()">
        <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="muestraSoftware()"><i class="feather icon-search"></i></button>
        </div>
      </div>
  </div>
  <div class="col-md-2"
  *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              ]">
      <div class="div-btn-nuevo-hardware">
          <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalNuevoCatSoftware">Agregar</button>
      </div>
  </div>
  <div class="col-md-1"></div>
</div>



<div style="overflow: auto;" *ngIf="!cargarLoader">
  <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Nombre</th>
          <th scope="col">Descripcion</th>
          <th scope="col">Tipo</th>
          <th scope="col">Marca</th>
          <th scope="col"
          *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              ]">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let s of software">
            <td>
                    {{s.nombre}}
            </td>
            <td>
                    {{s.descripcion}}
            </td>
            <td>
                    {{s.tipoSW}}
            </td>
            <td>
                    {{s.marca}}
            </td>
            <td style="text-align: center;"
            *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              ]">
                <button class="btn btn-warning" (click)="abreEditaCatSoftware(s.idSoftware)" data-bs-toggle="modal" data-bs-target="#modalEditaCatSoftware">Editar<i class="feather icon-edit iconos-acciones"></i></button>
            </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-start align-items-center p-2">
      <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" (pageChange)="loadPage($event)" [disabled]="software == null || software.length == 0">
        <ng-template ngbPaginationPrevious>Anterior</ng-template>
        <ng-template ngbPaginationNext>Siguiente</ng-template>
      </ngb-pagination>
    
      <div class="d-flex align-items-center ml-3">
        <label for="pageInput" class="mr-2">Buscar página específica:</label>
        <input id="pageInput" type="number" class="form-control w-auto"
               #pageInput
               (change)="loadPage(pageInput.value)"
               (keyup.enter)="loadPage(pageInput.value)"
               onkeypress="return(event.charCode >= 48 && event.charCode <= 57)"
               placeholder="" aria-label="Buscar página específica" />
      </div>
    </div>
  </div>    
</div>


<!-- Modal -->
<div class="modal fade" id="modalNuevoCatSoftware" tabindex="-1" aria-labelledby="modalNuevoCatSoftware" aria-hidden="true">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="modalNuevaCaracteristica">Agregar nuevo catalogo de software</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
    </div>
    <div class="modal-body">
        <form [formGroup]="formCatSoftware" (submit)="guardaCatSoftware()">

            <div class="form-group"> 
                <label class="control-label is-required" for="nombreCar">Nombre</label>
                <input type="text" class="form-control"maxlength="200" (keypress)="noSpecialCharNombre($event)" id="nombreCar" placeholder="nombre" formControlName="nombre">
            </div>   

            <div class="form-group"> 
                <label class="control-label is-required" for="descCar">Descripción</label>
                <input type="text" class="form-control"maxlength="200" id="descCar" (keypress)="noSpecialChar($event)" placeholder="Descripción" formControlName="descripcion">
            </div>                
                                    
            <div class="form-group"> 
                <label for="state_id" class="control-label is-required">Tipo de Software</label>
                <select class="form-control" name="tipoSw" id="tipoSw" formControlName="idTipoSw">
                    <option value="" disabled selected>Elige tipo de Software</option>
                    <option [value]=tiposw.idTipoSw *ngFor="let tiposw of tiposw">{{tiposw.nombre}}</option>
                </select>                    
            </div>

            <div class="form-group"> 
                <label for="state_id" class="control-label is-required">Marca</label>
                <select class="form-control" name="marca" id="marca" formControlName="idMarca">
                    <option value="" disabled selected>Elige la marca</option>
                    <option [value]=marca.idMarca *ngFor="let marca of marcas">{{marca.nombreMarca}}</option>
                </select>                    
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="submit" [disabled]="formCatSoftware.invalid" data-bs-dismiss="modal" class="btn btn-primary" (click)="guardaCatSoftware()">Guardar</button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
    </div>
  </div>
</div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalEditaCatSoftware" tabindex="-1" aria-labelledby="modalNuevoCatSoftware" aria-hidden="true">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="modalNuevaCaracteristica">Editar catalogo de software</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
    </div>
    <div class="modal-body">
        <form [formGroup]="formEditaCatSoftware" (submit)="editaCatSoftware()">
          <input hidden="true" [(ngModel)]="softwareEditar.idSoftware"  formControlName="idSoftware">
            <div class="form-group"> 
                <label class="control-label is-required" for="nombreCar">Nombre</label>
                <input type="text" class="form-control"maxlength="200" (keypress)="noSpecialCharNombre($event)" id="nombreCar" placeholder="nombre" formControlName="nombre" [(ngModel)]="softwareEditar.nombre">
            </div>   

            <div class="form-group"> 
                <label class="control-label is-required" for="descCar">Descripción</label>
                <input type="text" class="form-control"maxlength="200" id="descCar" (keypress)="noSpecialChar($event)" placeholder="Descripción" formControlName="descripcion" [(ngModel)]="softwareEditar.descripcion">
            </div>                
                                    
            <div class="form-group"> 
                <label for="state_id" class="control-label is-required">Tipo de Software</label>
                <select class="form-control" name="tipoSw" id="tipoSw" formControlName="idTipoSw"  [(ngModel)]="softwareEditar.idTipoSw">
                    <option value="" disabled selected>Elige tipo de Software</option>
                    <option [value]=tiposw.idTipoSw *ngFor="let tiposw of tiposw" [selected]="tiposw.idTipoSw == softwareEditar.idTipoSw">{{tiposw.nombre}}</option>
                </select>                    
            </div>

            <div class="form-group"> 
                <label for="state_id" class="control-label is-required">Marca</label>
                <select class="form-control" name="marca" id="marca" formControlName="idMarca" [(ngModel)]="softwareEditar.idMarca">
                    <option value="" disabled selected>Elige la marca</option>
                    <option [value]=marca.idMarca *ngFor="let marca of marcas" [selected]="marca.idMarca == softwareEditar.idMarca">{{marca.nombreMarca}}</option>
                </select>                    
            </div>
              
            <div style="text-align: center;"> 
              <button type="submit" [disabled]="formEditaCatSoftware.invalid" data-bs-dismiss="modal" class="btn btn-primary">Guardar</button>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
          </div>  
        </form>
    </div>
    <div class="modal-footer">
    </div>
  </div>
</div>
</div>