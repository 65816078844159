import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './view/layout/admin/admin.component';
import { AuthGuard } from './data/guard/auth.guard';
import { HardwareComponent } from './view/pages/hardware/hardware.component';
import { SoftwareComponent } from './view/pages/software/software.component';
import { AsignacionHardwareComponent } from './view/pages/asignacion-hardware/asignacion-hardware.component';
import { AsignacionSoftwareComponent } from './view/pages/asignacion-software/asignacion-software.component';
import { ReportesComponent } from './view/pages/reportes/reportes.component';
import { CatalogoHardwareComponent } from './view/pages/catalogo-hardware/catalogo-hardware.component';
import { CatalogoSoftwareComponent } from './view/pages/catalogo-software/catalogo-software.component';
import { ControlUsuariosComponent } from './view/pages/control-usuarios/control-usuarios.component';
import { FacturasSoftwareComponent } from './view/pages/facturas-software/facturas-software.component';
import { ControlLicenciasComponent } from './view/pages/control-licencias/control-licencias.component';
import { CatalogoPermisosComponent } from './view/pages/catalogo-permisos/catalogo-permisos.component';
import { CatalogoDocumentosComponent } from './view/pages/catalogo-documentos/catalogo-documentos.component';
import { CatalogoCaracteristicasComponent } from './view/pages/catalogo-caracteristicas/catalogo-caracteristicas.component';
import { CatalogoMarcasComponent } from './view/pages/catalogo-marcas/catalogo-marcas.component';
import { CatalogoTipoHwComponent } from './view/pages/catalogo-tipo-hw/catalogo-tipo-hw.component';
import { CatalogoTipoSwComponent } from './view/pages/catalogo-tipo-sw/catalogo-tipo-sw.component';
import { CatalogoAntenasComponent } from './view/pages/catalogo-antenas/catalogo-antenas.component';
import { CatalogoUbicacionesComponent } from './view/pages/catalogo-ubicaciones/catalogo-ubicaciones.component';
import { CatalogoSituacionesComponent } from './view/pages/catalogo-situaciones/catalogo-situaciones.component';
import { GrupoPermisoComponent } from './view/pages/grupo-permiso/grupo-permiso.component';
import { ReAsignacionSoftwareComponent } from './view/pages/re-asignacion-software/re-asignacion-software.component';
import { RepAsignacionUbicacionSwComponent } from './view/pages/rep-asignacion-ubicacion-sw/rep-asignacion-ubicacion-sw.component';
import { RepAsignacionUbicacionHwComponent } from './view/pages/rep-asignacion-ubicacion-hw/rep-asignacion-ubicacion-hw.component';
import { DisponibleAsignarSwComponent } from './view/pages/disponible-asignar-sw/disponible-asignar-sw.component';
import { DisponibleAsignarHwComponent } from './view/pages/disponible-asignar-hw/disponible-asignar-hw.component';
import { HistorialAsignacionHwComponent } from './view/pages/historial-asignacion-hw/historial-asignacion-hw.component';
import { HistorialAsignacionSwComponent } from './view/pages/historial-asignacion-sw/historial-asignacion-sw.component';
import { ReporteInventarioHwComponent } from './view/pages/reporte-inventario-hw/reporte-inventario-hw.component';
import { ReporteBajaHwComponent } from './view/pages/reporte-baja-hw/reporte-baja-hw.component';
import { ReporteVencimientoLicenciasComponent } from './view/pages/reporte-vencimiento-licencias/reporte-vencimiento-licencias.component';
import { ReporteValorInventarioHwComponent } from './view/pages/reporte-valor-inventario-hw/reporte-valor-inventario-hw.component';
import { ReporteValorInventarioSwComponent } from './view/pages/reporte-valor-inventario-sw/reporte-valor-inventario-sw.component';
import { ReporteInventarioSwComponent } from './view/pages/reporte-inventario-sw/reporte-inventario-sw.component';
import { PdfAltaAsignacionComponent } from './view/pages/pdf-alta-asignacion/pdf-alta-asignacion.component';
import { ReporteCaracteristicasComponent } from './view/pages/reporte-caracteristicas/reporte-caracteristicas.component';
import { DesasignacionHwComponent } from './view/pages/desasignacion-hw/desasignacion-hw.component';
import { DesasignacionSwComponent } from './view/pages/desasignacion-sw/desasignacion-sw.component';
import { Roles } from './model/variables';
import { MicrosoftLoginGuard } from './data/guard/microsoft-login.guard';
import { MicrosoftRolesGuard } from './data/guard/microsoft-roles.guard';
import { CatalogoDesinoNotificacionesComponent } from './view/pages/catalogo-desino-notificaciones/catalogo-desino-notificaciones.component';
import { HistorialAntenasComponent } from './view/pages/historial-antenas/historial-antenas.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./view/pages/login/login.module').then(module => module.LoginModule),
  },
  {
    path: '',
    component: AdminComponent,
    canActivate: [MicrosoftLoginGuard],
    children: [
      {
        path: '',
        redirectTo: 'inicio',
        pathMatch: 'full'
      },
      {
        path: 'inicio',
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        canActivate: [MicrosoftRolesGuard],
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'software',
        component: SoftwareComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
          canActivate: [MicrosoftRolesGuard],
          data: {
            rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
          }
      },
      {
        path: 'hardware',
        component: HardwareComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        canActivate: [MicrosoftRolesGuard],
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'asignacionHardware',
        component: AsignacionHardwareComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'asignacionSoftware',
        component: AsignacionSoftwareComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'admin',
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN]
        },
      },
      {
        path: 'reportes',
        component: ReportesComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'catalogoSoftware',
        component: CatalogoSoftwareComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'catalogoHardware',
        component: CatalogoHardwareComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'facturasSoftware',
        component: FacturasSoftwareComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
     {
        path: 'controlUsuarios',
        component: ControlUsuariosComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'controlVencimientos',
        component: ControlLicenciasComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'catalogoPermisos',
        component: CatalogoPermisosComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'catalogoDocumentos',
        component: CatalogoDocumentosComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'catalogoCaracteristicas',
        component: CatalogoCaracteristicasComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'catalogoMarca',
        component: CatalogoMarcasComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'catalogoTipoHw',
        component: CatalogoTipoHwComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'catalogoTipoSw',
        component: CatalogoTipoSwComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'catalogoAntenas',
        component: CatalogoAntenasComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'catalogoUbicaciones',
        component: CatalogoUbicacionesComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'catalogoSituaciones',
        component: CatalogoSituacionesComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR]
        },
      },
      {
        path: 'grupoPermisos',
        component: GrupoPermisoComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'reAsignacionSoftware',
        component: ReAsignacionSoftwareComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'repAsignacionUbicacionSw',
        component: RepAsignacionUbicacionSwComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'repAsignacionUbicacionHw',
        component: RepAsignacionUbicacionHwComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'disponibleAsignarSw',
        component: DisponibleAsignarSwComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'disponibleAsignarHw',
        component: DisponibleAsignarHwComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'historialAsignacionSw',
        component: HistorialAsignacionSwComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'historialAsignacionHw',
        component: HistorialAsignacionHwComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'reporteInventarioHw',
        component: ReporteInventarioHwComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'reporteInventarioSw',
        component: ReporteInventarioSwComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'reporteBajaHw',
        component: ReporteBajaHwComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'reporteVencimientoLicencias',
        component: ReporteVencimientoLicenciasComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'reporteValorInventarioHw',
        component: ReporteValorInventarioHwComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'reporteValorInventarioSw',
        component: ReporteValorInventarioSwComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'pdfAltaAsignacion',
        component: PdfAltaAsignacionComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'reporteCaracteristicas',
        component: ReporteCaracteristicasComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'desasignarHw',
        component: DesasignacionHwComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'desasignarSw',
        component: DesasignacionSwComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'catalogoDestinoNotificaciones',
        component: CatalogoDesinoNotificacionesComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
      {
        path: 'historialAntenas',
        component: HistorialAntenasComponent,
        loadChildren: () => import('./view/pages/inicio/inicio.module').then(module => module.InicioModule),
        data: {
          rolUsuario: [Roles.SUPERADMIN, Roles.SUPERVISOR, Roles.ING_SOPORTE]
        }
      },
    ]
  },
  {
    path: '**',
    redirectTo :'inicio'
  }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true, // <- Indicar que se use el hash
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
