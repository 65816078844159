import { AfterViewInit, Component, ElementRef, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators                  } from '@angular/forms';
import { formatDate, registerLocaleData                      } from "@angular/common";
import { HttpClient                                          } from '@angular/common/http';
import   localeEs                                              from "@angular/common/locales/es";
import   Swal                                                  from 'sweetalert2';

import { environment } from 'src/environments/environment';
import { invSoftware } from 'src/app/model/invSoftware';
import { Software } from '../../../model/Software';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { Roles } from 'src/app/model';

registerLocaleData(localeEs, "es");

@Component({
  selector:     'app-software',
  templateUrl:  './software.component.html',
  styleUrls:   ['./software.component.scss'],
  providers:   [{ provide: LOCALE_ID, useValue: "es" }],
})
export class SoftwareComponent implements OnInit, AfterViewInit {

  @ViewChild('txtBuscaInvSoft', {static: false}) txtBuscaInvSoft!:ElementRef<HTMLInputElement>;

  
  public formEditaInv : FormGroup;
  public formBajaInvSoft : FormGroup;

  public invSoftware: invSoftware[] = [];
  public invSoftwaree: any[] = [];
  public catSoftware: Software[];

  //#region PAGINATION
  public page : number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader:boolean = true;
  //#endregion
  public idInvSoftware = 0;

  public rolesAzure = Roles;
  
  invSwEditar: any = {
        idInventarioSw: null,
        vencimiento:    null,
        temporalidad:   null,
        idSoftware:     null,
        estatus:        true,
        motivoBaja:     '',
        lote:           '',
        stock:          null,
        nombreSw:       '',
        auxiliar:       ''
  }

  InvSwBaja: any = {
    idInventarioSw: null,
    motivoBaja: "",
  }

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService) { 
    this.formEditaInv = this.formBuilder.group({
      idInventarioSw: ['',[Validators.required]],
      auxiliar: ['',[Validators.required]],
      temporalidad: ['',[Validators.required]],
      vencimiento: ['',[Validators.required]],
      nombreSw: ['',[Validators.required]],
    })

    this.formBajaInvSoft = this.formBuilder.group({
      idInventarioSw: ['',[Validators.required]],
      motivoBaja: ['',[Validators.required]],
    })
  }

  ngOnInit() {
    this.muestraTipoSw()
  }

  ngAfterViewInit(): void { this.muestraInvSoftware(); }

  muestraTipoSw(){
    this.inventarioService.getSoftware().subscribe((respSoftware:any) =>{
       this.catSoftware = respSoftware.data;
    })
  }

  muestraInvSoftware() {
    this.invSoftwaree = [];
    this.totalRecords = 0;
    this.cargarLoader = true;
    if (this.txtBuscaInvSoft.nativeElement.value === '') {
      this.inventarioService.getInventarioSwLimit(this.page).subscribe((invSoft: any) => {
        this.invSoftwaree = invSoft.data;
        this.totalRecords = invSoft.totalRecords;
        this.cargarLoader = false;
      }, error => {
        Swal.fire({
          title: "ERROR",
          text: `Error ${error}`,
          type: "warning"
        })
        this.cargarLoader = false;
      })
    } else {
      let datoPostInvSoft = {
        "nombreTabla": "invsw",
        "valorBusqueda": this.txtBuscaInvSoft.nativeElement.value,
        "Page": this.page
      }
      this.inventarioService.getInvSwTablaCampo(datoPostInvSoft).subscribe((respInvSoft: any) => {
        if (respInvSoft['result'] === 0) {
          if (!Swal.isVisible()) {
            Swal.fire({
              title: "ERROR",
              text: "No hay coincidencias con tu criterio de búsqueda",
              type: "warning"
            }).then(() => {
              if (this.page != 1) {
                this.page = 1;
                this.muestraInvSoftware();
              } else {
                this.txtBuscaInvSoft.nativeElement.value = "";
                this.page = 1;
                this.muestraInvSoftware();
              }
            })
          }
        } else {
          this.invSoftwaree = respInvSoft.data;
          this.totalRecords = respInvSoft.totalRecords;
          this.cargarLoader = false;
        }
      })
    }
  }

  cambioBuscaInvSoftInput(){
    if (this.txtBuscaInvSoft.nativeElement.value === '') { this.page = 1; this.muestraInvSoftware(); }
  }

  buscaInvSoftInput(){
    if (this.txtBuscaInvSoft.nativeElement.value !== '') { this.page = 1; this.muestraInvSoftware(); }
  }

  abreEditaSoftware(idInv){
        this.inventarioService.getInvSwXId(idInv).subscribe((resped:any) =>{
           this.invSwEditar=resped.data[0]
           this.invSwEditar.vencimiento = formatDate(this.invSwEditar.vencimiento,'yyy-MM-dd','en-US')
        })
  }

  abreBajaSoftware(idInventarioSw){
      this.idInvSoftware = idInventarioSw
  }

  editaInvSoft(){
      this.inventarioService.editInvSw(this.formEditaInv.value).subscribe(data => {
        if (data['result'] === 1) {
          this.formEditaInv.reset();
          this.muestraInvSoftware()
          Swal.fire({
            title: "Guardado",
            text: "Se ha registrado exitosamente",
            type: "success"
          })
        }else{
          Swal.fire({
            title: "ERROR",
            text: data['message'],
            type: "warning"
          })
        }
        
    })   

  }

  bajaInvSoftware(idInvSoftware){
    this.InvSwBaja = this.formBajaInvSoft.value
    this.InvSwBaja.idInventarioSw = idInvSoftware
      this.inventarioService.bajaInvSw(this.InvSwBaja).subscribe(data => {
        if (data['result'] === 1) {
          this.formBajaInvSoft.reset();
          this.muestraInvSoftware()
          Swal.fire({
            title: "Guardado",
            text: "Se ha registrado exitosamente",
            type: "success",
            timer: 3000
          })
        }else{
          Swal.fire({
            title: "ERROR",
            text: data['message'],
            type: "warning",
            timer: 2000
          })
        }
    }, error => {
    })   
  } 

  loadPage(page: number) {
    this.page = page;
    this.muestraInvSoftware();
  }
}
