import { MAT_DATE_LOCALE } from '@angular/material/core'

import { NgModule } from '@angular/core';

import { MatButtonModule          } from '@angular/material/button';
import { MatDatepickerModule      } from '@angular/material/datepicker';
import { MatDialogModule          } from '@angular/material/dialog';
import { MatFormFieldModule       } from '@angular/material/form-field';
import { MatGridListModule        } from '@angular/material/grid-list';
import { MatIconModule            } from '@angular/material/icon';
import { MatInputModule           } from '@angular/material/input';
import { MatListModule            } from '@angular/material/list';
import { MatPaginatorModule       } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule          } from '@angular/material/select';
import { MatSidenavModule         } from '@angular/material/sidenav';
import { MatSnackBarModule        } from '@angular/material/snack-bar';
import { MatSortModule            } from '@angular/material/sort';
import { MatTableModule           } from '@angular/material/table';
import { MatTabsModule            } from '@angular/material/tabs';
import { MatToolbarModule         } from '@angular/material/toolbar';


@NgModule({
  exports: [
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ]
})

export class MaterialModule { }
