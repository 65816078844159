<div class="shadow p-3 mb-5 bg-body rounded">
  <div style="text-align: center;">
    <span>
      <h1>Catálogo de Documentos</h1>
    </span>
  </div>
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-5">
      <div class="input-group mb-3">
        <input type="text" class="form-control" #txtBuscaDocumento (change)="cambioBuscaCtlDocumentoInput()"
          placeholder="Buscar Catálogo de Documentos" #txtBuscaSoftware aria-label="Buscar Catálogo de Documentos"
          aria-describedby="basic-addon2" (keyup.enter)="buscaCtlDocumentoInput()">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="buscaCtlDocumentoInput()"><i
              class="feather icon-search"></i></button>
        </div>
      </div>
    </div>
    <div class="col-md-2"
    *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
      <div class="div-btn-nuevo-hardware">
        <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalNuevoCatSoftware">Agregar</button>
      </div>
    </div>
    <div class="col-md-2"></div>
  </div>

  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-9" *ngIf="!cargarLoader">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Tipo</th>
            <th scope="col"
            *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let s of tipoDocumentos">
            <td>
              {{s.nombreDocumento}}
            </td>
            <td>
              {{s.tipoDocumento}}
            </td>
            <td style="text-align: center;"
            *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              rolesAzure.ING_SOPORTE
              ]">
              <button class="btn btn-warning" (click)="abreEditarTipoDocumentos(s.idTipoDocumento)"
                data-bs-toggle="modal" data-bs-target="#modalEditaCatDoc">Editar<i
                  class="feather icon-edit iconos-acciones"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <pagination-controls (pageChange)="page = $event" previousLabel="Anterior" S nextLabel="Siguiente"></pagination-controls> -->
      <div class="d-flex justify-content-start align-items-center p-2">
        <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
          (pageChange)="loadPage($event)" [disabled]="tipoDocumentos == null || tipoDocumentos.length == 0">
          <ng-template ngbPaginationPrevious>Anterior</ng-template>
          <ng-template ngbPaginationNext>Siguiente</ng-template>
        </ngb-pagination>

        <div class="d-flex align-items-center ml-3">
          <label for="pageInput" class="mr-2">Buscar página específica:</label>
          <input id="pageInput" type="number" class="form-control w-auto" #pageInput
            (change)="loadPage(pageInput.value)" (keyup.enter)="loadPage(pageInput.value)"
            onkeypress="return(event.charCode >= 48 && event.charCode <= 57)" placeholder=""
            aria-label="Buscar página específica" />
        </div>
      </div>

    </div>
    <div class="col-md-2"></div>
  </div>

</div>




<!-- Modal -->
<div class="modal fade" id="modalNuevoCatSoftware" tabindex="-1" aria-labelledby="modalNuevoCatSoftware"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalNuevaCaracteristica">Agregar</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formCatDocumento" (submit)="guardaCatDocumento()">

          <div class="form-group"> <!-- Full Name -->
            <label class="control-label is-required" for="nombreDoc">Nombre</label>
            <input type="text" class="form-control" maxlength="200" (keypress)="noSpecialChar($event)" id="nombreDoc"
              placeholder="nombre" formControlName="nombreDocumento">
          </div>

          <div class="form-group"> <!-- Full Name -->
            <label class="control-label is-required" for="tipoDoc">Tipo de Documento</label>
            <!-- <input type="text" class="form-control"maxlength="1" (keypress)="noSpecialChar($event)" id="tipoDoc" placeholder="Tipo de Documento" formControlName="tipoDocumento"> -->

            <select class="form-control" name="tipoDoc" id="tipoDoc" formControlName="tipoDocumento">
              <option value="" disabled selected>Elige tipo de Documento</option>
              <option value="A">A</option>
              <option value="F">F</option>
            </select>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="submit" [disabled]="formCatDocumento.invalid" data-bs-dismiss="modal" class="btn btn-primary"
          (click)="guardaCatDocumento()">Guardar</button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
        <!-- valido: {{formCaracteristica.valid | json}} -->
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="modalEditaCatDoc" tabindex="-1" aria-labelledby="modalEditaCatDocumentos"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalEditaDocumentos">Agregar</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formEditaCatDocumento" (submit)="editaCatDocumento()">
          <input hidden="true" [(ngModel)]="documentoEditar.idTipoDocumento" formControlName="idTipoDocumento"
            [(ngModel)]="documentoEditar.idTipoDocumento">
          <div class="form-group"> <!-- Full Name -->
            <label class="control-label is-required" for="nombreDoc">Nombre</label>
            <input type="text" class="form-control" maxlength="200" (keypress)="noSpecialChar($event)" id="nombreDoc"
              placeholder="nombre" formControlName="nombreDocumento" [(ngModel)]="documentoEditar.nombreDocumento">
          </div>

          <div class="form-group"> <!-- Full Name -->
            <label class="control-label is-required" for="tipoDoc">Tipo de Documento</label>
            <!-- <input type="text" class="form-control"maxlength="1" (keypress)="noSpecialChar($event)" id="tipoDoc" placeholder="Tipo de Documento" formControlName="tipoDocumento"> -->

            <select class="form-control" name="tipoDoc" id="tipoDoc" formControlName="tipoDocumento"
              [(ngModel)]="documentoEditar.tipoDocumento">
              <option value="" disabled selected>Elige tipo de Documento</option>
              <option value="A" [selected]="documentoEditar.tipoDocumento == 'A'">A</option>
              <option value="F" [selected]="documentoEditar.tipoDocumento == 'F'">F</option>
            </select>
          </div>
          <div style="text-align: center;">
            <button type="submit" [disabled]="formEditaCatDocumento.invalid" data-bs-dismiss="modal"
              class="btn btn-primary">Guardar</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
          </div>
        </form>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>