<div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
        <div class="input-group mb-3">
            <input type="text" class="form-control" (change)="cambioBuscaInvSoftInput()" #txtBuscaInvSoft  placeholder="Buscar Software" aria-label="Buscar Software" aria-describedby="basic-addon2" (keyup.enter)="buscaInvSoftInput()">
            <div class="input-group-append">
            <button class="btn btn-outline-secondary" (click)="buscaInvSoftInput()" type="button"><i class="feather icon-search"></i></button>
            </div>
        </div>
    </div>
    <div class="col-md-2">
    </div>
</div>
<div style="overflow: auto;" *ngIf="!cargarLoader">
    <table class="table table-bordered">
        <thead>
        <tr>
            <th scope="col">Software</th>
            <th scope="col">Nombre</th>
            <th scope="col">Auxiliar</th>
            <th scope="col">Stock</th>
            <th scope="col">Temporalidad</th>
            <th scope="col">Vencimiento</th>
            <th scope="col"
            *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              ]"
            >Acciones</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let inv of invSoftwaree">
            <td>
                    {{inv.software}}
            </td>
            <td>
                    {{inv.nombreSw}}
            </td>
            <td>
                    {{inv.auxiliar}}
            </td>
            <td>
                    {{inv.stock}} de {{inv.cantidad}}
            </td>
            <td>
                    {{inv.temporalidad}} Meses
            </td>
            <td>
                    {{inv.vencimiento | date:'longDate'}}
            </td>
            <td style="text-align: center;"
            *appHasAnyRole="[
              rolesAzure.SUPERADMIN, 
              rolesAzure.SUPERVISOR, 
              ]"
              >
            <button class="btn btn-warning" (click)="abreEditaSoftware(inv.idInventarioSw)" data-bs-toggle="modal" data-bs-target="#modalEditaSoftware">Editar  <i class="feather icon-edit iconos-acciones"></i></button>
            <button class="btn btn-danger"  (click)="abreBajaSoftware(inv.idInventarioSw)" data-bs-toggle="modal" data-bs-target="#modalBajaFactura" style="margin-left: 5px;">Baja  <i class="feather icon-trash-2 iconos-acciones"></i></button>
        </td>
        </tbody>
    </table>

    <div class="d-flex justify-content-start align-items-center p-2">
        <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" (pageChange)="loadPage($event)" [disabled]="invSoftwaree == null || invSoftwaree.length == 0">
          <ng-template ngbPaginationPrevious>Anterior</ng-template>
          <ng-template ngbPaginationNext>Siguiente</ng-template>
        </ngb-pagination>
      
        <div class="d-flex align-items-center ml-3">
          <label for="pageInput" class="mr-2">Buscar página específica:</label>
          <input id="pageInput" type="number" class="form-control w-auto"
                 #pageInput
                 (change)="loadPage(pageInput.value)"
                 (keyup.enter)="loadPage(pageInput.value)"
                 onkeypress="return(event.charCode >= 48 && event.charCode <= 57)"
                 placeholder="" aria-label="Buscar página específica" />
        </div>
      </div>
</div>


  
  <div class="modal fade" id="modalEditaSoftware" tabindex="-1" role="dialog" aria-labelledby="modalEditaFactura" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalNuevaCaracteristica">Modificar Inventario de Software - {{invSwEditar.nombreSw}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
            <div class="container">
                <div id="divFactura">
                    <form [formGroup]="formEditaInv" (submit)="editaInvSoft()">
                        <input hidden="true" [(ngModel)]="invSwEditar.idInventarioSw"  formControlName="idInventarioSw">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group"> 
                                 <div class="form-group"> 
                                    <label class="control-label is-required" for="vencimiento">Vencimiento</label>
                                    <input type="date" class="form-control" id="vencimiento" placeholder="Vencimiento" formControlName="vencimiento" [(ngModel)]="invSwEditar.vencimiento">
                              </div>
                            </div>   
                        </div>
                        <div class="col-md-6">
                            <div class="form-group"> 
                                <label class="control-label" for="proveedor">Nombre</label>
                                <input type="text" class="form-control" id="nombreSw" placeholder="Nombre de Software" formControlName="nombreSw" [(ngModel)]="invSwEditar.nombreSw">
                            </div>   
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group"> 
                                <label class="control-label" for="subtotal">Auxiliar</label>
                                <input type="text" class="form-control" id="auxiliar" placeholder="Auxiliar" formControlName="auxiliar" [(ngModel)]="invSwEditar.auxiliar">
                            </div>   
                        </div>
                        <div class="col-md-6">
                            <div class="form-group"> 
                            <label class="control-label" for="total">Temporalidad (Meses)</label>
                                <input type="number" min="1" class="form-control" id="temporalidad" placeholder="Temporalidad" formControlName="temporalidad" [(ngModel)]="invSwEditar.temporalidad">
                            </div>   
                        </div>
                    </div>
                    <div style="text-align: center;">
                        <button type="submit" class="btn btn-primary"  data-bs-dismiss="modal" >Guardar</button>
                    </div>
                <!-- <hr> -->
                <div class="modal-footer">
                </div>
            </form>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="modalBajaFactura" tabindex="-1" role="dialog" aria-labelledby="modalBajaFactura" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalNuevaCaracteristica">Baja de Software</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
            <div class="container">
                <div id="divFactura">
                    <form [formGroup]="formBajaInvSoft">
                        <input hidden="true" [(ngModel)]="InvSwBaja.idInventarioSw"  formControlName="idInventarioSw">
                        <div class="form-group"> 
                            <label class="control-label" for="motivo">Motivo</label>
                            <textarea type="text" min="1" class="form-control" id="motivoBaja" placeholder="Motivo de la baja" formControlName="motivoBaja" maxlength="200"></textarea>
                        </div>   
                        <div style="text-align: center;">
                            <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" (click)="bajaInvSoftware(this.idInvSoftware)">Dar de baja</button>
                        </div>
                        <div class="modal-footer">
                        </div>
                    </form>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>
