import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

import { environment } from 'src/environments/environment';
import { Usuario } from 'src/app/model/usuario';
import { PdfService } from 'src/app/data/service/pdf-excel/pdf.service';
import { InventarioService } from 'src/app/data/service/inventario.service';
import { Roles } from 'src/app/model';

@Component({
  selector: 'app-desasignacion-hw',
  templateUrl: './desasignacion-hw.component.html',
  styleUrls: ['./desasignacion-hw.component.scss']
})
export class DesasignacionHwComponent implements OnInit {
  private idResponsable = localStorage.getItem('employeeId');
  // public page = 0
  public page: number = 1;
  public pageSize: number = 10;
  public totalRecords: number = 0;
  public cargarLoader: boolean = true;

  private url: string = environment.api;
  public buscaUsu = false
  public usuarios: Usuario[] = [];
  usuarioElegido: any = []
  public formUsu: FormGroup;
  public asignaciones: any[] = [];
  public checks = [];
  public formBaja: FormGroup;
  public date: Date = new Date();
  public dataa: any = [['Equipo', '# Serie', '# TAG', '# Inventario', 'Caracteristicas'],];

  public rolesAzure = Roles;

  @ViewChild('txtBuscaUsuario', { static: false }) txtBuscaUsuario!: ElementRef<HTMLInputElement>;

  constructor(private _pdfService: PdfService, private http: HttpClient, private formBuilder: FormBuilder, private inventarioService: InventarioService) {
    this.formUsu = this.formBuilder.group({
      fini: ['', [Validators.required]],
      ffin: ['', [Validators.required]],
      usuario: ['', [Validators.required]],
      idUsuario: ['', [Validators.required]],
      ubicacion: ['', [Validators.required]],
    });

    this.formBaja = this.formBuilder.group({
      idInventarioSw: ['', [Validators.required]],
      motivoBaja: ['', [Validators.required]],
    })
  }

  ngOnInit() {}

  buscaUsuario() {
    let valor = this.txtBuscaUsuario.nativeElement.value;
    if (valor === '') {
      this.usuarios.length = 0
      this.buscaUsu = true
    } else {
      let datoPostUsuario = {
        "nombreTabla": "usr",
        "valorBusqueda": valor,
      }
      this.http.get(`${this.url}Usuarios/nombre/` + valor).subscribe((respUsuario: any) => {
        if (respUsuario['result'] === 0) {
          this.usuarios.length = 0
          this.buscaUsu = true
        } else {
          this.buscaUsu = true
          this.usuarios = respUsuario.data;
        }
      })
    }
  }

  eligeUsuario(idUsuario) {
    this.asignaciones = [];
    this.totalRecords = 0;
    this.cargarLoader = true;
    this.checks = [];

    this.http.get(`${this.url}Usuarios/` + idUsuario).subscribe((respUsuario: any) => {
      this.usuarioElegido = respUsuario.data;
      this.verInventario();
    })
  }

  cambioCheck(idAsignacion, check) {
    if (check == true) {
      this.checks.push(idAsignacion)
    } else {
      const filteredLibraries = this.checks.filter((item) => item !== idAsignacion)
      this.checks = filteredLibraries
    }
  }

  verInventario() {
    this.asignaciones = [];
    this.totalRecords = 0;
    this.cargarLoader = true;
    this.checks = [];
    this.formBaja.patchValue({
      idInventarioSw: '',
      motivoBaja: ''
    });

    let reporte = {
      "fini": '',
      "ffin": '',
      "idUsuario": this.usuarioElegido['numeroEmpleado'],
      "idUbicacion": 0,
      "limit": 0,
      "Page": this.page
    }
    this.inventarioService.getAsignacionesHw(reporte).subscribe((respuesta: any) => {
      if(respuesta.data != null && respuesta.data.length > 0){
        this.asignaciones = respuesta.data;
        this.totalRecords = respuesta.totalRecords;
        this.cargarLoader = false;
      }
      else this.funcionErrorPaginacion()
    })
  }

  bajaAsignacion() {
    const embedHTML = document.getElementById('foo');
    const FileName = 'BajaAsignacion' + this.date.toLocaleDateString();
    // console.log(this.idResponsable)
    if (this.formBaja.value['motivoBaja'] == '' || this.formBaja.value['motivoBaja'] == undefined) {
      Swal.fire({
        title: "ERROR",
        text: 'Escriba el motivo de la baja',
        type: "warning",
        // timer: 2000
      }).then(() => {
        let element: HTMLElement = document.getElementsByClassName('btnBaja')[0] as HTMLElement;
        element.click()
      })
    } else {
      this.cargarLoader = true;
      let asignacionBaja = {
        "idUsuario": 0,
        "idUbicacion": 0,
        "motivo": this.formBaja.value['motivoBaja'],
        "idResponsable": this.idResponsable,
        "detalleAsignaciones": [
          {
            "idAsignaciones": this.checks,
          }
        ],
        "tipoDocumento": 'A',
        "ruta": 'https://inventario-rfid.s3.amazonaws.com/' + FileName + '.pdf'
      }
      // console.log(asignacionBaja)
      this.inventarioService.bajaAsignacionHw(asignacionBaja).subscribe(data => {
        if (data['result'] === 1) {
          for (let index = 4; index < data['data']['equiposList'].length + 4; index++) {
            let posicion = index - 4
            var arrayDeCadenas = data['data']['equiposList'][posicion]['equipo'].split('¶');
            let tipo = arrayDeCadenas[0]
            let marca = arrayDeCadenas[1]
            let mod = arrayDeCadenas[2]
            let nuinv = arrayDeCadenas[3]
            let nuse = arrayDeCadenas[4]
            let nutag = arrayDeCadenas[5]
            this.dataa.push([
              tipo + ' - ' + marca + ' - ' + mod,
              nuse,
              nutag,
              nuinv,
              data['data']['equiposList'][posicion]['caracteristicas']
            ])
          }
          const widths = [120, 80, 120, 70, 80];
          this._pdfService.generatePDFAsignacion(this.dataa, embedHTML, 'BajaAsignación' + data['data']['folioAsignacion'] + '.pdf', data['data']['motivo'], data['data']['idUsuario'], data['data']['nombreUbicacion'], data['data']['folioAsignacion'], data['data']['usuario'], 'Baja', data['data']['fechafechaHoraAsignacion'], widths);

          Swal.fire({
            title: "Guardado",
            text: "Se ha registrado exitosamente",
            type: "success",
            // timer: 3000
          })

          this.verInventario();
        } else {
          Swal.fire({
            title: "ERROR",
            text: data['message'],
            type: "warning",
            // timer: 2000
          }).then(() =>{
            this.verInventario();
          });
        }
      }, error => {
      })
    }

  }

  loadPage(page: number) {
    this.page = page;
    this.verInventario();
  }

  funcionErrorPaginacion(){
    if (!Swal.isVisible()) {
      Swal.fire({
        title: "ERROR",
        text: "No hay coincidencias con tu criterio de búsqueda",
        type: "warning"
      }).then(() => {
        if (this.page != 1) {
          this.page = 1;
          this.verInventario();
        } else {
          this.page = 1;
          this.verInventario();
        }
      })
    }
  }
}
